import { Badge, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import getSocket from "../../helpers/socket";
import RoomListItem from "./RoomListItem";
import api from "../../services/api";
import ChatRoom from "./Room";
import { ChatBubble, ChatBubbleOutline } from "@material-ui/icons";
import { AuthContext } from "../../context/Auth/AuthContext";
import RoomMinimized from "./RoomMinimized";
import ChatNotification from "./Notification";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
    },
    avatars: {
        width: "60px",
        background: theme.palette.background.paper,
        padding: 0,
        overflowY: 'auto',
        ...theme.scrollbarStylesSoft,
        '&::-webkit-scrollbar': {
            width: '3px'
        }
    },
    avatar: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        cursor: "pointer",
        '&:hover': {
            background: theme.palette.total
        },
        '& img': {
            borderRadius: '50%',
            width: "48px",
            height: "48px"
        },
        position: "relative",
        marginTop: "5px",
        marginLeft: "6px",
        '&>span': {
            position: "absolute",
            bottom: 0,
            right: 10,
        }
    },
    floatScreen: {
        position: "fixed",
        bottom: 0,
        right: "70px"
    },
    floatScreenFull: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        marginTop: "50px",
        right: "30px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        zIndex: 10
    },
    noRoomsText: {
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        padding: "20px"
    },
    mainContent: {
        minWidth: "270px",
        maxHeight: "80vh",
        overflowY: "auto",
        ...theme.scrollbarStylesSoft
    },
    toggleColapsed: {
        background: theme.palette.primary.main,
        marginLeft: 0,
        paddingLeft: "6px"
    },
    fullHeader: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        padding: "10px"
    },
    fullHeaderLeft: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        width: "20%",
        paddingLeft: 10
    },
    fullHeaderRight: {
        flex: 1,
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
    },
    menuItemCollapsed: {

    },
    menuItemExpanded: {
        background: 'grey'
    },
    openedChatsContainer: {
        position: "fixed",
        bottom: 0,
        right: "30px",
        display: "flex",
        height: 455,
    },
    openedChat: {
        bottom: 0,
        marginLeft: 20,
        width: 300
    },
    minimizedChatsContainer: {
        position: "fixed",
        bottom: 0,
        right: "-100px",
        transition: "right 1s"
    },
    minimizedContainerAnchor: {
        right: 0,
        position: "fixed",
        bottom: 0,
        background:'#fff',
        border: "1px solid #ccc",
        borderRadius: "3px 0 0 3px"
    },
    title: {
        fontWeight: "bold",
        fontSize: "2em",
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_ROOMS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        return [...state];
    }

    if (action.type === "ADD_ROOM") {
        const newTicket = action.payload;

        state.unshift(newTicket);

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_ROOM") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_ROOM_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_ROOM_LAST_MESSAGE") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].lastMessage = ticket.lastMessage;
            state[ticketIndex].updatedAt = ticket.updatedAt;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_ROOM_USER_STATUS") {
        const user = action.payload;

        const roomIndex = state.findIndex(r => r.userId == user.id || r.participantId == user.id);
        if (roomIndex !== -1) {
            if (state[roomIndex].userId == user.id) {
                state[roomIndex].user.status = user.status;
            } else {
                state[roomIndex].participant.status = user.status;
            }
        }
        return [...state];
    }

    if (action.type === "DELETE_ROOM") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Chat = ({}) => {
    const classes = useStyles();
    const [rooms, setRooms] = useReducer(reducer, []);
    const [collapsed, setCollapsed] =  useState(true);
    const [chatPage, setChatPage] = useState('index');
    const [users, setUsers] = useState([]);
    const [room, setRoom] = useState(null);
    const [to, setTo] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const [openedRooms, setOpenedRooms] = useState([]);
    const [minimizedRooms, setMinimizedRooms] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [unreadMessagesByRoom, setUnreadMessagesByRoom] = useState({});

    const minimizedContainerRef = useRef();
    const [anchorVisible, setAnchorVisible] = useState(true);

    let closing = false;

    const { user } = useContext(AuthContext);
    const { getSettingValue } = useContext(SettingsContext);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async() => {
                const { data } = await api.get('/chat/users');
                setUsers(data.usersNotInRoom);
            }
            fetchList();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async() => {
                const { data } = await api.get('/chat/info');
                setIsEnabled(data.enabled);
                setUnreadMessages(data.unreadMessages);
                if (data.unreadMessagesByRoom) {
                    let structure = {};
                    data.unreadMessagesByRoom.forEach(room => {
                        structure[room.roomId] = parseInt(room.unreadMessages);
                    });
                    setUnreadMessagesByRoom(structure);
                }
            }
            fetchList();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        const oRooms = [];
        const mRooms = [];
        rooms.map(room => {
            if (room.participants && room.participants.length > 0) {
                room.participants.map(participant => {
                    if (participant.userId == user.id) {
                        if (participant.opened) {
                            oRooms.push(room);
                        }
                        if (participant.minimized) {
                            mRooms.push(room);
                        }
                    }
                });
            }
        });
        setOpenedRooms(oRooms);
        setMinimizedRooms(mRooms);
    }, [rooms]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async() => {
                const { data } = await api.get('/chat/rooms');
                setRooms({
                    type: "LOAD_ROOMS",
                    payload: data.rooms
                });
            }
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        let total = 0;
        Object.keys(unreadMessagesByRoom).forEach(key => {
            total += unreadMessagesByRoom[key];
        });
        setUnreadMessages(total);
    }, [unreadMessagesByRoom]);

    const newRoomEvent = data => {
        setRooms({
            type: "ADD_ROOM",
            payload: data.room
        });
        setUsers(users => users.filter(user => user.id != data.room.participantId && user.id != data.room.userId));
        if (to && (to.id == data.room.participantId || to.id == data.room.userId)) {
            setOpenedRooms(openedRooms => [...openedRooms, data.room]);
            handleRoomClick(data.room);
            setTo(null);
        }
    }

    const newMessageEvent = data => {
        setRooms({
            type: "UPDATE_ROOM_LAST_MESSAGE",
            payload: data.message.room
        });
    }

    const readCountEvent = data => {
        setUnreadMessagesByRoom({ ...unreadMessagesByRoom, [data.roomId]: parseInt(data.unreadCount) });
        setRooms({
            type: "UPDATE_ROOM_UNREAD_MESSAGES",
            payload: {unreadMessages: data.unreadCount, id: data.roomId}
        });
    }

    const userEvent = data => {
        if (data.action === 'status' && users && users.length > 0) {
            const userIndex = users.findIndex(u => u.id === data.id);
            if (userIndex !== -1) {
                users[userIndex].status = data.status;
                setUsers([...users]);
            }
            setRooms({
                type: "UPDATE_ROOM_USER_STATUS",
                payload: data
            });
        }
    }

    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`chat:room:new#${publicToken}`, newRoomEvent);
        socket.on(`chat:message:new#${publicToken}`, newMessageEvent);
        socket.on(`chat:room:readCount#${publicToken}`, readCountEvent);
        socket.on('user', userEvent);

        return () => {
            socket.off(`chat:room:new#${publicToken}`, newRoomEvent);
            socket.off(`chat:message:new#${publicToken}`, newMessageEvent);
            socket.off(`chat:room:readCount#${publicToken}`, readCountEvent);
            socket.off('user', userEvent);
        }
    }, [users, to]);

    const handleAvatarClick = (toParam) => {
        setCollapsed(true);
        setRoom(null);
        setTo(toParam);
    }

    const handleRoomClick = (room) => {
        setCollapsed(true);
        (async() => {
            const to = room.userId == user.id ? room.participantId : room.userId;
            const { data } = await api.post(`/chat/open/${to}/true`);
            handleMinimizedContainerMouseLeave();
            setRooms({
                type: "UPDATE_ROOM",
                payload: data.room
            });
        })();
    }

    const handleRoomClose = (room) => {
        (async() => {
            const to = room.userId == user.id ? room.participantId : room.userId;
            const { data } = await api.post(`/chat/open/${to}/false`);
            setRooms({
                type: "UPDATE_ROOM",
                payload: data.room
            });
        })();
    }

    const handleRoomMinimize = (room) => {
        (async() => {
            const to = room.userId == user.id ? room.participantId : room.userId;
            const { data } = await api.post(`/chat/minimize/${to}/true`);
            setRooms({
                type: "UPDATE_ROOM",
                payload: data.room
            });
        })();
    }

    const handleMinimizedContainerMouseLeave = () => {
        closing = true;
        setTimeout(() => {
            if (closing) {
                minimizedContainerRef.current.style.right = '-100px';
                setTimeout(() => {
                    setAnchorVisible(true);
                }, 1000);
            }
        }, 2000);
    }

    const handleMinimizedContainerMouseOver = () => {
        closing = false;
        minimizedContainerRef.current.style.right = '10px';
        setAnchorVisible(false);
    }

    return (
        <React.Fragment>
            {isEnabled ? (
                <React.Fragment>
                    <ChatNotification notificationSound={getSettingValue("chatNotificationSound")} onClickOpen={handleRoomClick} />
                    <div className={classes.root}>
                        <IconButton onClick={() => setCollapsed(!collapsed)}
                            edge="start"
                            className={collapsed ? classes.menuItemCollapsed : classes.menuItemExpanded}
                            color="inherit"
                            aria-label="open drawer">
                                <Badge badgeContent={unreadMessages} color="secondary">
                                    <ChatBubble />
                                </Badge>
                        </IconButton>
                        {!collapsed && (
                            <div className={classes.floatScreenFull}>
                                <div className={classes.fullHeader}>
                                    <div className={classes.fullHeaderLeft}>
                                        <Typography className={classes.title}>Chat</Typography>
                                    </div>
                                    <div className={classes.fullHeaderRight}>
                                        
                                    </div>
                                </div>
                                <div className={classes.pageIndex}>
                                    <div className={classes.mainContent}>
                                        <div className={classes.rooms}>
                                            {rooms.map((roomItem) => (
                                                <RoomListItem key={roomItem.id} unreadMessages={unreadMessagesByRoom[roomItem.id]} room={roomItem} handleRoomClick={handleRoomClick} />
                                            ))}
                                        </div>
                                        <div className={classes.rooms}>
                                            {users.map((user) => (
                                                <RoomListItem key={`user-list-item-${user.id}`} to={user} handleRoomClick={handleAvatarClick} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.openedChatsContainer}>
                        {openedRooms.map((roomItem) => (<div className={classes.openedChat} key={`open_chat_${roomItem.id}`}>
                            <ChatRoom room={roomItem} onClose={handleRoomClose} onMinimize={handleRoomMinimize} />
                        </div>))}
                        {to && (<div className={classes.openedChat} key={`open_chat_to_${to}`}>
                            <ChatRoom to={to} onClose={(room) => {room ? handleRoomClose(room) : setTo(null)}} />
                        </div>)}
                    </div>
                    {anchorVisible && minimizedRooms && minimizedRooms.length > 0 && <div className={classes.minimizedContainerAnchor} onMouseOver={handleMinimizedContainerMouseOver}>
                        <ChatBubbleOutline />
                    </div>}
                    <ul className={classes.minimizedChatsContainer} ref={minimizedContainerRef} onMouseLeave={handleMinimizedContainerMouseLeave}>
                        {minimizedRooms.map((roomItem) => (
                            <RoomMinimized key={`minimized_chat_${roomItem.id}`} room={roomItem} onClick={handleRoomClick} />
                        ))}
                    </ul>
                </React.Fragment>
            ) : (<></>)}
        </React.Fragment>
    );
}

export default Chat;