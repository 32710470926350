import React from "react";
import { makeStyles } from "@material-ui/core";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    oneline: {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxHeight: 15
    },
    paragraph: {
        margin: 0,
        padding: 0
    },
    listContainer: {
        //marginTop:-40
    }
}));

const MarkdownWrapper = ({ children, ...props }) => {
    const boldRegex = /\*(.*?)\*/g;
    const tildaRegex = /~(.*?)~/g;

    const classes = useStyles();

    if (children && boldRegex.test(children)) {
        children = children.replace(boldRegex, "**$1**");
    }
    if (children && tildaRegex.test(children)) {
        children = children.replace(tildaRegex, "~~$1~~");
    }

    function LinkRenderer(rendererProps) {
        if (props.disableAnchor || props.oneline) {
            return rendererProps.children;
        }
        return (
            <a href={rendererProps.href} target="_blank" rel="noreferrer">
                {rendererProps.children}
            </a>
        );
    }

    function paragraphRenderer(rendererProps) {
        return (
            <div className={classes.paragraph}>{rendererProps.children}</div>
        )
    }

    function listItemRenderer(rendererProps) {
        return (
            <span>- {rendererProps.children}</span>
        )
    }

    function listRenderer(rendererProps) {
        return (
            props.oneline ? <div>{rendererProps.children}</div> : <div className={classes.listContainer}>{rendererProps.children}</div>
        )
    }

    if (!children) return null;

    return <Markdown
        remarkPlugins={[remarkGfm]}
        className={clsx({ [classes.oneline]: props.oneline })}
        components={{
            a: LinkRenderer,
            p: paragraphRenderer,
            ul: listRenderer,
            li: listItemRenderer
        }}
        children={children} />;
};

export default MarkdownWrapper;
