import React, { useState, useEffect, useContext } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { Grid, makeStyles, Paper, useTheme } from "@material-ui/core";

import DashboardBox from "./Box";
import { Computer, Contacts, DoneAll, Forum, Group, List, LocalOffer, People, PhonelinkRing, Queue, Schedule, Timer } from "@material-ui/icons";
import DashboardUsersBox from "./UsersBox";
import DateRangePicker from "../../components/DateRangePicker";
import SelectContacts from "../../components/SelectContacts";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectTags from "../../components/SelectTags";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        '& h2': {
            color: theme.palette.dark.main
        }
    },
    paper: {
        padding: theme.spacing(2),        
		boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)",
    }
}));

const ResumeChart = () => {

    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading] = useState(false);
    const [chartSeries, setChartSeries] = useState([]);
    const [ticketsData, setTicketsData] = useState({});
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());

    const [userIds, setUserIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const params = {
                        dateFrom: dateFrom.toJSON().slice(0, 10),
                        dateTo: dateTo.toJSON().slice(0, 10),
                        userIds,
                        queueIds,
                        contactIds,
                        tagIds
                    }
                    const { data } = await api.get("/dashboard/resume/", { params });
                    setChartSeries(data.series);
                    setTicketsData(data);
                } catch (err) {
                    toastError(err);
                }
                setLoading(false);
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [dateFrom, dateTo, userIds, contactIds, queueIds, tagIds]);

    useEffect(() => {
        setChartOptions(
            {
                chart: {
                    plotBackgroundColor: theme.palette.background.paper,
                    backgroundColor: theme.palette.background.paper,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                series: chartSeries,
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                }
            },
        );
    }, [chartSeries, theme]);

    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    useEffect(() => {
        async function getReportUser() {
            if (user && user.profile === 'user') {
                await setUserIds([user.id]);
            }
        }
        getReportUser();
    }, [user]);

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>


                        {user.profile === 'admin' ? (
                            <>
                                <Grid item xs={12} md={4}>
                                    <div className={classes.wrapperSelect}>
                                        <DateRangePicker disabled={loading} onChange={(p) => { setDateFrom(p[0]); setDateTo(p[1]) }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <div className={classes.wrapperSelect}>
                                        <SelectContacts disabled={loading} onChange={handleChangeContactIds} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <div className={classes.wrapperSelect}>
                                        <SelectUsers multiple disabled={loading} onChange={handleChangeUserIds} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <div className={classes.wrapperSelect}>
                                        <SelectQueues disabled={loading} onChange={handleChangeQueueIds} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <div className={classes.wrapperSelect}>
                                        <SelectTags outlined disabled={loading} onChange={handleChangeTagIds} />
                                    </div>
                                </Grid>
                            </>):(
                        <>
                            <Grid item xs={12} md={6}>
                                <div className={classes.wrapperSelect}>
                                    <DateRangePicker disabled={loading} onChange={(p) => { setDateFrom(p[0]); setDateTo(p[1]) }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.wrapperSelect}>
                                    <SelectContacts disabled={loading} onChange={handleChangeContactIds} />
                                </div>
                            </Grid>
                        </>
                            )}
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardBox  className={classes.paper} title="Resumo" value={<React.Fragment>
                    <HighchartsReact
                     className={classes.paper}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </React.Fragment>} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardBox className={classes.paper} loading={loading} title="Total de Atendimentos" value={ticketsData.total} />
                <DashboardBox marginTop={20} className={classes.paper} loading={loading} title="Total de Contatos" value={ticketsData.totalContacts} />
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
                <DashboardBox  className={classes.paper} loading={loading} title="Em Atendimento" value={ticketsData.open} icon={<Group fontSize="inherit" />} />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
                <DashboardBox  className={classes.paper} loading={loading} title="Pendentes" value={ticketsData.pending} icon={<Timer fontSize="inherit" />} />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
                <DashboardBox  className={classes.paper} loading={loading} title="Bot" value={ticketsData.bot} icon={<Computer fontSize="inherit" />} />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
                <DashboardBox  className={classes.paper} loading={loading} title="Finalizado" value={ticketsData.closed} icon={<DoneAll fontSize="inherit" />} />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
                <h2>Atendentes</h2>
            </Grid>
            {ticketsData.users && ticketsData.users.map((user) =>
                <Grid item x={12} sm={12} md={12} key={`user_box_${user.id}`}>
                    <DashboardUsersBox  className={classes.paper} user={user} />
                </Grid>
            )}
            {ticketsData.stats && (<>
                <Grid item xs={12} sm={12} md={12}>
                    <h2>Estatísticas</h2>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Atendentes" icon={<People fontSize="inherit" />} value={ticketsData.stats.usersNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Conexões" icon={<PhonelinkRing fontSize="inherit" />} value={ticketsData.stats.connectionsNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Contatos" icon={<Contacts fontSize="inherit" />} value={ticketsData.stats.contactsNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Grupos" icon={<Group fontSize="inherit" />} value={ticketsData.stats.groupsNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Mensagens" icon={<Forum fontSize="inherit" />} value={ticketsData.stats.messagesNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Tags" icon={<LocalOffer fontSize="inherit" />} value={ticketsData.stats.tagsNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Agendados / Enviados" icon={<Schedule />} value={`${ticketsData.stats.scheduledMessagesNumber} / ${ticketsData.stats.scheduledMessagesSentNumber}`} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Setores" icon={<Queue fontSize="inherit" />} value={ticketsData.stats.queuesNumber} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardBox title="Bots" icon={<Computer fontSize="inherit" />} value={ticketsData.stats.botsNumber} />
                </Grid>
            </>
            )}
            {ticketsData.queues && (<>
                <Grid item xs={12} sm={12} md={12}>
                    <h2>Setores</h2>
                </Grid>
                {ticketsData.queues.map(queue => (
                    <Grid item xs={12} sm={6} md={3} key={`queue_box_${queue.id}`}>
                        <DashboardBox title={queue.name} icon={<List fontSize="inherit" />} value={queue.users.length} label="Atendentes" />
                    </Grid>
                ))}
            </>)}
        </Grid>
    );
};

export default ResumeChart;
