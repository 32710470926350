import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import clsx from "clsx";

import { IconButton, Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import Select from 'react-select';
import colourStyles from "../../constants/tagsColor";
import getSocket from "../../helpers/socket";
import TicketInfoDrawer from "../TicketInfoDrawer";
import { InfoOutlined } from "@material-ui/icons";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import IntegrationIframeDrawer from "../IntegrationIframe/drawer";

const socket = getSocket();

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100%",
        position: "relative",
        overflow: "hidden"
    },
    fullscreen:{
        position:'absolute'
    },
    ticketInfo: {
        maxWidth: "40%",
        flexBasis: "40%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "80%",
            flexBasis: "80%"
        }
    },
    ticketActionButtons: {
        maxWidth: "60%",
        flexBasis: "60%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            flexBasis: "100%",
            marginBottom: "10px"
        }
    },

    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Ticket = ({fullscreen, setFullscreen}) => {
    const { ticketId } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState({});
    const [ticket, setTicket] = useState({});
    const [tags, setTags] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const [integrationIframes, setIntegrationIframes] = useState([]);
    const [loadedIframe, setLoadedIframe] = useState(null);

    const [dragDropFiles, setDragDropFiles] = useState([]);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get("/tickets/" + ticketId);
                    
                    setContact(data.contact);
                    if (data.contact.tags && data.contact.tags.length > 0) {
                        setInitialTags(data.contact.tags.map((tag) => {return {label: tag.name, value: tag.id, color: tag.color}}));
                    } else {
                        setInitialTags([]);
                    }
                    setTicket(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchTicket();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);

    // Load iframe integrations
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchIntegrations = async () => {
                try {
                    const { data } = await api.get("/integration/iframes");
                    setIntegrationIframes(data.iframes);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchIntegrations(); 
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);

    useEffect(() => {
        const connectEvent = () => {
            socket.emit("joinChatBox", ticketId);
        }
    
        const ticketEvent = data => {
            if (data.action === "update") {
                setTicket(data.ticket);
            }
    
            if (data.action === "delete") {
                toast.success("Atendimento deletado com sucesso!");
                history.push("/tickets");
            }
        }
    
        const contactEvent = data => {
            if (data.action === "update") {
                setContact(prevState => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact };
                    }
                    return prevState;
                });
            }
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);
        socket.on("contact", contactEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            socket.emit("leaveChatBox", ticketId);
            socket.off("connect", connectEvent);
            socket.off("ticket", ticketEvent);
            socket.off("contact", contactEvent);
        };
    }, [ticketId, history]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTags = async () => {
                try {
                    const { data } = await api.get("/tags", {params: {all: true}});

                    setTags(data.tags);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, history]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleInfoDrawerOpen = () => {
        setInfoDrawerOpen(true);
    }

    const handleInfoDrawerClose = () => {
        setInfoDrawerOpen(false);
    }


    const handleFullscreen = () => {
        setFullscreen(!fullscreen)
    }

    const handleChangeTags = async (changedTags) => {
        setInitialTags(changedTags);
        try {
            await api.post(`/tags/change`, {tagIds: changedTags.map((tag) => tag.value), contactIds: [ticket.contact.id]});
        } catch (err) {
            toastError(err);
        }
    };

    const handleIframeClick = (iframe) => {
        setLoadedIframe(iframe);
    }

    const handleIframeClose = () => {
        setLoadedIframe(null);
    }

    return (
        <div className={classes.root} id="drawer-container">
        <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen || infoDrawerOpen,
                })}
            >
                <TicketHeader loading={loading} handleFullscreen={handleFullscreen} fullscreen={fullscreen}>
                    <div className={classes.ticketInfo}>
                        <TicketInfo
                            contact={contact}
                            ticket={ticket}
                            onClick={handleDrawerOpen}
                            infoButton={user.profile === 'admin' ? <IconButton
                                size="small"
                                onClick={handleInfoDrawerOpen}
                            ><InfoOutlined /></IconButton> : null}
                        />
                    </div>
                    <div className={classes.ticketActionButtons}>
                        <TicketActionButtons ticket={ticket} handleFullscreen={handleFullscreen} fullscreen={fullscreen} integrationIframes={integrationIframes} onLoadIframe={handleIframeClick} />
                    </div>
                </TicketHeader>
                <div className={classes.ticketAddTags}>
                    <Select
                        options={tags.map((tag) => {return {value: tag.id, label: tag.name, color: tag.color}})}
                        isMulti
                        value={initialTags}
                        placeholder="Selecione as tags..."
                        onChange={handleChangeTags}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPosition={'fixed'}
                        menuPortalTarget={document.body}
                        styles={colourStyles}
                        />
                </div>
                <ReplyMessageProvider>
                    <MessagesList
                        ticketId={ticketId}
                        isGroup={ticket.isGroup}
                        status={ticket.status}
                        onDrop={setDragDropFiles}
                        user={user}
                    ></MessagesList>
                    <MessageInput ticketStatus={ticket.status} droppedFiles={dragDropFiles} contact={ticket && ticket.contact && ticket.contact} />
                </ReplyMessageProvider>
            </Paper>
            {drawerOpen && 
            <ContactDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                contact={contact}
                loading={loading}
                ticketId={ticket.id}
            />}
            {infoDrawerOpen && <TicketInfoDrawer
                open={infoDrawerOpen}
                handleDrawerClose={handleInfoDrawerClose}
                ticket={ticket}
                loading={loading}
            />}
            {loadedIframe && <IntegrationIframeDrawer integrationIframe={loadedIframe} ticket={ticket} handleDrawerClose={handleIframeClose} />}
        </div>
    );
};

export default Ticket;
