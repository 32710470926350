import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'

import ResumeChart from "./ResumeChart";
import { Box, Tab, Tabs } from "@material-ui/core";
import Report from "../Report";
import TabPanel from "../../components/TabPanel";

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        padding: 15,
        '& h2': {
            color: theme.palette.dark.main
        }
    },
    tabLabel: {
        color: theme.palette.text.primary,
    },    
    mainTabs: {
        marginBottom: theme.spacing(1)
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }
    
    return (
        <div className={classes.root}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={classes.mainTabs}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab className={classes.tabLabel} label="Geral" />
                    <Tab className={classes.tabLabel} label="Atendimentos" />
                </Tabs>
            </Box>
            <TabPanel value={tab} name={0}>
                <ResumeChart />
            </TabPanel>
            <TabPanel value={tab} name={1}>
                <Report />
            </TabPanel>
        </div>
    );
};

export default Dashboard;
