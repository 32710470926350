import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	title: {
		color: theme.palette.textPrimary
	}
}));

const Title = props => {
	const classes = useStyles();
	return (
		<Typography component="h2" variant="h6" className={classes.title} gutterBottom>
			{props.children}
		</Typography>
	);
};

export default Title;
