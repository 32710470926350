import React, { useContext, useEffect } from "react";


import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

import Paper from "@material-ui/core/Paper";
import SettingBoolField from "../../components/UserSetting/boolField";
import SettingSoundSelectionField from "../../components/UserSetting/soundSelectionField";
import getSocket from "../../helpers/socket";
import { Button } from "@material-ui/core";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";

const socket = getSocket();


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 10,
		boxShadow: 'none',
		'&>div': {
			width: "100%"
		}
	},

}));


const NotifySettingsModal = ({ open, onClose, userId }) => {
	const classes = useStyles();
	const { settings } = useContext(UserSettingsContext);
	
	const handleClose = () => {
		onClose();
	};
	
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Configuração de Notificações
				</DialogTitle>
				<DialogContent>
					<Paper className={classes.paper}>
						<SettingBoolField name="enableSound" value={settings ? settings.enableSound: false}
							userSettings={true} userId={userId}
							label="Som" helpText="Se desabilitado, não serão enviadas notificações e bots" />
					</Paper>

					<Paper className={classes.paper}>
						<SettingSoundSelectionField name="notificationSound"
							userId={userId} className={classes.paper} value={settings ? settings.notificationSound: false}
							userSettings={true}
							label={i18n.t("settings.settings.notificationSound.name")} />
					</Paper>

					<Paper className={classes.paper}>
						<SettingBoolField name="enableGroupNotifications"
							userId={userId} value={settings ? settings.enableGroupNotifications: 'default'}
							label="Habilitar notificações para grupos" helpText="Se desabilitado, não serão enviadas notificações para grupos" />
					</Paper>
					<Paper className={classes.paper}>
						<SettingBoolField name="enableBotNotifications"
							userId={userId} value={settings ? settings.enableBotNotifications: false}
							label="Habiitar notificações para Bots" helpText="Se desabilitado, não serão enviadas notificações e bots" />
					</Paper>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Fechar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default NotifySettingsModal;
