import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const filter = createFilterOptions({
    trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, forContact }) => {
    const history = useHistory();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [newContact, setNewContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [whatsapp, setWhatsapp] = useState(null);
    const [whatsapps, setWhatsapps] = useState([]);
    const [showWhatsappSelection, setShowWhatsappSelection] = useState(false);
    const [searchParamQueue, setSearchParamQueue] = useState('');
    const [loadingQueue, setLoadingQueue] = useState(false);
    const [queues, setQueues] = useState([]);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    useEffect(() => {
        if (forContact) {
            setSelectedContact(forContact);
        } else {
            setSelectedContact(null);
        }
    }, [forContact]);

    useEffect(() => {
        if (!modalOpen) {
            setLoadingQueue(false);
            return;
        }
        setLoadingQueue(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchQueues = async () => {
                try {
                    const { data } = await api.get("/queue/", {
                        params: { searchParam: searchParamQueue, whatsappId: whatsapp?.id },
                    });
                    setQueues(data);
                    setLoadingQueue(false);
                } catch (err) {
                    setLoadingQueue(false);
                    toastError(err);
                }
            };

            fetchQueues();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParamQueue, whatsapp, modalOpen]);

    useEffect(() => {
        setLoading(true);
        setSelectedQueue(null);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("whatsapp");
                    setWhatsapps(data.whatsapps);
                    if (data.whatsapps.length > 1) {
                        setShowWhatsappSelection(true);
                    }
                    for (const item of data.whatsapps) {
                        if (item.isDefault) {
                            setWhatsapp(item);
                            break;
                        }
                    }
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [modalOpen]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedContact(null);
    };

    const handleSaveTicket = async contactId => {
        if (!contactId) return;
        setLoading(true);
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                userId: user.id,
                status: "open",
                whatsappId: whatsapp ? whatsapp.id : null,
                queueId: selectedQueue ? selectedQueue.id : null
            });
            history.push(`/tickets/${ticket.id}`);
            
        } catch (err) {
            toastError(err);
            
        }
        setLoading(false);
        handleClose();
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            setContactModalOpen(true);
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContactTicket = contact => {
        handleSaveTicket(contact.id);
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`;
        }
    };

    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const handleWhatsappChange = (event, whatsapp) => {
        setWhatsapp(whatsapp);
    }

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
            ></ContactModal>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("newTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    {forContact ? <div>Contato: <strong>{forContact.name}</strong> ({forContact.number})</div> : 
                    <Autocomplete
                        options={options}
                        loading={loading}
                        style={{ width: 300 }}
                        clearOnBlur
                        autoHighlight
                        freeSolo
                        clearOnEscape
                        getOptionLabel={renderOptionLabel}
                        renderOption={renderOption}
                        filterOptions={createAddContactOption}
                        onChange={(e, newValue) => handleSelectOption(e, newValue)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("newTicketModal.fieldLabel")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParam(e.target.value)}
                                onKeyPress={e => {
                                    if (loading || !selectedContact) return;
                                    else if (e.key === "Enter") {
                                        handleSaveTicket(selectedContact.id);
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />}
                    {showWhatsappSelection && 
                    <div style={{marginTop: 10}}>
                        <p>Selecione a conexão</p>
                        <ToggleButtonGroup value={whatsapp} exclusive onChange={handleWhatsappChange} aria-label="select whatsapp">
                            {whatsapps.map((item) => {
                                return (<ToggleButton key={item.id} value={item}>{item.name}</ToggleButton>)
                            })}
                        </ToggleButtonGroup>
                    </div>}
                    <Autocomplete
                        style={{ width: 300, marginBottom: 10, marginTop: 20 }}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedQueue(newValue);
                        }}
                        options={queues}
                        filterOptions={filter}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("transferTicketModal.noOptions")}
                        loading={loadingQueue}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("transferTicketModal.fieldLabelQueue")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParamQueue(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("newTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact}
                        onClick={() => handleSaveTicket(selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("newTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketModal;
