import { Dialog, DialogTitle, TableBody, TableContainer, TableHead, TableCell, TableRow, Table, withStyles, Paper, DialogContent, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    spacingBlock: {
        marginBottom: theme.spacing(2)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

const StyledHighlighTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        '& td': {
            fontWeight: "bold"
        }
    },
}))(TableRow);

const ScheduledMessageRecipients = ({scheduledMessageId, open, onClose}) => {
    const [scheduledMessage, setScheduledMessage] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchScheduledMessage = async () => {
                if (!scheduledMessageId || !open) return;

                try {
                    const { data } = await api.get(`/scheduled-messages/${scheduledMessageId}`);
                    setScheduledMessage(data);
                } catch (err) {
                    toastError(err);
                }
            };

            fetchScheduledMessage();
        });
        return () => clearTimeout(delayDebounceFn);
    }, [scheduledMessageId, open]);

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleOnClose} maxWidth="lg" scroll="paper">
                <DialogTitle>
                    Informações do agendamento
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4">Recorrência</Typography>
                    {scheduledMessage && 
                    <div className={classes.spacingBlock}>
                        {!scheduledMessage.repeat && "Sem recorrência"}
                        {scheduledMessage.repeat && <div>
                            Recorrência a cada <strong>{ scheduledMessage.repeatValue } {scheduledMessage.repeatTypeLabelPlural}</strong>
                            {scheduledMessage.repeatTimes > 0 && <span> por {scheduledMessage.repeatTimes} vezes</span>}
                            {!scheduledMessage.repeatTimes && <span> sempre</span>}
                            {scheduledMessage.sents && scheduledMessage.sents.length > 0 && <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Data / Hora do processamento</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scheduledMessage.sents.map(sent => (
                                            <StyledHighlighTableRow>
                                                <StyledTableCell>{sent.createdAt}</StyledTableCell>
                                            </StyledHighlighTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                        </div>}
                    </div>
                    }
                    <Typography variant="h4">Conteúdo da mensagem</Typography>
                    <div className={classes.spacingBlock}>{scheduledMessage && scheduledMessage.content}</div>
                    <Typography variant="h4">Contatos a enviar</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nome</StyledTableCell>
                                    <StyledTableCell>Número</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {scheduledMessage && scheduledMessage.contacts && scheduledMessage.contacts.length > 0 && (
                                    <React.Fragment>
                                        <StyledHighlighTableRow>
                                            <StyledTableCell colSpan="2">Contatos</StyledTableCell>
                                        </StyledHighlighTableRow>
                                        {scheduledMessage.contacts.map(contact => (
                                            <StyledTableRow key={contact.id}>
                                                <StyledTableCell>{contact.name}</StyledTableCell>
                                                <StyledTableCell>{contact.number}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </React.Fragment>
                                )}
                                {scheduledMessage && scheduledMessage.tags && scheduledMessage.tags.length > 0 && (
                                    <React.Fragment>
                                        <StyledHighlighTableRow>
                                            <StyledTableCell colSpan="2">Contatos das Tags selecionadas</StyledTableCell>
                                        </StyledHighlighTableRow>
                                        {scheduledMessage.tags.map(tag => (
                                            <React.Fragment key={`tag-${tag.id}`}>
                                                <StyledHighlighTableRow key={tag.id}>
                                                    <StyledTableCell colSpan="2">{tag.name}</StyledTableCell>
                                                </StyledHighlighTableRow>
                                                {tag.contacts && tag.contacts.length > 0 && tag.contacts.map(contact => (
                                                    <StyledTableRow key={`contact-tag-${tag.id}-${contact.id}`}>
                                                        <StyledTableCell>{contact.name}</StyledTableCell>
                                                        <StyledTableCell>{contact.number}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ScheduledMessageRecipients;