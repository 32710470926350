import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Announcement } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const NotificationMenuButton = ({ }) => {
    const { info } = useContext(SettingsContext);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (info) {
            setShow(info.showNotificationsIcon);
        }
    }, [info]);

    return (show ? <RouterLink to="/notifications">
        <IconButton edge="start">
            <Announcement />
        </IconButton>
    </RouterLink> : <></>)
}

export default NotificationMenuButton;