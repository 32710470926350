import React, {} from "react";

import { makeStyles } from "@material-ui/core/styles";

/*const useStyles = makeStyles(theme => ({
	
}));*/

const ConnectionIcon = ({ connectionType, width, height, className }) => {
	//const classes = useStyles();

	return (
		<React.Fragment>
            {connectionType === 'whatsapp' && <img className={className} src="/icons/whatsapp.png" width={width || 24} height={height || 24} /> }
            {connectionType === 'instagram' && <img className={className} src="/icons/instagram.png" width={width || 24} height={height || 24} />}
            {connectionType === 'facebook' && <img className={className} src="/icons/facebook.png" width={width || 24} height={height || 24} />}
		</React.Fragment>
	);
};

export default ConnectionIcon;
