import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { LoopOutlined, Timer } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Box, Button, FormControlLabel, Hidden, Menu, MenuItem, Modal, Switch } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ScheduledMessageModal from "../ScheduledMessageModal";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import Swal from "sweetalert2";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles(theme => ({
    mainWrapper: {
        background: theme.palette.tabHeaderBackground,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: 0,
            width: "100%"
        }
    },

    newMessageBox: {
        background: theme.palette.tabHeaderBackground,
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },

    messageInputWrapper: {
        padding: 6,
        marginRight: 7,
        background: theme.palette.total,
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative"
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },

    sendMessageIcons: {
        color: theme.palette.messageIcons,
    },

    uploadInput: {
        display: "none",
    },

    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.tabHeaderBackground,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },

    audioLoading: {
        color: green[500],
        opacity: "70%",
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },

    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 8,
        paddingLeft: 73,
        paddingRight: 7,
    },

    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    replyginContactMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
    messageShortcutWrapper: {
        margin: 0,
        position: "absolute",
        bottom: "50px",
        background: theme.palette.total,
        padding: "2px",
        border: "1px solid #CCC",
        left: 0,
        width: "100%",
        "& li": {
            listStyle: "none",
            "&:focus-within a, & a": {
                display: "block",
                padding: "8px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "32px",
                textDecoration: "none",
                color: theme.palette.dark.main,
                "&:hover, &:active": {
                    background: theme.palette.primary.main,
                    color: "#FFF",
                    cursor: "pointer"
                }
            }
        }
    },
    formControl: {
        marginRight: 7,
        color: theme.palette.messageIcons
    }
}));

const MessageInput = ({ ticketStatus, droppedFiles, contact }) => {
    const classes = useStyles();
    const { ticketId } = useParams();

    const { isActive } = useContext(SettingsContext);

    const [medias, setMedias] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [caption, setCaption] = useState("");
    const [showEmoji, setShowEmoji] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recording, setRecording] = useState(false);
    const [typeBar, setTypeBar] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadMessagesConfirmationOpen, setLoadMessagesConfirmationOpen] = useState(false);
    const [uploadFromShortcut, setUploadFromShortcut] = useState(false);
    const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
    const [blockEnter, setBlockEnter] = useState(false);

    const { setReplyingMessage, replyingMessage } = useContext(
        ReplyMessageContext
    );
    const { user } = useContext(AuthContext);

    const shortcutMessagesListRef = useRef();

    const [signMessage, setSignMessage] = useLocalStorage("signOption", true);

    useEffect(() => {
        inputRef.current.focus();
    }, [replyingMessage]);

    useEffect(() => {
        inputRef.current.focus();
        return () => {
            setInputMessage("");
            setShowEmoji(false);
            setMedias([]);
            setReplyingMessage(null);
        };
    }, [ticketId, setReplyingMessage]);

    useEffect(() => {
        if (droppedFiles && droppedFiles.length > 0) {
            const selectedMedias = Array.from(droppedFiles);
            setMedias(selectedMedias);
        }
    }, [droppedFiles]);

    const handleChangeInput = e => {
        setInputMessage(e.target.value);
        handleLoadShortcutMessages(e.target.value);
    };

    const handleSuggestionClick = value => {
        if (value.mediaUrl) {
            (async () => {
                const res = await fetch(value.absoluteMediaUrl);
                const blob = await res.blob();
                const contentType = res.headers.get('content-type');
                setMedias([...medias, new File([blob], value.mediaUrl, { type: contentType })]);
                setCaption(value.content);
                setInputMessage("");
                setUploadFromShortcut(true);
            })();
        } else {
            setInputMessage(value.content);
        }
        setTypeBar(false);
        setTimeout(() => {
            setBlockEnter(false);
        }, 500);
    }

    const handleAddEmoji = e => {
        let emoji = e.native;
        setInputMessage(prevState => prevState + emoji);
    };

    const handleChangeMedias = e => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleInputPaste = e => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };

    const handleUploadMedia = async e => {
        setLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append("fromMe", true);
        medias.forEach(media => {
            formData.append("medias", media);
            formData.append("body", caption);
        });

        if (uploadFromShortcut) {
            formData.append("asAudio", true);
        }

        try {
            await api.post(`/messages/${ticketId}`, formData);
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }

        setLoading(false);
        setUploadFromShortcut(false);
        setMedias([]);
        setCaption("");
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === "") return;
        setLoading(true);

        const cantDisableSignMessage = !isActive('showDisableSignOption') && user.profile !== "admin";

        const message = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            body: signMessage || cantDisableSignMessage
                ? `*${user?.name}:*\n${inputMessage.trim()}`
                : inputMessage.trim(),
            quotedMsg: replyingMessage,
        };
        try {
            await api.post(`/messages/${ticketId}`, message);
        } catch (err) {
            Swal.fire({
                title: "Error!",
                text: getErrorMessage(err),
                icon: 'error',
                confirmButtonText: "OK"
            });
        }

        setInputMessage("");
        setShowEmoji(false);
        setLoading(false);
        setReplyingMessage(null);
    };

    const getErrorMessage = (err) => {
        const errorMsg = err.response?.data?.error;
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            return i18n.t(`backendErrors.${errorMsg}`);
        } else {
            return errorMsg;
        }
    }

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleLoadShortcutMessages = async (value) => {
        if (value && value.indexOf('/') === 0) {
            try {
                const queueIdList = user.queues.map(queue => queue.id);
                const { data } = await api.get('/shortcut-messages/', {
                    params: { searchParam: inputMessage.substring(1) }
                });
                let filteredShortcuts;
                if (data.shortcutMessages) {

                    if (user.profile === 'admin') {
                        filteredShortcuts = data.shortcutMessages;
                    } else {
                        filteredShortcuts = data.shortcutMessages.filter(shortcut => {
                            const shortcutQueueIds = shortcut.queueIds ? shortcut.queueIds.split(',').map(Number) : [];
                            return queueIdList.every(queueId => shortcutQueueIds.includes(queueId));
                        });

                        const shortcutsWithoutQueueIds = data.shortcutMessages.filter(shortcut => !shortcut.queueIds);
                        filteredShortcuts = filteredShortcuts.concat(shortcutsWithoutQueueIds);
                    }
                }
                else {
                    filteredShortcuts = [];
                }

                console.log('Filtered shortcut messages:', filteredShortcuts);
                setSuggestions(filteredShortcuts);


                if (data.shortcutMessages.length > 0) {
                    setTypeBar(true);
                } else {
                    setTypeBar(false);
                }
            } catch (err) {
                setTypeBar(false);
            }
        } else {
            setTypeBar(false);
        }
    }

    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3();
            if (blob.size < 10000) {
                setLoading(false);
                setRecording(false);
                return;
            }

            const formData = new FormData();
            const filename = `${new Date().getTime()}.mp3`;
            formData.append("medias", blob, filename);
            formData.append("body", filename);
            formData.append("fromMe", true);
            formData.append("isAudio", true);

            await api.post(`/messages/${ticketId}`, formData);
        } catch (err) {
            toastError(err);
        }

        setRecording(false);
        setLoading(false);
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuItemClick = (event) => {
        setAnchorEl(null);
    }

    const handleReloadMessages = (event) => {
        setLoadMessagesConfirmationOpen(true);
    }

    const handleLoadMessages = async (event) => {
        try {
            await api.post(`/tickets/${ticketId}/load-messages`);
        } catch (err) {
            toastError(err);
        }
    }

    let shortcutCurrentNavIndex = -1;

    const handleShortcutKeyPressEvent = (event) => {
        if (typeBar && event.key === "ArrowUp" || event.key === "ArrowDown") {
            setBlockEnter(true);
            const itemsCount = shortcutMessagesListRef.current.children.length;
            if (event.key === "ArrowDown") {
                shortcutCurrentNavIndex++;
                if (shortcutCurrentNavIndex >= itemsCount) {
                    shortcutCurrentNavIndex = 0;
                }
            } else {
                shortcutCurrentNavIndex--;
                if (shortcutCurrentNavIndex < 0) {
                    shortcutCurrentNavIndex = itemsCount - 1;
                }
            }
            shortcutMessagesListRef.current.children[shortcutCurrentNavIndex].focus();
        }
    }

    const handleShortcutKeyPressStart = (event) => {
        shortcutCurrentNavIndex = -1;
        handleShortcutKeyPressEvent(event);
    }

    const handleShortcutKeyPress = (event) => {
        if (typeBar && event.key === "Enter") {
            handleSuggestionClick(suggestions[shortcutCurrentNavIndex]);
        }
        handleShortcutKeyPressEvent(event);
    }

    const renderReplyingMessage = message => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        {message.body}
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                    onClick={() => setReplyingMessage(null)}
                >
                    <ClearIcon className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        );
    };

    if (medias.length > 0)
        return (
            <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
                <IconButton
                    aria-label="cancel-upload"
                    component="span"
                    onClick={e => setMedias([])}
                >
                    <CancelIcon className={classes.sendMessageIcons} />
                </IconButton>

                {loading ? (
                    <div>
                        <CircularProgress className={classes.circleLoading} />
                    </div>
                ) : (
                    <span>
                        {medias[0]?.name}
                        {/* <img src={media.preview} alt=""></img> */}
                    </span>
                )}
                <IconButton
                    aria-label="send-upload"
                    component="span"
                    onClick={handleUploadMedia}
                    disabled={loading}
                >
                    <SendIcon className={classes.sendMessageIcons} />
                </IconButton>
            </Paper>
        );
    else {
        return (
            <>
                <ScheduledMessageModal
                    open={appointmentModalOpen}
                    onClose={() => setAppointmentModalOpen(false)}
                    message={inputMessage}
                    fromTicket={true}
                    contact={contact}
                />
                <Paper square elevation={0} className={classes.mainWrapper}>
                    {replyingMessage && renderReplyingMessage(replyingMessage)}
                    <div className={classes.newMessageBox}>
                        {showEmoji ? (
                            <div className={classes.emojiBox}>
                                <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                                    <Picker
                                        perLine={16}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={handleAddEmoji}
                                    />
                                </ClickAwayListener>
                            </div>
                        ) : null}
                        <ConfirmationModal
                            title="Confirmar Carregamento de todas as mensagens"
                            open={loadMessagesConfirmationOpen}
                            onClose={setLoadMessagesConfirmationOpen}
                            onConfirm={handleLoadMessages}
                        >
                            Tem certeza que deseja carregar todas as mensagens desse atendimento? O processo é feito de forma assíncrona e pode levar alguns minutos até ser concluído.
                            Para manter a estabilidade do sistema, não realize esse procedimento frequentemente.
                        </ConfirmationModal>
                        <Hidden only={['sm', 'xs']}>
                            <IconButton
                                aria-label="emojiPicker"
                                component="span"
                                disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                onClick={e => setShowEmoji(prevState => !prevState)}
                            >
                                <MoodIcon className={classes.sendMessageIcons} />
                            </IconButton>

                            <input
                                multiple
                                type="file"
                                id="upload-button"
                                disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                className={classes.uploadInput}
                                onChange={handleChangeMedias}
                            />
                            <label htmlFor="upload-button">
                                <IconButton
                                    aria-label="upload"
                                    component="span"
                                    disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                >
                                    <AttachFileIcon className={classes.sendMessageIcons} />
                                </IconButton>
                            </label>

                            <IconButton
                                aria-label="reloadMessages"
                                component="span"
                                disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                onClick={handleReloadMessages}
                            >
                                <LoopOutlined className={classes.sendMessageIcons} />
                            </IconButton>
                            {(isActive('showDisableSignOption') || user.profile === "admin") && (
                                <FormControlLabel
                                    label={i18n.t("messagesInput.signMessage")}
                                    className={classes.formControl}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            checked={signMessage}
                                            onChange={e => {
                                                setSignMessage(e.target.checked);
                                            }}
                                            name="showAllTickets"
                                            color="primary"
                                        />
                                    }
                                />
                            )}
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenuClick}>
                                <MoreVert></MoreVert>
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                keepMounted
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuItemClick}
                            >
                                <MenuItem onClick={handleMenuItemClick}>
                                    <IconButton
                                        aria-label="emojiPicker"
                                        component="span"
                                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        onClick={e => setShowEmoji(prevState => !prevState)}
                                    >
                                        <MoodIcon className={classes.sendMessageIcons} />
                                    </IconButton>
                                </MenuItem>
                                <MenuItem onClick={handleMenuItemClick}>
                                    <input
                                        multiple
                                        type="file"
                                        id="upload-button"
                                        disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        className={classes.uploadInput}
                                        onChange={handleChangeMedias}
                                    />
                                    <label htmlFor="upload-button">
                                        <IconButton
                                            aria-label="upload"
                                            component="span"
                                            disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        >
                                            <AttachFileIcon className={classes.sendMessageIcons} />
                                        </IconButton>
                                    </label>
                                </MenuItem>
                                <MenuItem onClick={handleMenuItemClick}>

                                    <IconButton
                                        aria-label="reloadMessages"
                                        component="span"
                                        disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                        onClick={handleReloadMessages}
                                    >
                                        <LoopOutlined className={classes.sendMessageIcons} />
                                    </IconButton>
                                </MenuItem>
                                {(isActive('showDisableSignOption') || user.profile === "admin") && (
                                    <MenuItem onClick={handleMenuItemClick}>
                                        <FormControlLabel
                                            label={i18n.t("messagesInput.signMessage")}
                                            labelPlacement="start"
                                            className={classes.formControl}
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={signMessage}
                                                    onChange={e => {
                                                        setSignMessage(e.target.checked);
                                                    }}
                                                    name="showAllTickets"
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </MenuItem>
                                )}
                            </Menu>
                        </Hidden>
                        <div className={classes.messageInputWrapper}>
                            <InputBase
                                inputRef={input => {
                                    input && input.focus();
                                    input && (inputRef.current = input);
                                }}
                                className={classes.messageInput}
                                placeholder={
                                    ticketStatus === "open" || ticketStatus === "group"
                                        ? i18n.t("messagesInput.placeholderOpen")
                                        : i18n.t("messagesInput.placeholderClosed")
                                }
                                multiline
                                rowsMax={5}
                                value={inputMessage}
                                onChange={handleChangeInput}
                                disabled={recording || loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                onPaste={e => {
                                    (ticketStatus === "open" || ticketStatus === "group") && handleInputPaste(e);
                                }}
                                onKeyDown={handleShortcutKeyPressStart}
                                onKeyPress={e => {
                                    if (loading || e.shiftKey) return;
                                    else if (!blockEnter && e.key === "Enter") {
                                        handleSendMessage();
                                    }
                                    if (blockEnter) {
                                        setBlockEnter(false);
                                    }
                                }}
                            />
                            {typeBar ? (
                                <ul className={classes.messageShortcutWrapper} ref={shortcutMessagesListRef} onKeyDown={handleShortcutKeyPress}>
                                    {suggestions.map((value, index) => {
                                        return (<li className={classes.messageShortcutWrapperItem} tabIndex={0} key={index}>
                                            <a onClick={(event) => { event.preventDefault(); handleSuggestionClick(value) }} href="#">{value.mediaUrl && '[Arquivo] '}{value.content}</a></li>)
                                    })}
                                </ul>
                            ) : (<div></div>)}
                        </div>
                        {inputMessage ? (
                            <IconButton
                                aria-label="sendMessage"
                                component="span"
                                onClick={handleSendMessage}
                                disabled={loading}
                            >
                                <SendIcon className={classes.sendMessageIcons} />
                            </IconButton>
                        ) : recording ? (
                            <div className={classes.recorderWrapper}>
                                <IconButton
                                    aria-label="cancelRecording"
                                    component="span"
                                    fontSize="large"
                                    disabled={loading}
                                    onClick={handleCancelAudio}
                                >
                                    <HighlightOffIcon className={classes.cancelAudioIcon} />
                                </IconButton>
                                {loading ? (
                                    <div>
                                        <CircularProgress className={classes.audioLoading} />
                                    </div>
                                ) : (
                                    <RecordingTimer />
                                )}

                                <IconButton
                                    aria-label="sendRecordedAudio"
                                    component="span"
                                    onClick={handleUploadAudio}
                                    disabled={loading}
                                >
                                    <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                                </IconButton>
                            </div>
                        ) : (
                            <IconButton
                                aria-label="showRecorder"
                                component="span"
                                disabled={loading || (ticketStatus !== "open" && ticketStatus !== "group")}
                                onClick={handleStartRecording}
                            >
                                <MicIcon className={classes.sendMessageIcons} />
                            </IconButton>

                        )}

                        <IconButton
                            component="span"
                            onClick={() => setAppointmentModalOpen(true)}>
                            <Timer className={classes.sendMessageIcons} />
                        </IconButton>
                    </div>
                </Paper>

            </>
        );
    }
};

export default MessageInput;
