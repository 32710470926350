import { Drawer, IconButton, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";

const IntegrationIframeDrawer = ({ integrationIframe, ticket, handleDrawerClose }) => {
    const [drawerWidth, setDrawerWidth] = useState(320);
    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            display: "flex",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
        },
        header: {
            display: "flex",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "#eee",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            minHeight: "73px",
            justifyContent: "flex-start",
        },
        content: {
            display: "flex",
            backgroundColor: "#eee",
            flexDirection: "column",
            padding: "8px 0px 8px 8px",
            height: "100%",
            overflowY: "scroll",
            ...theme.scrollbarStyles,
        }
    }));

    const classes = useStyles();
    const [url, setUrl] = useState(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (integrationIframe && ticket) {
            setUrl(integrationIframe.url.replace('{contactId}', ticket.contactId).replace('{ticketId}', ticket.id).replace('{ticketUserId}', ticket.userId).replace('{userId}', user.id));
        }
        if (integrationIframe && integrationIframe.config) {
            if (typeof(integrationIframe.config) === "string") {
                integrationIframe.config = JSON.parse(integrationIframe.config);
            }
            if (integrationIframe.config.width) {
                setDrawerWidth(parseInt(integrationIframe.config.width));
            }
        }
    }, [integrationIframe, ticket, user]);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={true}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.header}>
                <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    {integrationIframe.name}
                </Typography>
            </div>
            <div className={classes.content}>
                {url && <iframe src={url} style={{ width: '100%', height: '100%', border: 0 }}></iframe>}
                {!url && <span>Não foi possível abrir o iframe</span>}
            </div>
        </Drawer>
    );
}

export default IntegrationIframeDrawer;