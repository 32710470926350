import React, { useState, useEffect, useContext } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { initUserOptions, cleanInitUserOptions } from "./helpers/userOptions";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { MutedItemsProvider } from './context/Tickets/MutedItems';
import { UserSettingsContext } from "./context/Settings/UserSettingsContext";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const App = () => {
	const [locale, setLocale] = useState();	

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const preferredTheme = window.localStorage.getItem("preferredTheme");
	const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? 'dark' : 'light');
	const [colorDefault, setColorDefault] = useState(null);
	
	const colorMode = React.useMemo(
		() => ({
		  toggleColorMode: () => {
			setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
		  },
		}),
		[],
	);

	const theme = createMuiTheme(
		{
			scrollbarStyles: {
				"&::-webkit-scrollbar": {
					width: "15px",
					height: "8px",
				},
				"&::-webkit-scrollbar-thumb": {
					boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.7)",
					backgroundColor: "#009fdd",
				},
			},
			scrollbarStylesSoft: {
				"&::-webkit-scrollbar": {
					width: "8px",
				},
				"&::-webkit-scrollbar-thumb": {
					backgroundColor: mode === 'light' ? "#DDDDDD" : '#333333',
				}
			},
			scrollbarStylesSoftBig: {
				"&::-webkit-scrollbar": {
					width: "12px",
					backgroundColor: mode === 'light' ? "#ffffff" : '#333333',
				},
				"&::-webkit-scrollbar-thumb": {
					backgroundColor: mode === 'light' ? "#DDDDDD" : '#cccccc',
				}
			},
			palette: {
				type: mode,
				primary: { main: colorDefault ? colorDefault: "#009fdd" },
				softBackground: mode === 'light' ? '#F1F1F1' : '#666',
				textPrimary: mode === 'light' ? colorDefault ? colorDefault: "#009fdd" : '#FFFFFF',
				borderPrimary: mode === 'light' ? colorDefault ? colorDefault: "#009fdd" : '#FFFFFF',
				dark: { main: mode === 'light' ? '#333333' : '#DDDDDD' },
				light: { main: mode === 'light' ? '#DDDDDD' : '#333333' },
				lightWhite: { main: mode === 'light' ? '#FFFFFF' : '#333333' },
				tabHeaderBackground: mode === 'light' ? '#EEE' : '#666',
				optionsBackground: mode === 'light' ? '#fafafa' : '#333',
				fancyBackground: mode === 'light' ? '#fafafa' : '#333',
				total: mode === 'light' ? '#fff' : '#222',
				messageIcons: mode === 'light' ? 'grey' : '#DDDDDD',
				inputBackground: mode === 'light' ? '#FFFFFF' : '#333',
			},
			mode
		},
		locale
	);

	useEffect(() => {
		const i18nlocale = localStorage.getItem("i18nextLng");
		const browserLocale =
			i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

		if (browserLocale === "ptBR") {
			setLocale(ptBR);
		}
	}, []);

	useEffect(() => {
		setColorDefault(window.localStorage.getItem("colorDefault"));
		window.localStorage.setItem("preferredTheme", mode);
	}, [mode, colorDefault]);

	useEffect(() => {
		const fnDebounce = setTimeout(() => {
			initUserOptions();
		}, 500);
		return () => {clearTimeout(fnDebounce);cleanInitUserOptions()};
	}, []);

	return (
		<ColorModeContext.Provider value={{ colorMode }}>
			<MutedItemsProvider>
			<ThemeProvider theme={theme}>
				<Routes />
			</ThemeProvider>
			</MutedItemsProvider>
		</ColorModeContext.Provider>
	);
};

export default App;
