import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ResolveTicketModal = ({ open, onClose, onAccept, onAcceptFeedback, onAcceptNoEndMessage, ticket, noFeedback }) => {

    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchPermissions = async () => {
				try {
					const { data } = await api.get(`/tickets/${ticket.id}/permissions`);
					setPermissions(data.permissions);
                    setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchPermissions();
		}, 500);
		return () => {
			clearTimeout(delayDebounceFn);
		};
    }, [ticket]);

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
            <DialogTitle id="form-dialog-title">
                {i18n.t("resolveTicketModal.title")}
            </DialogTitle>
            <DialogContent dividers>
                {ticket.status === 'group' ? i18n.t("resolveTicketModal.contentEndOnly") : noFeedback ? i18n.t("resolveTicketModal.contentAutoFeedback") : i18n.t("resolveTicketModal.content")}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                >
                    {i18n.t("resolveTicketModal.buttons.cancel")}
                </Button>
                {ticket.status === 'group' && 
                    <Button
                        variant="containerd"
                        type="submit"
                        onClick={() => onAcceptNoEndMessage(ticket.id)}
                        color="primary"
                        disabled={loading}
                    >{i18n.t("resolveTicketModal.buttons.end")}</Button>
                }
                {ticket.status !== 'group' && <>
                    {permissions.haveEndMessage && permissions.canFinalizeWithoutFeedback && <Button
                        variant="containerd"
                        type="submit"
                        onClick={() => onAcceptNoEndMessage(ticket.id)}
                        color="primary"
                        disabled={loading}
                        >{i18n.t("resolveTicketModal.buttons.endWithoutMessage")}</Button>}
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => onAccept(ticket.id)}
                        color="primary"
                        disabled={loading}
                    >
                        {!permissions.enabledFeedback || !permissions.canFinalizeWithoutFeedback ? i18n.t("resolveTicketModal.buttons.end") : i18n.t("resolveTicketModal.buttons.accept")}
                    </Button>
                    {permissions.enabledFeedback && permissions.canFinalizeWithoutFeedback && 
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        onClick={() => onAcceptFeedback(ticket.id)}
                    >
                        {i18n.t("resolveTicketModal.buttons.feedback")}
                    </Button>}
                </>}
            </DialogActions>
        </Dialog>
    )
}

export default ResolveTicketModal;