import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import useCountPaused from '../countMessages';
import api from "../../services/api";

const useTickets = ({
	searchParam,
	searchById,
	pageNumber,
	status,
	date,
	showAll,
	queueIds,
	withUnreadMessages,
	tagIds,
	userIds,
	statuses,
	connectionIds,
	dateFrom,
	dateTo,
	isGroup,
	sortDir,
	searchOnMessages,
	forceSearch,
	lastClientActivityMe,
	lastClientActivityClient,
	unreadMessagesSearchMe,
	unreadMessagesSearchClient,
	settingChange
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [count, setCount] = useState(0);
	const { countPaused, setCountPaused } = useCountPaused();

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTickets = async () => {
				try {
					const { data } = await api.get("/tickets", {
						params: {
							searchById,
							searchParam,
							pageNumber,
							status,
							date,
							showAll,
							queueIds,
							withUnreadMessages,
							tagIds,
							userIds,
							statuses,
							connectionIds,
							dateFrom,
							dateTo,
							isGroup,
							sortDir,
							searchOnMessages,
							forceSearch,
							lastClientActivityMe,
							lastClientActivityClient,
							unreadMessagesSearchMe,
							unreadMessagesSearchClient
						},
					});
					setTickets(data.tickets);
					setHasMore(data.hasMore);
					setCount(data.count);
					setLoading(false);
				} catch (err) {
					console.log(err);
					setLoading(false);
					toastError(err);
				}
			};
			fetchTickets();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		pageNumber,
		status,
		date,
		showAll,
		queueIds,
		withUnreadMessages,
		sortDir,
		forceSearch,
		settingChange
	]);

	return { tickets, loading, hasMore, count, setCountPaused, countPaused };
};

export default useTickets;
