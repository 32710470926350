import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import api from "../../services/api";
import { LanguageSelector, i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

import { Box, Button, Tab, Tabs } from "@material-ui/core";
import SettingBoolField from "../../components/Setting/boolField";
import SettingIntEnabledField from "../../components/Setting/intEnabledField";
import SettingIntField from "../../components/Setting/intField";
import SettingTextField from "../../components/Setting/textField";

import Title from "../../components/Title";
import ApiKeys from "../ApiKeys";
import SettingSoundSelectionField from "../../components/Setting/soundSelectionField";
import getSocket from "../../helpers/socket";
import SettingSelectionField from "../../components/Setting/selectionField";

import ClockIcon from "@material-ui/icons/AccessTime";
import ForumIcon from "@material-ui/icons/Forum";
import ConfirmationModal from "../../components/ConfirmationModal";
import Webhooks from "../Webhooks";
import IntegrationIframes from "../IntegrationIframe";

const socket = getSocket();
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(4),
        backgroundColor: theme.palette.fancyBackground,
    },

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
        '&>div': {
            width: "100%"
        }
    },
    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    tabLabel: {
        color: theme.palette.text.primary,
    }
}));

const Settings = () => {
    const classes = useStyles();

    const [settings, setSettings] = useState([]);
    //const [meta, setMeta] = useState({});
    const [tab, setTab] = useState(0);
    const [procedureModalOpen, setProcedureModalOpen] = useState(false);
    const [procedure, setProcedure] = useState(null);
    const [procedureResult, setProcedureResult] = useState(null);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/settings");
                setSettings(data.settings);
                //setMeta(data.meta);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const settingsEvent = data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        }

        socket.on("settings", settingsEvent);

        return () => {
            socket.off("settings", settingsEvent);
        };
    }, []);

    const handleProcedure = (procedure) => {
        setProcedureModalOpen(true);
        setProcedure(procedure);
    }

    const handleConfirmProcedure = async() => {
        if (procedure === 'clean-pending' || procedure === 'clean-open') {
            const { data } = await api.post(`/tickets/update-tickets`, {
                action: 'close',
                status: procedure === 'clean-pending' ? 'pending' : 'open'
            });
            setProcedureResult(`${data.total} registro(s) processado(s)`);
        }
    }

    return (
        <div className={classes.root}>
            <ConfirmationModal title="Atenção!" onClose={() => setProcedureModalOpen(false)} open={procedureModalOpen} onConfirm={handleConfirmProcedure}>
                Tem certeza que deseja realizar esse procedimento? Essa ação é irreversível.
            </ConfirmationModal>
            <Container className={classes.container} maxWidth="md">
                <Title>
                    {i18n.t("settings.title")}
                </Title>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.general")} />
                        <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.appointments")}  />
                        <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.internal_chat")}  />
                        <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.integration")}  />
                        <Tab className={classes.tabLabel} label={i18n.t("settings.tabs.actions_by_batch")} />
                    </Tabs>
                </Box>
                <TabPanel value={tab} index={0}>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="groupMedia" settings={settings} label={i18n.t("settings.settings.groupMedia.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showContacts" settings={settings} label={i18n.t("settings.settings.showContacts.name")}  />
                    </Paper>                    
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showTags" settings={settings} label={i18n.t("settings.settings.showTags.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showConnections" settings={settings} label={i18n.t("settings.settings.showConnections.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showDeletedMessages" settings={settings} label={i18n.t("settings.settings.showDeletedMessages.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showResolved" settings={settings} label={i18n.t("settings.settings.showResolved.name")}  />
                    </Paper>
                     <Paper className={classes.paper}>
                        <SettingBoolField name="showWaiting" settings={settings} label={i18n.t("settings.settings.showWaiting.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showDashboard" settings={settings} label={i18n.t("settings.settings.showDashboard.name")}  />
                    </Paper>
                    {1 == 2 && (<Paper className={classes.paper}>
                        <SettingBoolField name="showAllTickets" settings={settings} label={i18n.t("settings.settings.showAllTickets.name")}  />
                    </Paper>)}
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showDisableSignOption" settings={settings} label={i18n.t("settings.settings.showDisableSignOption.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="userCanFinalizeTicketWithoutFeedback" settings={settings} label={i18n.t("settings.settings.userCanFinalizeTicketWithoutFeedback.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableCustomerPortfolio" settings={settings} label={"Habilitar Carteira de Clientes"} helpText="Ao habilitar, contatos que estejam com atendentes associados iniciarão atendimento automaticamente com o atendente selecionado para cada um." />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableSessionBot" settings={settings} label={"Mostrar Seção Bots para usuários"} helpText="Ao habilitar, a seção bot aparece para usuários." />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="notesVisibleAll" settings={settings} label={"Mostrar Notas das mensagens dos tickets para todos usuários"} helpText="Ao habilitar, todos usuários conseguem ver as notas nas mensagens." />
                    </Paper>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Paper className={classes.paper}>
                        <SettingSoundSelectionField name="notificationSound" settings={settings} label={i18n.t("settings.settings.notificationSound.name")}  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingIntEnabledField name="ticketTimeout" settings={settings} label="Tempo de expiração do Atendimento" helpText="minutos"  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingIntField name="ticketFeedbackTimeout" settings={settings} label="Tempo de expiração da avaliação" helpText="Tempo de expiração (em minutos) quando o sistema estiver aguardando o usuário responder à avaliação (não pode ser 0)."  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingIntEnabledField name="ticketBotTimeout"	settings={settings} label="Tempo de expiração do bot" helpText="Tempo de expiração (em minutos) quando o sistema estiver aguardando o usuário responder ao bot."  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingTextField name="ticketTimeoutMessage" settings={settings} label="Mensagem de expiração" helpText="Mensagem a ser enviada ao usuário quando o atendimento é expirado."  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingIntEnabledField name="ticketLimitPerUser" settings={settings} label="Limite de atendimentos em aberto por usuário" helpText="Quantos atendimentos um atendente pode atender por vez."  />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingSelectionField name="showContactHistory" settings={settings} label="Mostrar histórico de atendimento para" options={[
                            {label: 'Todos', value: 'all'},
                            {label: 'Somente administradores', value: 'admin'},
                            {label: 'Para usuários do mesmo setor', value: 'queue'}
                        ]} />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="userCanInteractWithAllGroups" settings={settings} label="Usuário comum pode ver todos os grupos" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="ignoreUserConnectionForGroups" settings={settings} label="Ignorar conexão atribuída ao usuário para grupos" helpText="Caso habilitado, o usuário poderá ver grupos de qualquer conexão, caso seja permitido a ele ver os grupos especificados." />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="ticketsByConnection" settings={settings} label="Atendimentos por conexão" helpText="Permitir mais de um atendimento aberto para o mesmo contato, em conexões diferentes" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="historyByConnection" settings={settings} label="Histórico por conexão" helpText="Mostrar histórico por contato E por conexão (com essa opção desabilitada, o histórico será o mesmo para atendimentos para o mesmo contato independente da conexão)" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="historyByConnectionAdmin" settings={settings} label="Histórico de conexão para administrador" helpText="Separa o histórico de conexão também para o administrador. Lembrando que para ter efeito, a configuração acima deve estar ativa também" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="canDeleteTickets" settings={settings} label="Pode excluir atendimentos" helpText="Se desabilitado, nenhum usuário poderá excluir atendimentos" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="canDeleteMessages" settings={settings} label="Pode excluir mensagens" helpText="Se desabilitado, nenhum usuário poderá excluir mensagens" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="forceDownload" settings={settings} label="Forçar download ao clicar no link de Download de arquivos" helpText="Caso habilitado, quando o usuário realizar o download do arquivo, o navegador não tentará abrir o documento e sim sempre realizar o download. (Apenas para arquivos de até 500MB)" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showDownloadConfirmation" settings={settings} label="Mostrar tela de confirmação para download" helpText="Caso habilitado, quando o usuário tentar fazer download de arquivo será perguntado se ele deseja mesmo baixar o arquivo" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="blockNonDefaultConnections" settings={settings} label="Permite que o usuário veja atendimentos apenas da sua conexão padrão" helpText="Se habilitado, o usuário não verá atendimentos de outras conexões, que não a que está no campo 'Conexão padrão do usuário' na edição de usuários" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="showLinks" settings={settings} label="Mostrar links para os usuários" helpText="Se habilitado, os usuários poderão clicar nos links normalmente." />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableGroupNotifications" settings={settings} label="Habilitar notificações para grupos" helpText="Se desabilitado, não serão enviadas notificações para grupos" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="enableBotNotifications" settings={settings} label="Habiitar notificações para Bots" helpText="Se desabilitado, não serão enviadas notificações e bots" />
                    </Paper>
                   
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Paper className={classes.paper}>
                        <SettingBoolField name="chatEnabled" settings={settings} label="Habilitar Chat Interno" />
                    </Paper>
                    <Paper className={classes.paper}>
                        <SettingSoundSelectionField name="chatNotificationSound" settings={settings} label={i18n.t("settings.settings.chatNotificationSound.name")}  />
                    </Paper>
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <Paper className={classes.paper}>
                        <ApiKeys />
                    </Paper>
                    <Paper className={classes.paper}>
                        <Webhooks />
                    </Paper>
                    <Paper className={classes.paper}>
                        <IntegrationIframes />
                    </Paper>
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    {procedureResult && <Paper className={classes.paper}>{procedureResult}</Paper>}
                    <Paper className={classes.paper} title="Atendimentos">
                        <Button className={classes.procedureButton} variant="contained" color="secondary" onClick={() => handleProcedure('clean-pending')}><ClockIcon /> Limpar fila de aguardando (fechar atendimentos)</Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Button className={classes.procedureButton} variant="contained" color="primary" onClick={() => handleProcedure('clean-open')}>
                            <ForumIcon /> Limpar fila "Em Atendimento" (fecha os atendimentos sem enviar nada)
                        </Button>
                    </Paper>
                </TabPanel>
            </Container>
        </div>
    );
};

export default Settings;
