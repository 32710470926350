import React, { useState, useContext, useEffect } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Version from "../../helpers/version";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

const backgroundLoginURL = `${process.env.REACT_APP_BACKEND_URL}/background-login`;

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            background: `url('${backgroundLoginURL}')`,
            backgroundSize: "cover",
            [theme.breakpoints.down("sm")]: {
                background: "#FFFFFF"
            }
        }
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: theme.palette.lightWhite.main,
        borderRadius: 5,
        padding: 30,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width:400
    },
    avatar: {
        width: "100%",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        textAlign: "center",
        marginTop: "5%",
    
    },
    versao: {
        fontSize: "15px",
        textAlign: "right",
    },
    notificationWarning: {
        color: "red",
    },
    qrcode: {
        width: "100%",
        textAlign: "center",
        marginTop:50
    },
    qrcodeImage: {
        width: 130,
    },
    playStoreButton: {
        width: "60%"
    }
}));

const Login = () => {
    const classes = useStyles();

    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const [user, setUser] = useState({ email: "", password: "" });
    const [isFromFlutter, setIsFromFlutter] = useState(false);
    const [pushToken, setPushToken] = useState("");
    const [showQRCode, setShowQRCode] = useState(false);


    const appUserAgent = "Onecode Jupiter App 1.0";
    const fromApp = navigator.userAgent === appUserAgent;

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const checkPlayStoreUrl = async() => {
        const { data } = await api.get("/have-playstore-url");
        if (data.success) {
            setShowQRCode(true);
        }
    };

    useEffect(() => {
        checkPlayStoreUrl();
    } , []);

    const handlSubmit = e => {
        e.preventDefault();
        const sentData = user;
        if (window.localStorage.getItem("pushToken")) {
            sentData.pushToken = window.localStorage.getItem("pushToken");
        }
        handleLogin(sentData);
    };

    window.addEventListener("flutterInAppWebViewPlatformReady", function(event) {
        setIsFromFlutter(true);					
        window.flutter_inappwebview.callHandler('getPushToken').then((data) => {
            setPushToken(data.token);
            window.localStorage.setItem("pushToken", data.token);
        });
    });

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                
                <form className={classes.form} noValidate onSubmit={handlSubmit}>

                <img className={classes.avatar} src="/logo-black.png" alt="OneCode Logo" />

                    
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={i18n.t("login.form.email")}
                        name="email"
                        value={user.email}
                        onChange={handleChangeInput}
                        autoComplete="email"
                        type="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={i18n.t("login.form.password")}
                        type="password"
                        id="password"
                        value={user.password}
                        onChange={handleChangeInput}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {i18n.t("login.buttons.submit")}
                    </Button>

                    <Typography className={classes.versao}>
                        Versão: {Version} {fromApp ? <span>. Flutter: {isFromFlutter ? <span>Sim. Push Token: {pushToken.substring(0, 6)}...</span> : ""}</span> : ""}
                        {fromApp && (!isFromFlutter || !pushToken) ? <p className={classes.notificationWarning}>Houve um problema ao inicializar a aplicação. Você não receberá notificações das mensagens nesse dispositivo. Tente reiniciar a aplicação.</p> : ""}
                    </Typography>

                    
                </form>
                {showQRCode ? 
                <div className={classes.qrcode}>
                    <img src={`${apiURL}/qrcode-playstore`} alt="qrcode" className={classes.qrcodeImage} />
                    <p>
                        <a href={`${apiURL}/playstore`} target="_blank" rel="noopener noreferrer">
                            <img alt='Disponível no Google Play' src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png' className={classes.playStoreButton} />
                        </a>
                    </p>
                </div>
                : <></>}
            </div>
            <Box mt={8}>{/* <Copyright /> */}</Box>
        </Container>
    );
};

export default Login;
