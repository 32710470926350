import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core"
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import IOSSwitch from "../IOSSwitch";

const SettingBoolField = ({ name, settings, label, helpText }) => {
    const getSettingValue = key => {
		const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
		return value;
	};

    const handleChangeBooleanSetting = async(e) => {
        const selectedValue = e.target.checked ? "enabled" : "disabled";
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
    }

    return <FormControlLabel
        control={<IOSSwitch checked={settings && settings.length > 0 && getSettingValue(name) === "enabled"} onChange={handleChangeBooleanSetting} name={name} />}
        label={label}
        
    />
}

export default SettingBoolField;