import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	title: {
		color: theme.palette.textPrimary,
		margin: 0
	}
}));

export default function Title(props) {
	const classes = useStyles();
	return (
		<Typography variant="h5" className={classes.title} gutterBottom>
			{props.children}
		</Typography>
	);
}
