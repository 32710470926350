import React, { useState, useEffect, useReducer, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox, Chip, ListItemText, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { DoneAll } from "@material-ui/icons";
import ButtonWithSpinner from "../ButtonWithSpinner";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { getLocalOptions } from "../../helpers/userOptions";
import { Typography } from "@material-ui/core";
import getSocket from "../../helpers/socket";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import useCountPaused from '../../hooks/countMessages';
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    ticketsList: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStylesSoft,
        borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    },

    ticketsCheckbox: {
        flex: 1,
        position: "absolute",
        bottom: 0,
        padding: 10,
        boxShadow: '0px 3px 45px 0px rgba(0,0,0,0.31)',
        left: 0,
        zIndex: 9,
        width: '100%'
    },

    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: '18px',
    },

    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px",
    },
    checkInputs: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputCheck: {
        width: '75%'
    },
    buttonCheck: {
        width: '25%'
    },
    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

let fixedTickets = [];

const ticketSort = (a, b) => {
    const aInFixedTickets = fixedTickets.indexOf(a.id) > -1;
    const bInFixedTickets = fixedTickets.indexOf(b.id) > -1;
    if (aInFixedTickets && !bInFixedTickets) {
        return -1;
    }
    if (!aInFixedTickets && bInFixedTickets) {
        return 1;
    }
    if (aInFixedTickets == bInFixedTickets) {
        if (a.unreadMessages > 0 && b.unreadMessages == 0) {
            return -1;
        }
        if (a.unreadMessages == 0 && b.unreadMessages > 0) {
            return 1;
        }
    }
    return 0;
}

const ticketSortAsc = (a, b) => {
    if (a.createdAt < b.createdAt) {
        return -1;
    }
    if (a.createdAt > b.createdAt) {
        return 1;
    }
    return 0;
}

const getUnreadmessages = (ticket, user) => {
    if (ticket.status != 'group') {
        return ticket.unreadMessages;
    }

    if (!ticket.metas) {
        return ticket.unreadMessages;
    }

    for (const meta of ticket.metas) {
        if (meta.type == 'unreadMessages' && meta.userId == user.id) {
            ticket.unreadMessages = parseInt(meta.value);
        }
    }
    return ticket.unreadMessages;
}

const reducer = (state, action) => {
    const options = getLocalOptions();
    fixedTickets = options.fixedTickets;
    const sortDir = action.sortDir;
    const user = action.user;
    const setInternalCount = action.setInternalCount;
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            getUnreadmessages(ticket, user);


            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }


                if (ticket.unreadMessages > 0 && ticket.status === 'paused') {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                    console.log(state)
                }
            } else {
                state.push(ticket);
            }
        });

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        getUnreadmessages(ticket, user);

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
            if (setInternalCount) setInternalCount(prevState => prevState + 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;
        getUnreadmessages(ticket, user);
        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
            if (setInternalCount) setInternalCount(prevState => prevState + 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }

        if (action.status && action.status === 'group' && fixedTickets && fixedTickets.length > 0) {
            state.sort(ticketSort);
        }

        if (sortDir && sortDir === 'ASC') {
            state.sort(ticketSortAsc);
        }

        return [...state];
    }

    if (action.type === "DELETE_TICKET_BY_CONTACT") {
        const contactId = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId == contactId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
            if (setInternalCount) setInternalCount(prevState => prevState - 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TicketsList = ({
    status,
    searchParam,
    searchById,
    searchOnMessages,
    showAll,
    selectedQueueIds,
    selectedTagIds,
    updateCount,
    updateCountBadge,
    updatePausedBadge,
    title,
    style,
    sortDir,
    disableControls,
    selectedConnectionIds,
    selectedUserIds,
    selectedStatuses,
    dateFrom,
    dateTo,
    forceSearch,
    lastClientActivityMe,
    lastClientActivityClient,
    unreadMessagesSearchMe,
    unreadMessagesSearchClient,    
}) => {
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const [showWhatsappConnection, setShowWhatsappConnection] = useState(false);
    const { user } = useContext(AuthContext);
    const { getSettingValue, info, isActive, hashKey } = useContext(SettingsContext);
    const [disableAnchor, setDisableAnchor] = useState(false);
    const [fixedTickets, setFixedTickets] = useState([]);
    const [internalCount, setInternalCount] = useState(0);
    const { countPaused, setCountPaused } = useCountPaused();
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedActionCheckeds, setSelectedActionsCheckeds] = useState('');

    useEffect(() => {
        dispatch({ type: "RESET", status, sortDir });
        setPageNumber(1);
    }, [status, dispatch, showAll, selectedQueueIds, sortDir, forceSearch, hashKey]);

    useEffect(() => {
        setDisableAnchor(!isActive('showLinks'));
    }, [hashKey]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {
                const { data } = await api.get("/whatsapp/show-whatsapp-connection");
                setShowWhatsappConnection(data.show);
            };
            fetchData();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, []);

    useEffect(() => {
        //console.log('queueIds', selectedQueueIds)
        const options = getLocalOptions();
        setFixedTickets(options.fixedTickets || []);
    }, []);
   
    const { tickets, hasMore, loading, count } = useTickets({
        pageNumber,
        searchParam,
        searchOnMessages,
        searchById: searchById && Number(searchById),
        status,
        showAll,
        userIds: selectedUserIds && JSON.stringify(selectedUserIds),
        connectionIds:selectedConnectionIds && JSON.stringify(selectedConnectionIds),
        statuses: selectedStatuses && JSON.stringify(selectedStatuses),
        dateFrom,
        dateTo,
        queueIds: selectedQueueIds && JSON.stringify(selectedQueueIds),
        tagIds: selectedTagIds && JSON.stringify(selectedTagIds),
        isGroup: status === "group" ? true : null,
        sortDir: sortDir ? sortDir : "DESC",
        forceSearch,
        lastClientActivityClient,
        lastClientActivityMe,
        unreadMessagesSearchMe,
        unreadMessagesSearchClient,
        settingChange: hashKey
    });

    useEffect(() => {
        if (disableControls) {
            disableControls(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (!status && !searchParam && (!selectedTagIds || selectedTagIds.length === 0) && forceSearch !== true && forceSearch !== false) return;
        dispatch({
            type: "LOAD_TICKETS",
            payload: tickets,
            status: status,
            sortDir,
            user,
            setInternalCount
        });

        //console.log('tickets',tickets.length)
    }, [tickets]);

    useEffect(() => {
        if (updateCountBadge) {
            let totalUnread = 0;
            ticketsList.map(ticket => {
                if (ticket.unreadMessages > 0) {
                    totalUnread++;
                }
            });
            updateCountBadge(totalUnread);
        }

        if (updatePausedBadge) {
            let totalPaused = 0;
            tickets.map(ticket => {
                if (ticket.unreadMessages && ticket.status == 'paused') {
                    totalPaused++;
                }
            });
            setCountPaused(totalPaused);
            updatePausedBadge(totalPaused);
        }




    }, [updateCount, ticketsList]);




    const handleCheckboxChange = (itemId, isChecked) => {
      //  console.log('getting inside of checked', itemId)
        if (isChecked) {
            setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemId]);
        } else {
            setCheckedItems(prevCheckedItems =>
                prevCheckedItems.filter(id => id !== itemId)
            );
        }
    };

    const handleSelectActionCheckeds = (event) => {
        setSelectedActionsCheckeds(event.target.value);

    };


    const changeStatusTickets = async () => {
        try {
         //   console.log("checkedItems", checkedItems)
        // console.log("selectedActionCheckeds", selectedActionCheckeds);
            await api.post(`/tickets/lot/status`, {
                items: checkedItems,
                action: selectedActionCheckeds
            });
            toast.success(i18n.t("contacts.toasts.synced"));

        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        if (updateCount) {
            updateCount(count);
            setInternalCount(count);
        }

        /* if (updatePausedBadge) {
             updatePausedBadge(count);
             setInternalCount(count);
         }*/
    }, [updatePausedBadge, count]);

    useEffect(() => {
        if (updateCount) {
            updateCount(internalCount);
        }
    }, [internalCount]);

    useEffect(() => {
        const socket = getSocket(true);
        const userCanInteractWithAllGroups = user.profile === "admin" || isActive('userCanInteractWithAllGroups');
        const blockNonDefaultConnections = isActive('blockNonDefaultConnections');
        const ignoreUserConnectionForGroups = isActive('ignoreUserConnectionForGroups');
        const userAllowedToGroup = (ticket) => userCanInteractWithAllGroups ? true : ticket.contact.allowUsers.some((item) => item.id == user.id);

        const shouldUpdateTicket = ticket => {
            return (!ticket.userId || ticket.userId === user?.id || showAll) &&
                (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) &&
                (ticket.status != "group" || userAllowedToGroup(ticket)) &&
                (!blockNonDefaultConnections || (ticket.status == 'group' && ignoreUserConnectionForGroups) || !user?.whatsappId || ticket.whatsappId == user?.whatsappId);
        }

        const notBelongsToUserQueues = ticket =>
            ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

        const connectEvent = () => {
            if (status) {
                socket.emit("joinTickets", status);
            } else {
                socket.emit("joinNotification");
            }
        }

        const ticketEvent = data => {
            if (data.action === "updateUnread") {
                if (!data.userId || data.userId == user.id) {
                    dispatch({
                        type: "RESET_UNREAD",
                        payload: data.ticketId,
                        status,
                        sortDir,
                        setInternalCount
                    });
                }
            }

            if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET",
                    payload: data.ticket,
                    status,
                    sortDir,
                    user,
                    setInternalCount
                });
            }

            if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
                dispatch({ type: "DELETE_TICKET", payload: data.ticket.id, status, sortDir, setInternalCount });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId, status, sortDir, setInternalCount });
            }

            if (data.action === "change-contact-allow-user") {
                if (user.profile !== 'admin' && data.userIds && data.userIds.indexOf(user.id) === -1) {
                    dispatch({ type: "DELETE_TICKET_BY_CONTACT", payload: data.contactId, status, sortDir, setInternalCount });
                }
            }
        }

        const userOptionsEvent = data => {
            setFixedTickets(data.options.fixedTickets);
        }

        const appMessageEvent = data => {
            if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET_UNREAD_MESSAGES",
                    payload: data.ticket,
                    status,
                    sortDir,
                    user,
                    setInternalCount
                });
            }
        }

        const contactEvent = data => {
            if (data.action === "update") {
                dispatch({
                    type: "UPDATE_TICKET_CONTACT",
                    payload: data.contact,
                    status,
                    sortDir,
                    setInternalCount
                });
            }
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);

        if (status === 'group') {
            socket.on(`user_options_${user.id}`, userOptionsEvent);
        }

        if (status) {
            socket.on("appMessage", appMessageEvent);
            socket.on("contact", contactEvent);
        }

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            /*if (status) {
                socket.emit("leaveTickets", status);
            } else {
                socket.emit("leaveNotification");
            }
            socket.off("connect", connectEvent);
            socket.off("ticket", ticketEvent);
            if (status === "group") {
                socket.off(`user_options_${user.id}`, userOptionsEvent);
            }
            if (status) {
                socket.off("appMessage", appMessageEvent);
                socket.off("contact", contactEvent);
            }*/
            socket.disconnect();
        };
    }, [status, showAll, user, selectedQueueIds, sortDir, info]);

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevState => prevState + 1);
            clearInterval(pageNumberInterval);
        }, 100);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };


    return (
        <div style={style} className={classes.ticketsListWrapper}>
            {title && <Typography>{title}</Typography>}
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                onScroll={handleScroll}
            >
                <List style={{ paddingTop: 0 }}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                {i18n.t("ticketsList.noTicketsTitle")}
                            </span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("ticketsList.noTicketsMessage")}
                            </p>
                        </div>
                    ) : (
                        <>
                            {ticketsList.map(ticket => (
                                <TicketListItem
                                    disableAnchor={disableAnchor}
                                    ticket={ticket}
                                    fixedTickets={fixedTickets}
                                    key={ticket.id}
                                    showWhatsappConnection={showWhatsappConnection}
                                    isChecked={checkedItems.includes(ticket.id)}
                                    onCheckboxChange={handleCheckboxChange}
                                />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
            {checkedItems.length > 0 && (
                <Paper className={classes.ticketsCheckbox}>

                    {checkedItems.length} selecionad{checkedItems.length > 1 ? "os" : "o"}
                    <FormControl variant="outlined" className={classes.checkInputs} fullWidth>
                        <Select
                            displayEmpty
                            value={selectedActionCheckeds}
                            className={classes.inputCheck}
                            onChange={handleSelectActionCheckeds}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }} >


                            <MenuItem value="" disabled>
                                Selecione uma opção
                            </MenuItem>
                            <MenuItem key={-1} value={'paused'}>
                                <p>Pausar Tickets</p>
                            </MenuItem>
                            <MenuItem key={0} value={'closed'}>
                                <p>Finalizar Tickets</p>
                            </MenuItem>
                        </Select>
                        <ButtonWithSpinner
                            style={{ backgroundColor: '#2ECC71', color: '#00000', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                            loading={loading}
                            startIcon={<DoneAll />}
                            size="small"
                            variant="contained"
                            className={classes.buttonCheck}
                            onClick={changeStatusTickets}
                        >
                            Confirmar
                        </ButtonWithSpinner>
                    </FormControl>
                </Paper>
            )}
        </div>
    );
};

export default TicketsList;
