import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Paper, Button, CircularProgress, Box } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import BotList from "../../components/BotList";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		//padding: theme.spacing(1),
		borderRadius:0,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	helpMessage: {
		padding: "10px"
	},
	tag: {
		padding:3,
		paddingLeft:10,
		paddingRight:10,
		color:"#FFF",
		fontWeight: "bold",
		borderRadius: 4,
		display: "inline-block"
	},
	initialMessage: {
		padding:10,
		textAlign:'center'
	},
	mainBox: {
		padding:20,
		border:"1px solid #CCC",
		borderRadius:10,
		margin:20
	},
	options: {
		marginTop:20
	},
	listItem: {
		listStyle: "none",
		border:"1px solid #CCC",
		padding:5,
		margin:5
	},
	listItems: {
		margin:0,
		padding:0
	},
	listItemNumber: {
		borderRadius: "50%",
		padding:15,
		textAlign: "center",
		fontWeight: "bold",
		background: "#666",
		color: "#FFF",
		display: "inline-block",
		fontSize: "16px",
		marginRight: 20
	},
	listItemActionLabel: {
		display: "inline-block",
		marginLeft:20,
		marginRight: 15
	},
	actions: {
		display:"inline-block"
	},
	cleanButtonWrapper: {
		textAlign: "right"
	}
}));

const Bots = () => {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [mainList, setMainList] = useState([]);
	const [loadingInitial, setLoadingInitial] = useState(false);
	const [reload, setReload] = useState(false);
	const [queues, setQueues] = useState([]);
	const [users, setUsers] = useState([]);
	const [showRemoveModal, setShowRemoveModal] = useState(false);

	useEffect(() => {
		setLoading(true);
		setLoadingInitial(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchFirstList = async () => {
				try {
					const { data } = await api.get("/bots/", {
						params: { root: "1" },
					});
					setMainList(data.lists);
					setLoading(false);
					setLoadingInitial(false);
				} catch (err) {
					toastError(err);
				}
			};
			const fetchQueues = async() => {
				try {
					const { data } = await api.get("/queue/");
					setQueues(data);
				} catch (err) {
					toastError(err);
				}
			}
			const fetchUsers = async() => {
				try {
					const { data } = await api.get("/users/", {
						params: {pageNumber: 'all'}
					});
					setUsers(data.users);
				} catch (err) {
					toastError(err);
				}
			}
			fetchFirstList();
			fetchQueues();
			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [reload]);

	const save = async(list) => {
		try {
			let url = `/bots/`;
			if (list && list.id) url = `/bots/${list.id}`;
			await api.post(url, list);
		} catch (err) {
			toastError(err);
		}
	}

	const enable = async() => {
		setLoadingInitial(true);
		await save({first: true});
		setReload(!reload);
	}

	const cleanAll = async() => {
		try {
			let url = `/bots/${mainList.id}`;
			await api.delete(url);
			setShowRemoveModal(false);
			setReload(!reload);
		} catch (err) {
			toastError(err);
			setShowRemoveModal(false);
			setReload(!reload);
		}
	}

	return (
		<MainContainer className={classes.mainContainer}>
			<MainHeader>
				<Title>Bot de Mensagens</Title>
			</MainHeader>
			<Paper
				className={classes.mainPaper}
				variant="outlined"
			>
				<ConfirmationModal 
				title="Confirme a exclusão" 
				onConfirm={() => cleanAll()}
				onClose={() => setShowRemoveModal(false)}
				open={showRemoveModal}>
					Tem certeza que deseja excluir Tudo?
				</ConfirmationModal>
				<div className={classes.cleanButtonWrapper}><Button onClick={() => {setShowRemoveModal(true)}}>Limpar Bot</Button></div>
				<Box color="text.primary" className={classes.mainBox}>
					{ !loadingInitial && mainList && <p>As alterações no bot são salvas durante a edição</p>}
					{ !loadingInitial && !mainList && <p className={classes.initialMessage}>O bot ainda não está ativado. <Button onClick={enable}>Clique aqui para editar e ativar</Button></p> }
					{ loadingInitial && <CircularProgress /> }
					{ !loadingInitial && mainList && 
						<BotList 
							listId={mainList.id}
							queues={queues}
							users={users} />
					}
				</Box>
			</Paper>
		</MainContainer>
	);
};

export default Bots;
