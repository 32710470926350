import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import ColorPicker from "../ColorPicker";

const useStyles = makeStyles(theme => ({
	root: {
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%"
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textFieldNameContainer: {
		width: "100%"
	},
	textFieldColorContainer: {
		width: "100%",
		minHeight: 300
	}
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "O nome é muito curto")
		.max(150, "Informe um nome de até 150 caracteres")
		.required("Required"),
	content: Yup.string().min(8, "Informe uma mensagem de no mínimo 8 caracteres").max(60000, "Informe uma mensagem de no máximo 60.000 caracteres"),
});

const TagModal = ({ open, onClose, tagId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		name: "",
		color: ""
	};

	const [tag, setTag] = useState(initialState);
	const [color, setColor] = useState('#000');
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

	const nameRef = useRef();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchTag = async () => {
			if (initialValues) {
				setTag(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!tagId) return;

			try {
				const { data } = await api.get(`/tags/${tagId}`);
				if (isMounted.current) {
					setTag(data);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchTag();
	}, [tagId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setTag(initialState);
	};

	const handleChangeColor = (color) => {
		setColor(color);
	}

	const handleSaveTag = async values => {
		try {
			if (!values.color) {
				values.color = color.hex;
			}
			if (tagId) {
				await api.put(`/tags/${tagId}`, values);
				handleClose();
			} else {
				const { data } = await api.post("/tags", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("tagModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{tagId
						? `${i18n.t("tagModal.title.edit")}`
						: `${i18n.t("tagModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("tagModal.form.mainInfo")}
								</Typography>
								<div className={classes.textFieldNameContainer}>
									<Field
										as={TextField}
										label={i18n.t("tagModal.form.name")}
										name="name"
										autoFocus
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										inputRef={nameRef}
									/>
								</div>
								<div className={classes.textFieldColorContainer}>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.color")}
										name="color"
										id="color"
										onFocus={() => {
											setColorPickerModalOpen(true);
											nameRef.current.focus();
										}}
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: values.color }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(true)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
									/>
									<ColorPicker
										open={colorPickerModalOpen}
										handleClose={() => setColorPickerModalOpen(false)}
										onChange={color => {
											values.color = color;
											setTag(() => {
												return { ...values, color };
											});
										}}
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("tagModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{tagId
										? `${i18n.t("tagModal.buttons.okEdit")}`
										: `${i18n.t("tagModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default TagModal;
