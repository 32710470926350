import { Dialog, DialogTitle, TableBody, TableContainer, TableHead, TableCell, TableRow, Table, withStyles, Paper, DialogContent, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    spacingBlock: {
        marginBottom: theme.spacing(2)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

const StyledHighlighTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        '& td': {
            fontWeight: "bold"
        }
    },
}))(TableRow);

const ScheduledMessageSents = ({scheduledMessageId, open, onClose}) => {
    const [scheduledMessage, setScheduledMessage] = useState(null);
    const [scheduledMessageSents, setScheduledMessageSents] = useState(null);
    const [scheduledMessageDeliveries, setScheduledMessageDeliveries] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchScheduledMessage = async () => {
                if (!scheduledMessageId || !open) return;

                try {
                    const { data } = await api.get(`/scheduled-messages/${scheduledMessageId}/sents`);
                    setScheduledMessageSents(data.sents);
                    setScheduledMessageDeliveries(data.deliveries);
                } catch (err) {
                    toastError(err);
                }
            };

            fetchScheduledMessage();
        });
        return () => clearTimeout(delayDebounceFn);
    }, [scheduledMessageId, open]);

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const formatDate = (value) => {
		return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
	}

    return (
        <div>
            <Dialog open={open} onClose={handleOnClose} maxWidth="lg" scroll="paper">
                <DialogTitle>
                    Informações de envio do agendamento
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4">Envios realizados</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nome</StyledTableCell>
                                    <StyledTableCell>Número</StyledTableCell>
                                    <StyledTableCell>Data</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Detalhes</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!scheduledMessageDeliveries || !scheduledMessageDeliveries.length ? 
                                    <StyledTableRow>
                                        <StyledTableCell colSpan="5">Sem envios registrados</StyledTableCell>
                                    </StyledTableRow>
                                :
                                    scheduledMessageDeliveries.map(delivery => (
                                        <StyledTableRow key={delivery.id}>
                                            <StyledTableCell>{delivery.contact.name}</StyledTableCell>
                                            <StyledTableCell>{delivery.contact.number}</StyledTableCell>
                                            <StyledTableCell>{formatDate(delivery.createdAt)}</StyledTableCell>
                                            <StyledTableCell>{i18n.t(`scheduledMessage.deliveryStatus.${delivery.status}`)}</StyledTableCell>
                                            <StyledTableCell>{delivery.error || delivery.reason || '--'}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ScheduledMessageSents;