import React, { useContext, useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import GetApp from "@material-ui/icons/GetApp";


import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import Version from "../../helpers/version";
import Typography from "@material-ui/core/Typography";
import DateRangePicker from "../../components/DateRangePicker";
import * as moment from 'moment';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import Logs from '../../logs.json';


const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 10,
        overflow: 'hidden'
    },
    fixedHeightPaper: {
        //padding: theme.spacing(2),
        padding: 6,
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
        borderRadius: 0
    },
    highchartPaper: {
        height: 'auto',
        paddingLeft: '10px',
        marginBottom: 10,
        maxWidth: '100%',
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    filterContainer: {
        backgroundColor: '#FFF',
        marginBottom: 10
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    wrapperSelect: {
        padding: theme.spacing(2)
    },
    mainHeaderPage: {
        background: '#f9f9f9',
        paddingTop: 10,
        paddingLeft: 15
    },
    titlePage: {
        textAlign: 'center'
    },
    ulLogs: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
    },
    containerLogs: {
        transition: 'all ease 0.5s',
        display: 'none',
        background: '#f9f9f9',
        padding: 15
    },
    liLogs: {
        width: '100%',
        '&:hover $containerLogs': {
            display: 'block !important',
        },
        '&:hover $dateLogs': {
            background: '#3498db !important',
            color: '#ffffff !important'
        },
    },
    liLogsActive: {
        '& $containerLogs': {
            display: 'block !important',
        },
    },

    blockLogs: {
        width: '100%',
    },

    dateLogs: {
        cursor: 'pointer',
        fontWeight: 300,
        color: '#a6a6a6',
        fontSize: 15,
        transition: 'all ease 0.5s',
        width: '100%',
        border: '1px solid #f5f5f5',
        borderRadius: 5,
        padding: '10px 15px',
        marginBottom: 5
    },
    h4Log: {
        fontSize: 16,
        marginBottom: 0,
        lineHeight: 1,
        color: '#353535'
    },
    pLog: {
        fontSize: 13,
        color: '#353535'
    },
    imageLog: {

    },
    contLog: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '15px',
    },

    theLogs:{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', // Flex direction for mobile
        },
    },
    infoUpdates:{
        marginBottom: 15
    },
    player: {

    },
    playerVideo: {
        maxWidth: 600,
        borderRadius: 40
    }
}));

const Changelog = () => {
    const logs = Logs;
    const classes = useStyles();
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);
    const [userIds, setUserIds] = useState([]);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };
    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleToggleLogs = index => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <div>
            <MainContainer className={classes.mainContainer}>
                <Paper className={classes.mainHeaderPage}>
                    <Title className={classes.titlePage}>Changelog</Title>
                    <MainHeaderButtonsWrapper>
                        <Typography style={{ fontSize: "12px", cursor: "pointer", padding: "10px", textAlign: "right", fontWeight: "bold" }}>
                            Versão: {Version}
                        </Typography>
                    </MainHeaderButtonsWrapper>
                </Paper>
                <Paper
                    className={classes.mainPaper}
                    variant="outlined"
                    onScroll={handleScroll}
                >
                    <Grid container className={classes.filterContainer}>
                        <Grid item xs={12} md={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={3}>
                                    <ul className={classes.ulLogs}>
                                        {logs.map((entry, index) => (
                                            <li key={index} 
                                            className={`${classes.liLogs} ${activeIndex === index ? classes.liLogsActive : ''}`}
                                            onClick={() => handleToggleLogs(index)}
                                            >
                                                <div className={classes.blockLogs}>
                                                    <h5 className={classes.dateLogs}>{entry.date}</h5>
                                                    <div

                                                        className={`${classes.containerLogs} ${classes.hidden}`}
                                                    >
                                                        <div className={classes.theLogs}>
                                                            <div className={classes.contLog}>
                                                                {entry.tasks.map((entry2, index1) => (

                                                                    <div key={index1} className={classes.infoUpdates}>
                                                                        <h4 className={classes.h4Log}>{entry2.name}</h4>
                                                                        <p className={classes.PLog}>{entry2.description}</p>
                                                                    </div>

                                                                ))}
                                                            </div>
                                                            <div className={classes.player}>
                                                                {entry.tasks.map((entry2, index2) => (

                                                                    <>
                                                                        {entry2.image && entry2.image.endsWith('.mp4') ? (
                                                                            <video key={index2} src={entry2.image} className={classes.playerVideo} controls />
                                                                        ) : (
                                                                            <img
                                                                                key={index2}
                                                                                src={entry2.image}
                                                                                className={classes.ImageLog}
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper >
            </MainContainer >
        </div >
    );
};

export default Changelog;
