import React, { useContext, useEffect, useState } from 'react';
import { Badge, makeStyles } from "@material-ui/core";
import { AuthContext } from '../../context/Auth/AuthContext';
import UserStatusIcon from '../User/statusIcon';
import getSocket from '../../helpers/socket';

const socket = getSocket();

const useStyles = makeStyles(theme => ({
    minimizedChat: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        cursor: "pointer",
        '&:hover': {
            //background: theme.palette.total
        },
        '& img': {
            borderRadius: '50%',
            width: "48px",
            height: "48px"
        },
        position: "relative",
        marginTop: "5px",
        marginLeft: "6px",
        '&>span': {
            bottom: 0,
            right: 10,
        }
    },
    statusIcon: {
        position: "absolute",
        bottom: 0,
        left: 0
    }
}));

const RoomMinimized = ({ room, onClick }) => {
    const classes = useStyles();

    const { user } = useContext(AuthContext);
    const [roomMe, setRoomMe] = useState({});

    const handleRoomClick = (room) => {
        if (onClick) onClick(room);
    }

    useEffect(() => {
        if (room.participants) {
            const me = room.participants.find(p => p.userId === user.id);
            if (me) setRoomMe(me);
        }
    }, [room, user]);

    const readCountEvent = data => {
        if (room.id === data.roomId) {
            setRoomMe({ ...roomMe, unreadMessages: data.unreadCount });
        }
    }

    useEffect(() => {
        const publicToken = window.localStorage.getItem('public-token');

        socket.on(`chat:room:readCount#${publicToken}`, readCountEvent);

        return () => {
            socket.off(`chat:room:readCount#${publicToken}`, readCountEvent);
        }
    }, [room]);

    return (
        <li className={classes.minimizedChat} onClick={() => handleRoomClick(room)}>
            <Badge badgeContent={roomMe.unreadMessages} color="secondary">
                <img src={`https://ui-avatars.com/api/?name=${room.userId == user.id ? room.participant.name : room.user.name}`} /> 
                <UserStatusIcon user={room.userId == user.id ? room.participant : room.user} className={classes.statusIcon} />
            </Badge>
        </li>
    );
};

export default RoomMinimized;