import React, { useState } from 'react';
import vCard from "vcf";
import api from '../../services/api';
import { Avatar, Button, IconButton, makeStyles } from '@material-ui/core';
import { Chat, Error, PersonAdd } from '@material-ui/icons';
import NewTicketModal from '../NewTicketModal';
import { getNationalFormatedPhoneNumber } from '../../utils';

const useStyles = makeStyles(theme => ({
    vCard: {
        display: "flex",
        alignItems: "center",
        "& > div": {
            flexBasis: "auto",
            marginRight: "10px"
        },
        width: "100%",
        paddingTop: 20,
        marginTop: 10,
        borderTop: "1px dashed #BBB"
    },
    vCardFirst: {
        display: "flex",
        alignItems: "center",
        "& > div": {
            flexBasis: "auto",
            marginRight: "10px"
        },
        width: "100%"
    },
    vCardAvatar: {},
    vCardButtons: {
        marginTop: 15,
        borderTop: "1px solid #e0e0e0",
        paddingTop: 7,
        textAlign:"center"
    },
    renderError: {
        color: 'red',
        fontSize: '0.8em'
    }
}));

const MessageContact = ({ message }) => {
    const [contactsMap, setContactsMap] = useState({});
    const [newTicketModalOpened, setNewTicketModalOpened] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);

    const classes = useStyles();

    const getContactByNumber = async contactNumber => {
        if (contactsMap[contactNumber]) {
            return contactsMap[contactNumber];
        }
        try {
            const { data } = await api.get(`/contacts/?searchParam=${contactNumber}`);
            if (data.contacts.length > 0) {
                setContactsMap({ ...contactsMap, [contactNumber]: data.contacts[0] });
                return data.contacts[0];
            }
            return false;
        } catch (err) {
            return false;
        }
    }

    const saveContact = async (contact) => {
        try {
            const { data } = await api.post("/contacts/", contact);
            if (data) {
                setContactsMap({ ...contactsMap, [data.number]: data });
                return data;
            }
            return false;
        } catch (err) {
            return false;
        }
    }

    const handleCloseNewTicketModal = () => {
        setNewTicketModalOpened(false);
        setSelectedContact(null);
    }

    const openNewTicketModal = (contact) => {
        setSelectedContact(contact);
        setNewTicketModalOpened(true);
    }

    const render = () => {
        try {
            const body = message.body;
            const isArray = body.indexOf('json:') === 0;
            let cards = [];
            if (isArray) {
                cards = JSON.parse(body.replace('json:', '')).map(contact => {
                    return contact.vcard.replace(/\n/g, "\r\n");
                });
            } else {
                cards = [body.replace(/\n/g, "\r\n")];
            }
            return <React.Fragment>{cards.map((card, index) => renderVCard(vCard().parse(card), index))}</React.Fragment>
        } catch (e) {
            return <span className={classes.renderError}><Error />Erro ao processar mensagem de contato: <strong>{e.message}</strong></span>
        }
    }

    let firstCard = true;

    const renderVCard = (card, parentIndex) => {
        try {
            var tels = [];
            const contactName = card.data.fn._data;

            if (card.data.tel.length) {
                for (let i = 0; i < card.data.tel.length; i++) {
                    tels.push(card.data.tel[i].waid);
                    getContactByNumber(card.data.tel[i].waid);
                }
            } else {
                tels.push(card.data.tel.waid);
                getContactByNumber(card.data.tel.waid);
            }
            return (
                <React.Fragment key={parentIndex}>
                    {tels.map((tel, index) => {
                        const phonenumber = getNationalFormatedPhoneNumber(tel);
                        let isFirstVCard = false;
                        if (firstCard) {
                            isFirstVCard = true;
                            firstCard = false;
                        }
                        return (<React.Fragment key={index}>
                            <div key={index} className={isFirstVCard ? classes.vCardFirst : classes.vCard}>
                                <Avatar
                                    alt={contactName}
                                    src={card.data.photo && card.data.photo.type == "base64" ? "data:image/png;base64, " + card.data.photo._data : null}
                                    className={classes.vCardAvatar}
                                ></Avatar>
                                <div className={classes.vCardContent}>
                                    <strong>{contactName}</strong><br />
                                    {phonenumber}
                                </div>
                            </div>
                            <div className={classes.vCardButtons}>
                                {contactsMap[tel] && <Button color="primary" title="Iniciar conversa" onClick={() => openNewTicketModal(contactsMap[tel])}><Chat /> Iniciar conversa</Button>}
                                {!contactsMap[tel] && <IconButton title="Salvar contato" onClick={() => saveContact({ name: contactName, number: tel})}><PersonAdd /> Salvar contato</IconButton>}
                            </div>
                        </React.Fragment>);
                    })}
                </React.Fragment>
            );
        } catch (e) {
            console.error(e);
            return (
                <div>{message.body}</div>
            )
        }
    }
    return <React.Fragment>
        {newTicketModalOpened && <NewTicketModal modalOpen={newTicketModalOpened} onClose={handleCloseNewTicketModal} forContact={selectedContact} />}
        {render()}
    </React.Fragment>
}

export default MessageContact;