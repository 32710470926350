import React, { useContext, useState, useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import GetApp from "@material-ui/icons/GetApp";

import Chart from "./Chart";
import SelectTags from "../../components/SelectTags";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectContacts from "../../components/SelectContacts";

import DateRangePicker from "../../components/DateRangePicker";
import * as moment from 'moment';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import SelectStatus from "../../components/SelectStatus";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 10,
        overflow: 'hidden'
    },
    fixedHeightPaper: {
        //padding: theme.spacing(2),
        padding: 6,
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
        borderRadius: 0
    },
    highchartPaper: {
        height: 'auto',
        paddingLeft: '10px',
        marginBottom: 10,
        maxWidth: '100%',        
		boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    filterContainer: {
        backgroundColor: '#FFF',
        marginBottom: 10
    },
    paper: {
        padding: theme.spacing(2),        
		boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    wrapperSelect: {
        padding: theme.spacing(2)
    }
}));

const Report = () => {
    const classes = useStyles();
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);
    const [userIds, setUserIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [loadingResponseTime, setLoadingResponseTime] = useState(false);
    const [loadingTicketTime, setLoadingTicketTime] = useState(false);
    const [exporting, setExporting] = useState(false);
    const { user } = useContext(AuthContext);

    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    const handleChangeStatuses = (values) => {
        setStatuses(values.map((item) => item.id));
    }

    const downloadExport = async () => {
        setExporting(true);
        try {
            const format = 'xlsx';
            const dateStart = moment(period[0]).format('YYYY-MM-DD');
            const dateEnd = moment(period[1]).format('YYYY-MM-DD');
            const userIdsString = JSON.stringify(userIds);
            const tagIdString = tagIds ? JSON.stringify(tagIds) : '';
            const statusesString = statuses ? JSON.stringify(statuses) : '';
            
            const { data } = await api.get(`/report/export/tickets?userIdsString=${userIdsString}&tagIdsString=${tagIdString}&statuses=${statusesString}&dateStart=${dateStart}&dateEnd=${dateEnd}&format=${format}&`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `historico_atendimentos.${format}`;
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }

    useEffect(() => {
        async function getReportUser() {
            if (user && user.profile === 'user') {
                await setUserIds([user.id]);
            }
        }
        getReportUser();
    }, [user]);


    return (
        <div>
            <Container maxWidth={false} className={classes.container}>
                <Grid container className={classes.filterContainer}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>

                                {user.profile === 'admin' ? (
                                    <>
                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectStatus outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeStatuses} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectUsers multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeUserIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectQueues multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeQueueIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectTags outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeTagIds} />
                                            </div>
                                        </Grid></>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>
                                    </>
                                )}


                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.highchartPaper}>
                            <Chart
                                contactIds={contactIds}
                                queueIds={queueIds}
                                tagIds={tagIds}
                                userIds={userIds}
                                statuses={statuses}
                                period={period}
                                endpoint="/report/ticket-number/"
                                title={<span style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }} >Atendimentos <GetApp style={{ paddingTop: 5 }} onClick={downloadExport} /></span>}
                                setLoading={setLoadingTickets}
                                yAxisTitle="Número de Atendimentos" />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.highchartPaper}>
                            <Chart
                                contactIds={contactIds}
                                queueIds={queueIds}
                                tagIds={tagIds}
                                userIds={userIds}
                                statuses={statuses}
                                period={period}
                                setLoading={setLoadingResponseTime}
                                endpoint="/report/response-time/"
                                title="Tempo de Resposta"
                                yAxisTitle="Tempo de Resposta em minutos" />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.highchartPaper}>
                            <Chart
                                contactIds={contactIds}
                                queueIds={queueIds}
                                tagIds={tagIds}
                                userIds={userIds}
                                statuses={statuses}
                                period={period}
                                setLoading={setLoadingTicketTime}
                                endpoint="/report/ticket-response-time/"
                                title="Tempo de Resposta do Atendimento"
                                yAxisTitle="Tempo de Resposta em minutos" />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Report;
