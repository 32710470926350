import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#eee",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: "#eee",
        flexDirection: "column",
        padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    contactAvatar: {
        margin: 15,
        width: 160,
        height: 160,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    ticketDate: {
        backgroundColor: "#F3F3F3",
        padding: 10,
        textAlign: "center",
        marginBottom: 10
    }
}));

const formatDate = (value) => {
    return value ? moment(value).format("DD/MM/YYYY à[s] HH:mm") : '';
}

const TicketInfoDrawer = ({ open, handleDrawerClose, ticket, loading }) => {
    const classes = useStyles();

    const [transfers, setTransfers] = useState([]);
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        if (ticket && ticket.id) {
            setLocalLoading(true);
            const delayDebounceFn = setTimeout(() => {
                const fetchTicket = async () => {
                    try {
                        const ticketId = ticket.id;
                        const { data } = await api.get(`/tickets/${ticketId}/logs`);
                        
                        setTransfers(data.transfers);
                        console.log(data.transfers);
                        setLocalLoading(false);
                    } catch (err) {
                        setLocalLoading(false);
                        toastError(err);
                    }
                };
                fetchTicket();
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [ticket]);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.header}>
                <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    Dados do Ticket
                </Typography>
            </div>
            {loading || localLoading ? (
                <ContactDrawerSkeleton classes={classes} />
            ) : (
                <div className={classes.content}>
                    <Paper square variant="outlined" className={classes.contactHeader}>
                        <Typography>#{ticket.id}</Typography>
                    </Paper>
                    <Paper square variant="outlined" className={classes.contactDetails}>
                        <Typography variant="subtitle1">
                            Histórico de Transferências
                        </Typography>
                        {transfers.map(transfer => (
                            <Paper
                                key={transfer.id}
                                square
                                variant="outlined"
                                className={classes.contactExtraInfo}
                            >
                                <div className={classes.ticketDate}>{formatDate(transfer.createdAt)}</div>
                                <Typography component="div" style={{ paddingTop: 2 }}>
                                    <strong>Tranferido de:</strong>
                                </Typography>
                                <Typography component="div">
                                    Atendente: <strong>{transfer.userId ? transfer.user.name : '(Não definido)'}</strong>
                                </Typography>
                                <Typography component="div">
                                    Setor: <strong>{transfer.queueId ? transfer.queue.name : '(Não definido)'}</strong>
                                </Typography>
                                <Typography component="div">
                                    Conexão: <strong>{transfer.whatsappId ? transfer.whatsapp.name : '(Não definido)'}</strong>
                                </Typography>
                                <Typography component="div" style={{ marginTop: 10 }}><strong>Para</strong>:</Typography>
                                <Typography component="div">
                                    Atendente: <strong>{transfer.userTargetId ? transfer.userTarget.name : '(Não definido)'}</strong>
                                </Typography>
                                <Typography component="div">
                                    Setor: <strong>{transfer.queueTargetId && transfer.queueTarget ? transfer.queueTarget.name : '(Não definido)'}</strong>
                                </Typography>
                                <Typography component="div">
                                    Conexão: <strong>{transfer.whatsappTargetId ? transfer.whatsappTarget.name : '(Não definido)'}</strong>
                                </Typography>
                            </Paper>
                        ))}
                    </Paper>
                </div>
            )}
        </Drawer>
    );
};

export default TicketInfoDrawer;
