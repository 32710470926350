import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const WebhookSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Obrigatório"),
    urlType: Yup.string().required("Obrigatório"),
    url: Yup.string().required("Obrigatório")
});

const WebhookModal = ({ open, onClose, webhookId }) => {
    const classes = useStyles();

    const initialState = {
        name: "",
        url: "",
        urlType: "static",
        type: ""
    };

    const [webhook, setWebhook] = useState(initialState);

    useEffect(() => {
        const fetchWebhook = async () => {
            if (!webhookId) return;
            try {
                const { data } = await api.get(`/webhooks/${webhookId}`);
                setWebhook(prevState => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchWebhook();
    }, [webhookId, open]);

    const handleClose = () => {
        onClose();
        setWebhook(initialState);
    };

    const handleSaveWebhook = async values => {
        const webhookData = values;
        
        try {
            if (webhookId) {
                await api.put(`/webhooks/${webhookId}`, webhookData);
            } else {
                await api.post("/webhooks", webhookData);
            }
            toast.success(i18n.t("webhookModal.success"));
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {webhookId
                        ? `${i18n.t("webhookModal.title.edit")}`
                        : `${i18n.t("webhookModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={webhook}
                    enableReinitialize={true}
                    validationSchema={WebhookSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWebhook(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("webhookModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Field
                                        as={Select}
                                        label={i18n.t("webhookModal.form.urlType")}
                                        placeholder={i18n.t("webhookModal.form.urlType")}
                                        name="urlType"
                                        error={touched.urlType && Boolean(errors.urlType)}
                                        helperText={touched.urlType && errors.urlType}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    >
                                        <MenuItem value="post">Somente POST</MenuItem>
                                        <MenuItem value="restful">REST</MenuItem>
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("webhookModal.form.url")}
                                        name="url"
                                        error={touched.url && Boolean(errors.url)}
                                        helperText={touched.url && errors.url}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("webhookModal.form.headerString")}
                                        name="headerString"
                                        error={touched.headerString && Boolean(errors.headerString)}
                                        helperText={touched.headerString && errors.headerString}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={Select}
                                        label={i18n.t("webhookModal.form.type")}
                                        name="type"
                                        error={touched.type && Boolean(errors.type)}
                                        helperText={touched.type && errors.type}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    >
                                        <MenuItem value="tickets">Atendimentos (criação, atualização e exclusão)</MenuItem>
                                        <MenuItem value="messages">Mensagens (criação, atualização e exclusão)</MenuItem>
                                        <MenuItem value="report_tickets">Relatório de Atendimentos (enviar no período selecionado)</MenuItem>
                                    </Field>
                                </div>
                                {values.type && values.type.indexOf('report_') === 0 && 
                                    <div className={classes.multFieldLine}>
                                        <Field as={TextField}
                                            type="number"
                                            label={i18n.t("webhookModal.form.sendEveryMinutes")}
                                            name="sendEveryMinutes"
                                            error={touched.type && Boolean(errors.type)}
                                            helperText={touched.type && errors.type}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth 
                                        />
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("webhookModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {webhookId
                                        ? `${i18n.t("webhookModal.buttons.okEdit")}`
                                        : `${i18n.t("webhookModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default WebhookModal;
