import React, { useContext, useEffect, useState } from "react";
import NotificationBar from "./bar";
import NotificationModal from "./modal";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import NotificationBlocked from "./blocked";
import NotificationLimit from "./limit";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const reducer = (state, oldState) => {
    if (!state) return {bar: [], modal: []};
    let result = oldState ? oldState : {bar: [], modal: []};
    state.forEach((item) => {
        if (item.notification.alertType === 'modal') {
            result.modal.push(item);
        }
        if (item.notification.alertType === 'top_danger' || item.notification.alertType === 'top_success') {
            result.bar.push(item);
        }
    });
    return result;
}


const NotificationReceiver = () => {
    const [structNotifications, setStructNotifications] = useState({bar: [], modal: []});
    const [openModal, setOpenModal] = useState(false);
    const [openBar, setOpenBar] = useState(false);
    const [reload, setReload] = useState(0);
    const [blocked, setBlocked] = useState(false);
    const [controlReloadInfo, setControlReloadInfo] = useState(0);
    const [overflowUsersLimit, setOverflowUsersLimit] = useState(false);
    const [overflowConnectionsLimit, setOverflowConnectionsLimit] = useState(false);
    const { user } = useContext(AuthContext);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchToShowNotifications = async () => {
				try {
					const { data } = await api.get("/notifications/show");
                    setStructNotifications(reducer(data.notifications.notifications));
				} catch (err) {
					toastError(err);
				}
			};
			fetchToShowNotifications();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [reload]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
			const fetchOptions = async () => {
				try {
					const { data } = await api.get("/info");
                    setBlocked(data.blocked);
                    setOverflowUsersLimit(data.overflowUsersLimit);
                    setOverflowConnectionsLimit(data.overflowConnectionsLimit);
                    setTimeout(() => {
                        setControlReloadInfo(Math.random());
                    }, 60000);
				} catch (err) {
					toastError(err);
				}
			};
			fetchOptions();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
    }, [controlReloadInfo]);

    useEffect(() => {
        const notificationReceiveEvent = data => {
            if (data.action === "create") {
                setTimeout(() => {
                    setReload(Math.random());
                }, 10000);
            }
        }

        const blockedEvent = data => {
            setBlocked(data.blocked);
        }

        socket.on(`notification_${user.id}`, notificationReceiveEvent);
        socket.on(`blocked`, blockedEvent);
        
        return () => {
            socket.off(`notification_${user.id}`, notificationReceiveEvent);
            socket.off(`blocked`, blockedEvent);
        };

	}, [user]);

    useEffect(() => {
        if (structNotifications.bar.length > 0) {
            setOpenBar(true);
        }
        if (structNotifications.modal.length > 0) {
            setOpenModal(true);
        }
    }, [structNotifications]);

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleCloseBar = () => {
        setOpenBar(false);
    }

    return (
        <div>
            {openBar && structNotifications.bar.length > 0 && <NotificationBar onClose={handleCloseBar} notifications={structNotifications.bar} />}
            {structNotifications.modal.length > 0 && <NotificationModal open={openModal} onClose={handleCloseModal} notifications={structNotifications.modal} />}
            {blocked && <NotificationBlocked open={blocked} />}
            {(overflowUsersLimit || overflowConnectionsLimit) && <NotificationLimit overflowUsers={overflowUsersLimit} />}
        </div>
    )
}

export default NotificationReceiver;