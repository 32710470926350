import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, InputLabel, Select, MenuItem, Switch } from "@material-ui/core";
import { AddOutlined, HighlightOffOutlined } from "@material-ui/icons";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoodIcon from "@material-ui/icons/Mood";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ModalImage from "react-modal-image";
import IOSSwitch from "../IOSSwitch";
import FileIconImage from "../FileIconImage";

const useStyles = makeStyles(theme => ({
    options: {
        marginTop:20
    },
    listItem: {
        listStyle: "none",
        border:"1px solid #CCC",
        padding:5,
        margin:5
    },
    listItems: {
        margin:0,
        padding:0
    },
    listItemNumber: {
        borderRadius: "50%",
        padding:12,
        paddingLeft:15,
        paddingRight:15,
        textAlign: "center",
        fontWeight: "bold",
        background: "#03b1fc",
        color: "#FFF",
        display: "inline-block",
        fontSize: "16px",
        marginRight: 20
    },
    listItemActionLabel: {
        display: "inline-block",
        marginLeft:20,
        marginRight: 15
    },
    actions: {
        display:"inline-block"
    },
    actionsList: {
        display: "block",
        marginTop: 20,
        marginLeft:20,
        paddingLeft:20,
        borderLeft: "1px solid #000"
    },
    insideList: {
        display: "block",
    },
    removeListWrapper: {
        textAlign: "right",
        float: "right"
    },
    removeOptionWrapper: {
        textAlign: "right",
        float: "right"
    },
    textFieldStyle: {
        '& .MuiOutlinedInput-input': {
            paddingTop: "10px",
            paddingBottom: "10px"
        }
    },
    textFieldStyleOption: {
        '& .MuiOutlinedInput-input': {
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "30px"
        }
    },
    textFieldStyleMessage: {
        '& .MuiOutlinedInput-input': {
            paddingTop: "5px",
            paddingBottom: "5px"
        },
        width: "100%",
        paddingTop: "10px",
        marginTop: "15px"
    },
    lineSpacing: {
        marginLeft: "10px"
    },
    enableWrapper: {
        textAlign: "right",
        marginBottom: "20px"
    },
    emojiBox: {
        position: "absolute",
        borderTop: "1px solid #e8e8e8",
        zIndex: 10,
        right:'30px',
        top:0
    },
    emojiBoxLeft: {
        position: "absolute",
        borderTop: "1px solid #e8e8e8",
        zIndex: 10,
        left:'30px',
        top:0
    },
    emojiWrapper: {
        position: "absolute",
        right: '5px',
        bottom: 0
    },
    emojiSmallWrapper: {
        position: "absolute",
        right: '5px',
        bottom: '3px'
    },
    textFieldEmojiWrapper: {
        position: "relative"
    },
    textFieldEmojiSmallWrapper: {
        position: "relative",
        display:"inline-block"
    },
    afterMessage: {
        position:"relative"
    },
    uploadInput: {
        display: 'none'
    }
}));

const BotList = ({ queues, users, listId, triggerSave, triggerRemove }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [mainList, setMainList] = useState({});
    const [reload, setReload] = useState(false);
    const [listModalOpen, setListModalOpen] = useState({});
    const [optionModalOpen, setOptionModalOpen] = useState({});
    const saveDebounce = useRef(null);
    const [showEmoji, setShowEmoji] = useState(false);
    const [showEmojiTextOption, setShowEmojiTextOption] = useState({});
    const [showEmojiTextOptionText, setShowEmojiTextOptionText] = useState({});
    const [medias, setMedias] = useState([]);
    const [optionMedias, setOptionMedias] = useState([]);
    const listFileRef = useRef();
    const listOptionFileRef = useRef({});


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                try {
                    const { data } = await api.get(`/bots/${listId}`);
                    setMainList(data.lists);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [reload]);

    const remove = async(list) => {
        try {
            let url = `/bots/${list.id}`;
            const { data } = await api.delete(url);
            if (triggerRemove) {
                triggerRemove(list.id);
            }
            return data.deleted;
        } catch (err) {
            toastError(err);
        }
    }

    const removeOption = async(option) => {
        try {
            let url = `/bots/option/${option.id}`;
            const { data } = await api.delete(url);
            setReload(!reload);
            return data.deleted;
        } catch (err) {
            toastError(err);
        }
    }

    const save = async(list) => {
        try {
            let url = `/bots/`;
            if (list && list.id) url = `/bots/${list.id}`;
            const { data } = await api.post(url, list);
            if (triggerSave) {
                triggerSave();
            }
            return data.lists;
        } catch (err) {
            toastError(err);
        }
    }

    const upload = async(list) => {
        setLoading(true);
        const formData = new FormData();
        
        if (!medias || medias.length == 0) {
            setLoading(false);
            return;
        }
        formData.append("body", '');
        medias.forEach(media => {
            formData.append("medias", media);
        });

        try {
            const { data } = await api.post(`/bots/${list.id}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            if (triggerSave) {
                triggerSave();
            }
            setMedias([]);
            setLoading(false);
            setMainList(data.lists);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    }

    const uploadOption = async(option, optionIndex, medias) => {
        setLoading(true);
        if (!medias || medias.length == 0) {
            setLoading(false);
            return;
        }
        const formData = new FormData();
        formData.append("body", '');
        medias.forEach(media => {
            formData.append("medias", media);
        });

        try {
            const { data } = await api.post(`/bots/${option.botListId}/options/${option.id}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            if (triggerSave) {
                triggerSave();
            }
            setOptionMedias([]);
            setLoading(false);
            changeOptionFieldLocal('absoluteMediaUrl', optionIndex, data.option.absoluteMediaUrl);
            changeOptionFieldLocal('mediaUrl', optionIndex, data.option.mediaUrl);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }

    }

    const removeUpload = async(list) => {
        setLoading(true);
        try {
            const { data } = await api.delete(`/bots/${list.id}/upload`);
            if (triggerSave) {
                triggerSave();
            }
            setLoading(false);
            setMainList(data.lists);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    }

    useEffect(() => {
        upload(mainList);
    }, [medias]);

    const removeChildList = (index) => {
        let options = mainList.options;
        options[index].openBotListId = null;
        options[index].actionType = '';
        setMainList({...mainList, options});
    }

    const handleAddEmoji = (e, field) => {
        let emoji = e.native;
        let value = mainList[field] + emoji;
        onChangeListField(field, value);
    };

    const handleClickShowEmojiOption = (index) => {
        setShowEmojiTextOption({...showEmojiTextOption, [index]: true});
    }

    const handleClickHideEmojiOption = (index) => {
        setShowEmojiTextOption({...showEmojiTextOption, [index]: false});
    }

    const handleClickShowEmojiOptionText = (index) => {
        setShowEmojiTextOptionText({...showEmojiTextOptionText, [index]: true});
    }

    const handleClickHideEmojiOptionText = (index) => {
        setShowEmojiTextOptionText({...showEmojiTextOptionText, [index]: false});
    }

    const addOption = async (list) => {
        let maxNumber = 0;
        if (!list.options) {
            list.options = [];
        }
        if (list.options.length > 0) {
            maxNumber = list.options.reduce((total, value) => {return total && total > value.number ? total : value.number}, 0);
        }
        let options = list.options;
        options.push({number: maxNumber+1, text: '', actionType: '', list: {}});
        setMainList({...mainList, options});
        const lists = await save(mainList);
        setMainList(lists);
    }

    useEffect(() => {
        return () => clearTimeout(saveDebounce.current);
    }, []);

    const onChangeListField = (field, value) => {
        setMainList({...mainList, [field]: value});
        let list = mainList;
        list[field] = value;
        clearTimeout(saveDebounce.current);
        saveDebounce.current = setTimeout(() => {
            save(list);
        }, 1000);
    }

    const changeOptionFieldLocal = (field, index, value) => {
        let options = mainList.options;
        options[index][field] = value;
        setMainList({...mainList, options});
    }

    const changeOptionField = (field, index, value) => {
        changeOptionFieldLocal(field, index, value);
        if (field == 'actionType' && value == 'list') {
            createOptionList(index);
        } else {
            clearTimeout(saveDebounce.current);
            saveDebounce.current = setTimeout(() => {
                save(mainList);
            }, 1000);
        }
    }

    const createOptionList = async(index) => {
        let options = mainList.options;
        clearTimeout(saveDebounce.current);
        saveDebounce.current = setTimeout(async() => {
            let list = await save({optionId: options[index].id});
            if (list) {
                options[index].openBotListId = list.id;
                setMainList({...mainList, options});
            }
        }, 1000);
    }

    const openOptionModal = (list) => {
        setOptionModalOpen({...optionModalOpen, [list.id]: true});
    }

    const closeOptionModal = (list) => {
        setOptionModalOpen({...optionModalOpen, [list.id]: false});
    }

    const openListModal = (list) => {
        setListModalOpen({...listModalOpen, [list.id]: true});
    }

    const closeListModal = (list) => {
        setListModalOpen({...listModalOpen, [list.id]: false});
    }

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return; 
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    }

    const removeOptionUpload = async(option, optionIndex) => {
        setLoading(true);
        try {
            const { data } = await api.delete(`/bots/${option.botListId}/options/${option.id}/upload`);
            if (triggerSave) {
                triggerSave();
            }
            setLoading(false);
            changeOptionFieldLocal("mediaUrl", optionIndex, data.option.mediaUrl);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    }

    const showFilePicker = () => {
        listFileRef.current.click();
    }

    const showOptionFilePicker = (optionIndex) => {
        listOptionFileRef.current[optionIndex].click();
    }

    return (
        <div className={classes.mainList}>
            {mainList.first && 
                <div className={classes.enableWrapper}>
                    {mainList.enabled && 
                        <span>Desabilitar Bot</span>
                    }
                    {!mainList.enabled &&
                        <span>Habilitar Bot</span>
                    }
                    <IOSSwitch checked={mainList.enabled} onChange={(event) => {onChangeListField('enabled', event.target.checked)}} name="enabled" />
                    {mainList.enabled}
                </div>
            }
            {!mainList.first && 
                <div className={classes.removeListWrapper}>
                    <Button onClick={() => openListModal(mainList)}>
                        <HighlightOffOutlined />
                    </Button>
                </div>
            }
            <ConfirmationModal 
                title="Confirme a exclusão" 
                onConfirm={() => remove(mainList)}
                onClose={() => closeListModal(mainList)}
                open={listModalOpen[mainList.id]}>
                    Tem certeza que deseja excluir essa lista? Todas as opções serão removidas
                </ConfirmationModal>
            <div className={classes.textFieldEmojiWrapper}>
                <TextField 
                    value={mainList.text} 
                    label="Saudação" 
                    variant="outlined"
                    fullWidth={true}
                    className={classes.textFieldStyle}
                    InputLabelProps={{ shrink: true }}
                    multiline={true}
                    rows={3}
                    onChange={(event) => onChangeListField('text', event.target.value)}/>
                <div className={classes.emojiWrapper}>
                    {!mainList.mediaUrl ? 
                    <div className={classes.emojiButton} onClick={e => showFilePicker()}>
                        <input
                            multiple
                            type="file"
                            ref={listFileRef}
                            disabled={loading}
                            className={classes.uploadInput}
                            onChange={handleChangeMedias}
                            />
                        <AttachFileIcon />
                    </div>
                    : <div className={classes.emojiButton}>
                        <FileIconImage fileUrl={mainList.absoluteMediaUrl} width={25} />
                        <CloseIcon onClick={() => removeUpload(mainList)}/>
                    </div>}
                    <div className={classes.emojiButton} onClick={e => setShowEmoji(true)}>
                        <MoodIcon />
                    </div>
                    {showEmoji && <div className={classes.emojiBox}>
                        <ClickAwayListener onClickAway={e => setShowEmoji(false)}>
                            <Picker
                                perLine={16}
                                showPreview={false}
                                showSkinTones={false}
                                onSelect={e => handleAddEmoji(e, 'text')}
                            />
                        </ClickAwayListener>
                    </div>}
                </div>
            </div>
            <div className={classes.options}>
                <h3>Opções</h3>
                <ul className={classes.listItems}>
                    {mainList.options && mainList.options.map((option, optionIndex) => (
                        <li className={classes.listItem} key={option.number}>
                            <div className={classes.removeOptionWrapper}>
                                <Button onClick={() => openOptionModal(option)}>
                                    <HighlightOffOutlined />
                                </Button>
                            </div>
                            <ConfirmationModal 
                                title="Confirme a exclusão" 
                                onConfirm={() => removeOption(option)}
                                onClose={() => closeOptionModal(option)}
                                open={optionModalOpen[option.id]}>
                                    Tem certeza que deseja excluir essa opção? Todas as listas filhas também serão removidas
                                </ConfirmationModal>
                            <InputLabel className={classes.listItemNumber}>{option.number}</InputLabel>

                            <div className={classes.textFieldEmojiSmallWrapper}>
                                <TextField className={classes.textFieldStyleOption} value={option.text} variant="outlined" onChange={(event) => changeOptionField('text', optionIndex, event.target.value)} />
                                <div className={classes.emojiSmallWrapper}>
                                    <div className={classes.emojiButton} onClick={e => handleClickShowEmojiOption(optionIndex)}>
                                        <MoodIcon />
                                    </div>
                                    {showEmojiTextOption[optionIndex] && <div className={classes.emojiBoxLeft}>
                                        <ClickAwayListener onClickAway={e => handleClickHideEmojiOption(optionIndex)}>
                                            <Picker
                                                perLine={16}
                                                showPreview={false}
                                                showSkinTones={false}
                                                onSelect={e => changeOptionField('text', optionIndex, option.text + e.native)}
                                            />
                                        </ClickAwayListener>
                                    </div>}
                                </div>
                            </div>

                            <span className={classes.listItemActionLabel}>Ação para essa opção:</span>
                            <Select value={option.actionType} disabled={option.actionType == 'list'} onChange={(event) => changeOptionField('actionType', optionIndex, event.target.value)}>
                                <MenuItem value="">[Selecione uma ação]</MenuItem>
                                <MenuItem value="list">Novo Menu</MenuItem>
                                <MenuItem value="queue">Atribui Para um Setor</MenuItem>
                                <MenuItem value="user">Atribui para um atendente</MenuItem>
                                <MenuItem value="end">Finaliza o atendimento</MenuItem>
                            </Select>
                            <div className={option.actionType == 'list' && option.openBotListId ? classes.actionsList : classes.actions}>
                                {option.actionType == 'list' && option.openBotListId && 
                                    <div className={classes.insideList}>
                                        <BotList 
                                            listId={option.openBotListId}
                                            queues={queues}
                                            users={users}
                                            triggerRemove={() => removeChildList(optionIndex)}
                                            />
                                    </div>
                                }
                                {option.actionType == 'queue' && 
                                    <Select value={option.queueId} className={classes.lineSpacing} onChange={(event) => changeOptionField('queueId', optionIndex, event.target.value)}>
                                        <MenuItem value="">[Selecione um setor]</MenuItem>
                                        {queues.map((queue) => (
                                            <MenuItem key={`queueItem_${queue.id}`} value={queue.id}>{queue.name}</MenuItem>
                                        ))}
                                    </Select>
                                }
                                {option.actionType == 'user' && 
                                    <Select value={option.userId} className={classes.lineSpacing} onChange={(event) => changeOptionField('userId', optionIndex, event.target.value)}>
                                        {users.map(user => (
                                            <MenuItem key={`userItem_${user.id}`} value={user.id}>{user.name}</MenuItem>
                                        ))}
                                    </Select>
                                }
                            </div>
                            <div className={classes.afterMessage}>
                                {(option.actionType == 'queue' || option.actionType == 'user') && <div>
                                    <TextField 
                                        label="Mensagem após selecionar essa opção" 
                                        InputLabelProps={{ shrink: true }} className={classes.textFieldStyleMessage} 
                                        value={option.message} variant="outlined"
                                        multiline={true}
                                        rows={2}
                                        onChange={(event) => changeOptionField('message', optionIndex, event.target.value)} />
                                    <div className={classes.emojiWrapper}>
                                        {!option.mediaUrl ? 
                                        <div className={classes.emojiButton} onClick={e => {showOptionFilePicker(optionIndex)}}>
                                            <input
                                                multiple
                                                type="file"
                                                ref={(element) => listOptionFileRef.current[optionIndex] = element}
                                                disabled={loading}
                                                className={classes.uploadInput}
                                                onChange={(e) => {uploadOption(option, optionIndex, Array.from(e.target.files))}}
                                                />
                                            <AttachFileIcon />
                                        </div>
                                        : <div className={classes.emojiButton}>
                                            <FileIconImage fileUrl={option.absoluteMediaUrl} width={25} />
                                            <CloseIcon onClick={() => removeOptionUpload(option, optionIndex)}/>
                                        </div>}
                                        <div className={classes.emojiButton} onClick={e => handleClickShowEmojiOptionText(optionIndex)}>
                                            <MoodIcon />
                                        </div>
                                        {showEmojiTextOptionText[optionIndex] && <div className={classes.emojiBox}>
                                            <ClickAwayListener onClickAway={e => handleClickHideEmojiOptionText(optionIndex)}>
                                                <Picker
                                                    perLine={16}
                                                    showPreview={false}
                                                    showSkinTones={false}
                                                    onSelect={e => changeOptionField('message', optionIndex, option.message + e.native)}
                                                />
                                            </ClickAwayListener>
                                        </div>}
                                    </div>
                                </div>}
                            </div>
                        </li>
                    ))}
                </ul>
                <Button onClick={() => addOption(mainList)}><AddOutlined /> Adicionar Opção</Button>
            </div>
        </div>
    );
};

export default BotList;