import { Badge, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import moment from "moment";
import UserStatusIcon from "../User/statusIcon";

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        flexFlow: "row",
        borderRadius: "5px",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        padding: 10,
        cursor: "pointer",
        color: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: "#F3F3F3",
            color: "#333"
        }
    },
    avatar: {
        width: "52px",
        textAlign: "center",
        marginRight: 10,
        '& img': {
            borderRadius: '50%',
            width: "48px",
            height: "48px",
        },
        position: "relative"
    },
    content: {
        flex: 1,
        maxWidth: 350
    },
    name: {
        color: "inherit"
    },
    lastMessage: {
        display: "block",
        color: "#999",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    date: {
        display: "block",
        float: "right",
        fontSize: "0.8em"
    },
    statusIcon: {
        position: "absolute",
        bottom: 0,
        left: 0
    }
}));

const RoomListItem = ({ room, handleRoomClick, unreadMessages, to }) => {
    const classes = useStyles();
    const [recipient, setRecipient] = useState(null);
    const [roomMe, setRoomMe] = useState({});

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (to) {
            setRecipient(to);
        } else if (room.userId == user.id) {
            setRecipient(room.participant);
        } else {
            setRecipient(room.user);
        }
        if (room && room.participants) {
			const me = room.participants.find(p => p.userId === user.id);
			if (me) setRoomMe(me);
		}
    }, [room, user, to]);

    return (
        <div className={classes.item} onClick={() => {handleRoomClick(room ? room : to)}}>
            <div className={classes.avatar}>
                {recipient && <img src={`https://ui-avatars.com/api/?name=${recipient.name}`} />}
                <UserStatusIcon user={room ? room.userId == user.id ? room.participant : room.user : to} className={classes.statusIcon} />
            </div>
            <div className={classes.content}>
                <span className={classes.name}>{recipient && recipient.name}</span>
                {room && <span className={classes.lastMessage}>{room.lastMessage}</span>}
                {room && <span className={classes.date}>{moment(room.updatedAt).fromNow()}</span>}
            </div>
            <Badge badgeContent={unreadMessages} color="secondary"></Badge>
        </div>
    );
}

export default RoomListItem;