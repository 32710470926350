import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
	makeStyles,
	Drawer,
	AppBar,
	Toolbar,
	List,
	Typography,
	Divider,
	MenuItem,
	IconButton,
	Menu,
	useTheme
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { LanguageSelector, i18n } from "../translate/i18n";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Hidden from '@material-ui/core/Hidden';
import Chat from "../components/Chat/main";
import ColorModeContext from "../layout/themeContext";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { SettingsContext } from "../context/Settings/SettingsContext";
import NotificationMenuButton from "../components/Notifications/menuButton";
import getSocket from "../helpers/socket";
import { ListNotes } from "../components/ListNotes";
const socket = getSocket();

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100vh",
		[theme.breakpoints.down("sm")]: {
			display: "block"
		},
		backgroundColor: theme.palette.fancyBackground,
		'& .MuiButton-outlinedPrimary': {
			color: theme.mode === 'light' ? theme.palette.primary : '#FFF',
			border: theme.mode === `light` ? `1px solid ${theme.palette.primary}` : `1px solid rgba(255, 255, 255, 0.5)`,
		},
		'& .MuiTab-textColorPrimary.Mui-selected': {
			color: theme.mode === 'light' ? theme.palette.primary : '#FFF',
		}
	},
	avatar: {
		width: "100%",
	},
	logo: {
		width: "80%",
		height: "auto",
		[theme.breakpoints.down("sm")]: {
			width: "auto",
			height: "100%"
		}
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
		color: theme.palette.dark.main,
		boxShadow: '0px 1px 10px 0px rgba(0,0,0,0.05)'
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		minHeight: "48px",
		boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.1)",
		[theme.breakpoints.down("sm")]: {
			height: "48px",
			justifyContent: "space-between",

		}
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		boxShadow: 'none'
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		borderRight: '1px solid #ddddd',
		boxShadow: 'inset -5px 0px 5px 0px rgba(0,0,0,0.05)',
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		...theme.scrollbarStylesSoft
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	appBarSpacer: {
		minHeight: "48px",
	},
	content: {
		flex: 1,
		overflow: "auto",
		paddingTop: theme.spacing(6),
		[theme.breakpoints.down("sm")]: {
			height: "calc(100vh - 50px)"
		}
	},
	container: {
		//paddingTop: theme.spacing(4),
		//paddingBottom: theme.spacing(4),
	},
	paper: {
		//padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column"
	},
	mainListItemsContainer: {
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	},
	mainListItemsContainerClose: {

	}
}));

const LoggedInLayout = ({ children }) => {
	const classes = useStyles();
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const { handleLogout, loading } = useContext(AuthContext);
	const [drawerOpen, setDrawerOpen] = useLocalStorage("drawerOpen", true);
	const { user } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);
	const { getSettingValue } = useContext(SettingsContext);
	const [colorDefault, setColorDefault] = useState(null);
	const theme = useTheme();
	const { colorMode } = useContext(ColorModeContext);

	useEffect(() => {
		const notificationUserEvent = data => {
			if (data.action === "new") {
				setNotifications(notifications);
			}
		}

		socket.on(`notification_${user.id}`, notificationUserEvent);

		return () => {
			socket.off(`notification_${user.id}`, notificationUserEvent);
		};
	}, []);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
		setMenuOpen(true);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setMenuOpen(false);
	};

	const handleOpenUserModal = () => {
		setUserModalOpen(true);
		handleCloseMenu();
	};

	const handleClickLogout = () => {
		handleCloseMenu();
		handleLogout();
	};

	const handleMenuItemClick = () => {
		const { innerWidth: width, innerHeight: height } = window;
		if (width <= 600) {
			setDrawerOpen(false);
		}
	};

	const toggleColorMode = () => {
		colorMode.toggleColorMode();
	}

	if (loading) {
		return <BackdropLoading />;
	}

	return (
		<div className={classes.root}>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(
						classes.drawerPaper,
						!drawerOpen && classes.drawerPaperClose
					),
				}}
				open={drawerOpen}
			>
				<div className={classes.toolbarIcon}>
					{drawerOpen && <img className={classes.logo} src="/logo-black.png" alt="image" />}
					<IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
						<Hidden only={['sm', 'xs']}>
							{drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</Hidden>
						<Hidden smUp>
							{drawerOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
						</Hidden>
					</IconButton>
				</div>
				<List onClick={() => handleMenuItemClick()} className={drawerOpen ? classes.mainListItemsContainerClose : classes.mainListItemsContainer}>
					<MainListItems collapsed={!drawerOpen} />
				</List>
				<Divider />
			</Drawer>
			<UserModal
				open={userModalOpen}
				onClose={() => setUserModalOpen(false)}
				userId={user?.id}
			/>
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
				color={"inherit"}
			>
				<Toolbar variant="dense" className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => setDrawerOpen(!drawerOpen)}
						className={clsx(
							classes.menuButton,
							drawerOpen && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						className={classes.title}
					>
					</Typography>
					<div>

					</div>

					<LanguageSelector />

					<Chat />
					<NotificationMenuButton />
					<IconButton edge="start" onClick={toggleColorMode} color="inherit">
						{theme.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
					</IconButton>


					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={menuOpen}
							onClose={handleCloseMenu}
						>
							<MenuItem onClick={handleOpenUserModal}>
								{i18n.t("mainDrawer.appBar.user.profile")}
							</MenuItem>
							<MenuItem onClick={handleClickLogout}>
								{i18n.t("mainDrawer.appBar.user.logout")}
							</MenuItem>
						</Menu>
					</div>





				</Toolbar>
			</AppBar>
			<main className={classes.content}>
				{children ? children : null}
			</main>
		</div>
	);
};

export default LoggedInLayout;
