import React, { useState, useEffect, useReducer } from "react";
//import { AuthContext } from "../../context/Auth/AuthContext";
import Title from "../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import BlockIcon from "@material-ui/icons/Block";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MessageInputForward from "../MessageInputForward";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from "@material-ui/core";
import getSocket from "../../helpers/socket";

import ConnectionIcon from "../../components/ConnectionIcon";
//import SelectUsers from "../../components/SelectUsers";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    modalDialog: {
        width: 500
    },
    TitlePadding: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        paddingTop: 10
    },
    selectUsersDrop: {
        width: 150,
        marginLeft: 10,
        marginRight: 10
    },
    mainTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mainPaperOverflow: {
        height: '60vh',
        overflowY: 'scroll'
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    block_title: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'space-between'
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));


const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id);
            if (contactIndex && contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }
    if (action.type === "RESET") {
        return [];
    }
};


const ForwardMessageModal = ({ open, onClose, message }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    //const [selectedContactId, setSelectedContactId] = useState(null);
    //const [contactModalOpen, setContactModalOpen] = useState(false);
    //const [contactSyncSingleModalOpen, setContactSyncSingleModalOpen] = useState(false);
    //const [addTagModalOpen, setAddTagModalOpen] = useState(false);
    //const [deletingContact, setDeletingContact] = useState(null);

    //const [newTicketContact, setNewTicketContact] = useState(null);
    //const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [tagIds] = useState([]);    
    const [userIds] = useState([]);

    const [hasMore, setHasMore] = useState(false);
    const [checked, setChecked] = useState([]);
    const [onlyGroups, setOnlyGroups] = useState(false);
    
    const [totalContacts, setTotalContacts] = useState("");

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, tagIds, userIds, onlyGroups]);

  

 

    const handleToggle = (event) => {
        const currentIndex = checked.indexOf(event.target.value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const toggleAll = (event) => {
        if (event.target.checked) {
            checkAll();
        } else {
            uncheckAll();
        }
    }
    const checkAll = () => {
        setChecked(contacts.map((element) => { return "" + element.id }));
    }

    const uncheckAll = () => {
        setChecked([]);
    }

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/", {
                        params: { searchParam, pageNumber, onlyGroups: onlyGroups ? "1" : "0", tagIds, userIds },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    setHasMore(data.hasMore);
                    setTotalContacts(`(${data.count})`);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, onlyGroups, tagIds, userIds]);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOnlyGroupChange = e => {
        setOnlyGroups(e.target.checked);
    }


    //const { user } = useContext(AuthContext);

    const initialState = {
        name: "",
        number: "",
        email: "",
        blocked: false
    };


    const handleClose = () => {
        setSearchParam('')
        setChecked([]);
        onClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" scroll="paper">
                <div id="form-dialog-title" className={classes.TitlePadding}>
                    <div className={classes.mainTitle}>
                        <Title>{i18n.t("contacts.title")} {totalContacts}</Title>
                       {/* <SelectUsers width="200px" className={classes.selectUsersDrop} multiple onChange={(value) => setUserIds(value ? value.map(userItem => userItem.id) : [])} outlined></SelectUsers> */}
                    </div>
                    <div className={classes.mainTitle}>
                        <TextField
                            placeholder={i18n.t("contacts.searchPlaceholder")}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <div>&nbsp;&nbsp;&nbsp;</div>

                        <FormControlLabel label="Mostrar somente grupos" control={
                            <Checkbox
                                checked={onlyGroups}
                                onChange={handleOnlyGroupChange}
                            />
                        } />
                    </div>


                </div>

                <Paper
                    className={classes.mainPaperOverflow}
                    variant="outlined"
                    onScroll={handleScroll}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox onChange={toggleAll} />
                                </TableCell>
                                <TableCell padding="checkbox" />
                                <TableCell></TableCell>
                                <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.whatsapp")}
                                </TableCell>
                                <TableCell>
                                    {i18n.t("contacts.table.user")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("contacts.table.email")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {contacts ? contacts.map(contact => (
                                    <TableRow key={contact.id}>
                                        <TableCell>
                                            <Checkbox value={contact.id} checked={checked.indexOf("" + contact.id) !== -1} onChange={handleToggle} />
                                        </TableCell>
                                        <TableCell>
                                            {contact.blocked && <BlockIcon />}
                                        </TableCell>
                                        <TableCell style={{ paddingRight: 0 }}>
                                            {<Avatar src={contact.profilePicUrl} />}
                                        </TableCell>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell align="center">
                                            {contact.isWhatsapp && <ConnectionIcon connectionType={'whatsapp'} />}
                                            {contact.isFacebook && <ConnectionIcon connectionType={'facebook'} />}
                                            {contact.isInstagram && <ConnectionIcon connectionType={'instagram'} />}
                                            {contact.numberFormatted}
                                        </TableCell>
                                        <TableCell>
                                            {contact.user ? contact.user.name : ''} {contact.queue ? `(${contact.queue.name})` : ''}
                                        </TableCell>
                                        <TableCell align="center">{contact.email}</TableCell>

                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell style={{ paddingRight: 0 }}>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="center">
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell align="center"></TableCell>

                                    </TableRow>
                                )}
                                {loading && <TableRowSkeleton columns={6} />}
                            </>
                        </TableBody>
                    </Table>
                </Paper>

                <Paper
                    className={classes.mainPaperTextingMessage}
                    variant="outlined"
                    onScroll={handleScroll}
                >
                    <MessageInputForward ticketStatus={'open'} message={message} onClose={handleClose} recipients={checked} />
                </Paper>

            </Dialog>
        </div>
    );
};

export default ForwardMessageModal;