const messages = {
    pt: {
        translations: {
            ticket: "Atendimento",
            exporting: "Exportando...",
            search: "Pesquisar",
            searchPlaceholder: "Pesquisar...",
            dateFrom: "De",
            dateTo: "Até",
            clean: "Limpar",
            whatsapp: {
                status: {
                    connected: 'Conectado',
                    disconnected: 'Desconectado'
                }
            },
            signup: {
                title: "Cadastre-se",
                toasts: {
                    success: "Usuário criado com sucesso! Faça seu login!!!.",
                    fail: "Erro ao criar usuário. Verifique os dados informados.",
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha",
                },
                buttons: {
                    submit: "Cadastrar",
                    login: "Já tem uma conta? Entre!",
                },
            },
            login: {
                title: "Login",
                form: {
                    email: "Email",
                    password: "Senha",
                },
                buttons: {
                    submit: "Entrar",
                    register: "Não tem um conta? Cadastre-se!",
                },
            },
            auth: {
                toasts: {
                    success: "Login efetuado com sucesso!",
                },
            },
            dashboard: {
                charts: {
                    perDay: {
                        title: "Atendimentos hoje: ",
                    },
                },
            },
            connections: {
                title: "Conexões",
                toasts: {
                    deleted: "Conexão com o WhatsApp excluída com sucesso!",
                    restarted: "O servidor foi reiniciado, aguarde 1 minuto e atualize a página.",
                },
                confirmationModal: {
                    deleteTitle: "Deletar",
                    deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
                    disconnectTitle: "Desconectar",
                    disconnectMessage:
                        "Tem certeza? Você precisará ler o QR Code novamente.",
                },
                buttons: {
                    add: "Adicionar Conexão",
                    disconnect: "desconectar",
                    tryAgain: "Tentar novamente",
                    qrcode: "QR CODE",
                    newQr: "Novo QR CODE",
                    connecting: "Conectando",
                    reset: "Resetar conexão",
                    restartServer: "Reiniciar Servidor",
                    authenticate: "Autenticar"
                },
                toolTips: {
                    disconnected: {
                        title: "Falha ao iniciar sessão do WhatsApp",
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
                    },
                    qrcode: {
                        title: "Esperando leitura do QR Code",
                        content:
                            "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
                    },
                    connected: {
                        title: "Conexão estabelecida!",
                    },
                    timeout: {
                        title: "A conexão com o celular foi perdida",
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
                    },
                },
                table: {
                    name: "Nome",
                    status: "Status",
                    lastUpdate: "Última atualização",
                    default: "Padrão",
                    actions: "Ações",
                    session: "Sessão",
                    number: "Número",
                    platform: "Dispositivo",
                    type: "Tipo"
                },
            },
            whatsappModal: {
                title: {
                    add: "Adicionar Conexão",
                    edit: "Editar Conexão",
                },
                form: {
                    name: "Nome",
                    default: "Padrão",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "WhatsApp salvo com sucesso.",
            },
            qrCode: {
                message: "Leia o QrCode para iniciar a sessão",
            },
            contacts: {
                title: "Contatos",
                toasts: {
                    deleted: "Contato excluído com sucesso!",
                    synced: "Contato sincronizado com sucesso!",
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    importTitlte: "Importar contatos",
                    deleteMessage:
                        "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
                    importMessage: "Deseja importas todos os contatos do telefone?",
                },
                buttons: {
                    import: "Importar",
                    add: "Adicionar",
                    addTags: "Adicionar Tags",
                    deleteContacts: "Excluir",
                    export: "Exportar"
                },
                table: {
                    name: "Nome",
                    whatsapp: "ID",
                    email: "Email",
                    actions: "Ações",
                    tags: "Tags",
                    user: "Atendente"
                },
            },
            shortcut_messages: {
                title: "Mensagens Pré-definidas",
                toasts: {
                    deleted: "Mensagem pré-definida excluída com sucesso!",
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    deleteMessage:
                        "Tem certeza que deseja deletar esta mensagem pré-definida?",
                },
                buttons: {
                    add: "Adicionar",
                    import: "Importar",
                    export: "Exportar"
                },
                table: {
                    name: "Nome",
                    queue: "Setores",
                    content: "Conteúdo",
                    actions: "Ações",
                    file: "Arquivo"
                },
                showMore: "Mostrar mais",
                showLess: "Mostrar menos"
            },
            scheduled_messages: {
                title: "Mensagens Programadas",
                toasts: {
                    deleted: "Mensagem programada excluída com sucesso!",
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    deleteMessage:
                        "Tem certeza que deseja deletar esta mensagem programada?",
                },
                buttons: {
                    add: "Adicionar Mensagem programada",
                },
                table: {
                    date: "Agendada para",
                    content: "Conteúdo",
                    sent: "Enviada em",
                    actions: "Ações",
                    to: "Para",
                    file: "Arquivo"
                },
            },
            tags: {
                title: "Tags",
                toasts: {
                    deleted: "Tag excluída com sucesso!",
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    deleteMessage:
                        "Tem certeza que deseja deletar esta tag?",
                },
                buttons: {
                    add: "Adicionar Tag",
                },
                table: {
                    name: "Nome",
                    content: "Conteúdo",
                    actions: "Ações",
                    color: "Cor",
                    contacts: "Número de contatos"
                },
            },
            addNoteModal: {
                title: {
                    add: "Adicionar nota",
                    edit: "Editar nota",
                },
                form: {
                    mainInfo: "Dados da nota",
                    syncInfo: "Você deseja sicronizar o contato ?",
                    extraInfo: "Informações adicionais",
                    title: "Titulo",
                    content: "Mensagem",
                    number: "Número do Whatsapp",
                    email: "Email",
                    extraName: "Nome do campo",
                    extraValue: "Valor",
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    okSync: "Sincronizar",
                    cancel: "Cancelar",
                },
                success: "Nota salva com sucesso.",
            },
            contactModal: {
                title: {
                    add: "Adicionar contato",
                    edit: "Editar contato",
                },
                form: {
                    mainInfo: "Dados do contato",
                    syncInfo: "Você deseja sicronizar o contato ?",
                    extraInfo: "Informações adicionais",
                    name: "Nome",
                    number: "Número do Whatsapp",
                    email: "Email",
                    extraName: "Nome do campo",
                    extraValue: "Valor",
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    okSync: "Sincronizar",
                    cancel: "Cancelar",
                },
                success: "Contato salvo com sucesso.",
            },
            tagModal: {
                title: {
                    add: "Adicionar tag",
                    edit: "Editar tag",
                },
                form: {
                    mainInfo: "Dados da tag",
                    name: "Nome",
                    color: "Cor"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Tag salva com sucesso.",
            },
            shortcutMessageModal: {
                title: {
                    add: "Adicionar mensagem pré-definida",
                    edit: "Editar mensagem pré-definida",
                },
                form: {
                    mainInfo: "Dados da mensagem pré-definida",
                    extraInfo: "Informações adicionais",
                    name: "Nome",
                    content: "Conteúdo",
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Mensagem pré-definida salva com sucesso.",
            },
            scheduledMessageModal: {
                title: {
                    add: "Adicionar mensagem programada",
                    edit: "Editar mensagem programada",
                },
                form: {
                    mainInfo: "Dados da mensagem programada",
                    extraInfo: "Informações adicionais",
                    date: "Programar para:",
                    content: "Conteúdo",
                    contactId: "Contato"
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Mensagem programada salva com sucesso.",
            },
            queueModal: {
                title: {
                    add: "Adicionar setor",
                    edit: "Editar setor",
                },
                form: {
                    name: "Nome",
                    color: "Cor",
                    greetingMessage: "Mensagem de saudação",
                    endMessage: "Mensagem de Finalização",
                    feedbackMessage: "Mensagem para o Feedback (desativado caso não seja informado)"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
            },
            userModal: {
                title: {
                    add: "Adicionar usuário",
                    edit: "Editar usuário",
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha",
                    profile: "Perfil",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Usuário salvo com sucesso.",
            },
            webhookModal: {
                title: {
                    add: "Adicionar webhook",
                    edit: "Editar webhook",
                },
                form: {
                    name: "Nome",
                    url: "URL",
                    urlType: "Tipo de requisição",
                    headerString: "Header personalizado (JSON, opcional)",
                    type: "Tipo",
                    sendEveryMinutes: "Enviar requisição a cada X Minutos (algo entre 60 e 1440)"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Webhook salvo com sucesso.",
            },
            integrationIframeModal: {
                title: {
                    add: "Adicionar iframe",
                    edit: "Editar iframe",
                },
                form: {
                    name: "Nome",
                    url: "URL",
                    width: "Largura (em px)"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Iframe salvo com sucesso.",
            },
            chat: {
                noTicketMessage: "Selecione um atendimento para começar a conversar.",
            },
            ticketsManager: {
                buttons: {
                    newTicket: "Novo",
                },
            },
            ticketsQueueSelect: {
                placeholder: "Setores",
            },
            tickets: {
                toasts: {
                    deleted: "O atendimento que você estava foi deletado.",
                },
                notification: {
                    message: "Mensagem de",
                },
                tabs: {
                    open: { title: "Inbox" },
                    closed: { title: "Resolvidos" },
                    search: { title: "Busca" },
                    paused: { title: "Pausados" },
                },
                search: {
                    placeholder: "Buscar atendimentos e mensagens",
                    ticketId: "ID do ticket: ex. 15",
                    connections: "Conexões",
                    users: "Usuários",
                    from: "De",
                    to: "Até",
                    noQueues: "Sem Setor",
                    unanswered: "Não respondidas",
                    unansweredDisabled: "Desativado",
                    unansweredClient: "Todas mensagens não respondidas pelo cliente",
                    unansweredMe: "Todas mensagens não respondidas por mim",
                    unansweredTimeClient: "Não respondidas pelo cliente em um período de tempo",
                    unansweredTimeMe: "Não respondidas por mim em um período de tempo",

                    selectedConnections: " conexõe(s) selecionada(s)",
					selectedUsers: " usuário(s) selecionado(s)",
					selectedTags: " tag(s) selecionada(s)",
					selectedQueues: " setore(s) selecionado(s)",
                },

                buttons: {
                    showAll: "Todos",
                    sortAscending: "Ordenar mais antigos",
                    clear: "Limpar",
					search: "Buscar"
                },
                status: {
                    open: "Em Atendimento",
                    closed: "Resolvidos",
                    bot: "No bot",
                    feedback: "Em Classificação",
                    pending: "Aguardando",
                    group: "Grupos"
                }
            },
            transferTicketModal: {
                title: "Transferir Atendimento",
                fieldLabel: "Digite para buscar usuários",
                fieldLabelWhatsapp: "Digite para buscar conexões",
                fieldLabelQueue: "Digite para buscar setores",
                noOptions: "Nenhum usuário encontrado com esse nome",
                buttons: {
                    ok: "Transferir",
                    cancel: "Cancelar",
                },
            },
            resolveTicketModal: {
                title: "Finalizar Atendimento",
                content: "Deseja finalizar esse atendimento e enviar a pesquisa de satisfação, ou somente finalizar o atendimento?",
                contentAutoFeedback: "Tem certeza que deseja finalizar esse atendimento e enviar a pesquisa de satisfação?",
                contentEndOnly: "Tem certeza que deseja finalizar o atendimento?",
                buttons: {
                    accept: "Somente Finalizar",
                    feedback: "Finalizar e enviar Pesquisa de Satisfação",
                    cancel: "Cancelar",
                    end: "Finalizar",
                    endWithoutMessage: "Finalizar sem nenhuma mensagem"
                }
            },
            ticketsList: {
                pendingHeader: "Aguardando",
                assignedHeader: "Atendendo",
                noTicketsTitle: "Nada aqui!",
                noTicketsMessage:
                    "Nenhum atendimento encontrado com esse status ou termo pesquisado",
                buttons: {
                    accept: "Aceitar",
                },
            },
            newTicketModal: {
                title: "Criar Atendimento",
                fieldLabel: "Digite para pesquisar o contato",
                add: "Adicionar",
                buttons: {
                    ok: "Iniciar",
                    cancel: "Cancelar",
                },
            },
            mainDrawer: {
                listItems: {
                    dashboard: "Dashboard",
                    connections: "Conexões",
                    tickets: "Atendimentos",
                    contacts: "Contatos",
                    queues: "Setores",
                    administration: "Administração",
                    users: "Usuários",
                    settings: "Configurações",
                    shortcut_messages: "Mensagens",
                    scheduled_messages: "Agendamento",
                    tags: "Tags",
                    bots: "Bots"
                },
                appBar: {
                    user: {
                        profile: "Perfil",
                        logout: "Sair",
                    },
                },
            },
            notifications: {
                noTickets: "Nenhuma notificação.",
                table: {
                    title: "Título",
                    message: "Conteúdo",
                    date: "Data"
                },
                buttons: {
                    close: "Fechar"
                },
                title: "Mensagens",
                searchPlaceholder: "Pesquisar mensagens"
            },
            queues: {
                title: "Setores",
                table: {
                    name: "Nome",
                    color: "Cor",
                    greeting: "Mensagem de saudação",
                    actions: "Ações",
                },
                buttons: {
                    add: "Adicionar setor",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos desse setor continuarão existindo, mas não terão mais nenhum setor atribuído.",
                },
            },
            queueSelect: {
                inputLabel: "Setores",
            },
            users: {
                title: "Usuários",
                table: {
                    name: "Nome",
                    email: "Email",
                    profile: "Perfil",
                    actions: "Ações",
                    status: "Status"
                },
                buttons: {
                    add: "Adicionar usuário",
                },
                toasts: {
                    deleted: "Usuário excluído com sucesso.",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Todos os dados do usuário serão perdidos. Os atendimentos abertos deste usuário serão movidos para o setor.",
                },
                total: "Total de usuários: ",
                totalEnabled: "Total de usuários ativos: ",
                totalAllowed: "Total de usuários permitidos: "
            },
            webhooks: {
                title: "Webhooks (Beta)",
                table: {
                    name: "Nome",
                    url: "URL",
                    urlType: "Tipo de requisição",
                    headerString: "Header (JSON, opcional)",
                    active: "Ativo",
                    type: "Tipo",
                    actions: "Ações"
                },
                searchPlaceholder: "Pesquisar",
                buttons: {
                    add: "Adicionar webhook",
                },
                toasts: {
                    deleted: "Webhook excluído com sucesso.",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Tem certeza que deseja excluir? A URL configurada não receberá mais requisições do tipo selecionado.",
                },
            },
            apikeys: {
                title: "Chaves de API",
                table: {
                    name: "Nome",
                    url: "URL",
                    urlType: "Tipo de requisição",
                    headerString: "Header (JSON, opcional)",
                    active: "Ativo",
                    type: "Tipo",
                    actions: "Ações"
                },
                searchPlaceholder: "Pesquisar",
                buttons: {
                    add: "Adicionar webhook",
                },
                toasts: {
                    deleted: "Webhook excluído com sucesso.",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Tem certeza que deseja excluir? A URL configurada não receberá mais requisições do tipo selecionado.",
                },
            },
            iframes: {
                title: "Iframes (Beta)",
                table: {
                    name: "Nome",
                    url: "URL",
                    active: "Ativo",
                    actions: "Ações"
                },
                searchPlaceholder: "Pesquisar",
                buttons: {
                    add: "Adicionar iframe",
                },
                toasts: {
                    deleted: "Iframe excluído com sucesso.",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Tem certeza que deseja excluir?",
                },
            },
            bots: {
                table: {
                    name: 'Nome',
                    createdAt: 'Criado',
                    updatedAt: 'Atualizado',
                    enabled: 'Habilitado',
                    actions: 'Ações'
                }
            },
            settings: {
                success: "Configurações salvas com sucesso.",
                title: "Configurações",
                tabs: {
                    general: "Em geral",
                    appointments: "Atendimentos",
                    internal_chat: "Chat interno",
                    integration: "Integração",
                    actions_by_batch: "Ações por Lote",
                },
                settings: {
                    notificationSound: {
                        name: "Som da notificação geral",
                        options: {
                            enabled: "Habilitado",
                            disabled: "Desabilitado",
                        },
                    },                    
					backgroundChat: {
						name: "Plano de fundo do chat",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
                    chatNotificationSound: {
                        name: "Som da notificação de mensagem",
                        options: {
                            enabled: "Habilitado",
                            disabled: "Desabilitado",
                        },
                    },
                    userCreation: {
                        name: "Criação de usuário",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado",
                        },
                    },
                    groupMedia: {
                        name: "Agrupar mídias (imagens e vídeos)",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado"
                        }
                    },
                    showContacts: {
                        name: "Mostrar seção Contatos para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showTags: {
                        name: "Mostrar seção Tags para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showConnections: {
                        name: "Mostrar seção Conexões para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showResolved: {
                        name: "Mostrar Todos os Atendimentos Resolvidos para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (cada atendente vê apenas os seus atendimentos)"
                        }
                    },
                    showWaiting: {
                        name: "Mostrar Aba Aguardando para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showDeletedMessages: {
                        name: "Mostrar mensagens deletadas",
                        options: {
                            enabled: "Ativado",
                            disabled: "Disabled (only administrator)"
                        }
                    },
                    showDashboard: {
                        name: "Mostrar seção Dashboard para todos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showAllTickets: {
                        name: "Mostrar a opção de Mostrar Todos os Tickets para todos os usuários",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    showDisableSignOption: {
                        name: "Mostrar a opção do atendente desabilitar a assinatura",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado (somente administrador)"
                        }
                    },
                    userCanFinalizeTicketWithoutFeedback: {
                        name: "Os atendentes podem finalizar o Atendimento sem enviar pesquisa de satisfação, se ativada.",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado"
                        }
                    },
                    notificationSound: {
                        name: "Som de notificação"
                    },
                    chatNotificationSound: {
                        name: "Som de notificação"
                    },
                    language: {
                        name: "Idioma",
                        options: {
                            english: "Inglês",
                            spanish: "Espanhol",
                            portuguese: "Português"
                        }
                    }
                },
            },
            messagesList: {
                header: {
                    assignedTo: "Atribuído à:",
                    buttons: {
                        return: "Retornar",
                        resolve: "Finalizar",
                        reopen: "Reabrir",
                        accept: "Aceitar",
                        pause: "Pausar",
                        unpause: "Retomar",
                    },
                },
            },
            messages: {
                "[CONTACT]": "👤 Contato",
                "[LOCALIZATION]": "📌 Localização",
            },
            messagesInput: {
                placeholderOpen: "Digite uma mensagem",
                placeholderClosed:
                    "Reabra ou aceite esse atendimento para enviar uma mensagem.",
                signMessage: "Assinar",
            },
            contactDrawer: {
                header: "Dados do contato",
                buttons: {
                    edit: "Editar contato",
                },
                extraInfo: "Outras informações",
            },
            ticketOptionsMenu: {
                delete: "Deletar",
                transfer: "Transferir",
                confirmationModal: {
                    title: "Deletar o atendimento do contato",
                    message:
                        "Atenção! Todas as mensagens relacionadas ao atendimento serão perdidas.",
                },
                buttons: {
                    delete: "Excluir",
                    cancel: "Cancelar",
                },
            },
            confirmationModal: {
                buttons: {
                    confirm: "Ok",
                    cancel: "Cancelar",
                },
            },
            messageOptionsMenu: {
                delete: "Deletar",
                forward: "Encaminhar",
                reply: "Responder",
                create_note: "Criar nota",
                confirmationModal: {
                    title: "Apagar mensagem?",
                    message: "Esta ação não pode ser revertida.",
                },
                download_all: "Baixar Todos",
                download_zip: "Baixar arquivo zip"
            },
            instagram: {
                mentionedStory: "% mencionou você em seu story",
                mentionedStoryBy: "Você mencionou %s em seu story"
            },
            scheduledMessage: {
                deliveryStatus: {
                    done: 'Completo',
                    error: 'Erro',
                    canceled: 'Cancelado',
                    pending: 'Pendente'
                }
            },
            backendErrors: {
                ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
                ERR_NO_DEF_WAPP_FOUND:
                    "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
                ERR_WAPP_NOT_INITIALIZED:
                    "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
                ERR_WAPP_CHECK_CONTACT:
                    "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
                ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
                ERR_WAPP_DOWNLOAD_MEDIA:
                    "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
                ERR_INVALID_CREDENTIALS:
                    "Erro de autenticação. Por favor, tente novamente.",
                ERR_USER_DISABLED: "Este usuário foi desativado.",
                ERR_SENDING_WAPP_MSG:
                    "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
                ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
                ERR_OTHER_OPEN_TICKET: "Já existe um atendimento aberto para este contato. O atendimento está com {{ errorData.userName }} no setor {{ errorData.queueName }}",
                ERR_OPEN_MORE_TICKETS_THAN_LIMIT: "Você não pode aceitar mais atendimentos no momento",
                ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
                ERR_USER_CREATION_DISABLED:
                    "A criação do usuário foi desabilitada pelo administrador.",
                ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
                ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
                ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
                ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
                ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
                ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
                ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
                ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
                ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
                ERR_FETCH_WAPP_MSG:
                    "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
                ERR_QUEUE_COLOR_ALREADY_EXISTS:
                    "Esta cor já está em uso, escolha outra.",
                ERR_WAPP_GREETING_REQUIRED:
                    "A mensagem de saudação é obrigatório quando há mais de um setor.",
                ERR_FILE_TOO_BIG:
                    "O Whatsapp suporta apenas arquivos de até 16MB.",
                ERR_IFRAME_INVALID_URL: "URL inválida",
                ERR_IFRAME_INVALID_WIDTH: "Largura do iframe informada é inválida",
                ERR_IFRAME_NAME_ALREADY_EXISTS: "Já existe um outro iframe com o mesmo nome",
                ERR_IFRAME_INVALID_NAME: "Nome do iframe inválido. Informe um nome com mais de 2 caracteres"
            },
        },
    },
};

export { messages };
