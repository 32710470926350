import React, { useEffect, useRef, useState } from "react"
import 'react-data-grid/lib/styles.css';
import { makeStyles } from "@material-ui/core/styles";
import { read, utils } from "xlsx";
import { Button, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import api from "../../services/api";
import SelectTags from "../SelectTags";
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import SelectUsers from "../SelectUsers";
import AsyncSelect from "../AsyncSelect";

function WorksheetToDatagrid(ws) {
  /* create an array of arrays */
  const rows = utils.sheet_to_json(ws, { header: 1 });

  /* create column array */
  const range = utils.decode_range(ws["!ref"]||"A1");
  const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
    key: String(i), // RDG will access row["0"], row["1"], etc
    name: utils.encode_col(i), // the column labels will be A, B, etc
    //editor: textEditor // enable cell editing
  }));

  return { rows, columns }; // these can be fed to setRows / setColumns
}

const useStyles = makeStyles(theme => ({
    xlsTable: {
        width: '100%'
    },
    tableContainer: {
        height: '400px'
    },
    actions: {
        padding: theme.spacing(2),
        border: '1px solid #CCC',
        boxShadow: "1px 1px 5px #CCC",
        marginTop: theme.spacing(2)
    },
    importOptions: {
        padding: theme.spacing(2),
        border: '1px solid #CCC',
        boxShadow: "1px 1px 5px #CCC",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    error: {
        color: 'red',
        marginTop: theme.spacing(1)
    },
    buttonImport: {
        marginRight: theme.spacing(1)
    },
    select: {
        minWidth: 200
    }
}));

const ContactImport = ({}) => {
    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    const fileRef = useRef(null);
    const classes = useStyles();
    const [contactFieldsAvailable, setContactFieldsAvailable] = useState([]);
    const [columnValue, setColumnValue] = useState({});
    const [openingFile, setOpeningFile] = useState(false);
    const [selection, setSelection] = useState({});
    const [updateExisting, setUpdateExisting] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [invalidFile, setInvalidFile] = useState(false);
    const [error, setError] = useState(null);
    const [countCreated, setCountCreated] = useState(0);
    const [countUpdated, setCountUpdated] = useState(0);
    const [countIgnored, setCountIgnored] = useState(0);
    const [countTickets, setCountTickets] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [imported, setImported] = useState(false);
    const [fileObjects, setFileObjects] = useState([]);

    const [createTicketWithUser, setCreateTicketWithUser] = useState(false);
    const [createTicketWithUserUpdated, setCreateTicketWithUserUpdated] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [attribUser, setAttribUser] = useState(false);
    const [attribUserId, setAttribUserId] = useState(null);
    const [attribQueueId, setAttribQueueId] = useState(null);

    const contactFields = [
        {id: 'name', label: 'Nome', required: false},
        {id: 'number', label: 'Número', required: true},
        {id: 'email', label: 'E-mail', required: false}
    ];

    useEffect(() => {
        setContactFieldsAvailable(contactFields);
    }, []);

    const processImport = async() => {
        if (!selection.number) {
            setError('Não foi selecionado o campo de número do contato');
            return;
        }
        if (createTicketWithUser && (!selectedUsers || !selectedUsers.id)) {
            setError("Nenhum usuário foi selecionado");
            return;
        }
        setUploading(true);
        const { data } = await api.post(`/contacts/import/rows`, {
            selection,
            rows,
            updateExisting,
            tags: selectedTags,
            userId: selectedUsers && selectedUsers.id,
            createTicketWithUserUpdated,
            attribUserId: attribUserId ? attribUserId : undefined,
            attribQueueId: attribQueueId ? attribQueueId : undefined
        });

        setCountCreated(data.countCreated);
        setCountUpdated(data.countUpdated);
        setCountIgnored(data.countIgnored);
        setCountTickets(data.countTickets);
        setImported(true);
        setUploading(false);
    }

    const onChangeFile = (files) => {
        if (files && files.length == 0) return;
        setOpeningFile(true);
        setInvalidFile(false);
        setImported(false);
        setUploading(false);
        var file = files[0];
        var reader = new FileReader();
        reader.onload = function(e) {
            try {
                var data = e.target.result;
                const wb = read(data);
                const ws = wb.Sheets[wb.SheetNames[0]];
                const { rows, columns } = WorksheetToDatagrid(ws);
                setRows(rows);
                setColumns(columns);
                setOpeningFile(false);
            } catch (e) {
                console.error(e);
                setInvalidFile(true);
                setOpeningFile(false);
                setFileObjects([]);
            }
        }
        reader.readAsArrayBuffer(file);
    }

    const handleSelectChange = (event) => {
        var value = event.target.value;
        setSelection(selection => ({...selection, ...{[value]: event.target.name}}));
        setColumnValue(columnValue => ({...columnValue, ...{[event.target.name]: event.target.value}}));
    }

    const renderSelectbox = (column) => {
        return <Select value={columnValue[column.key]} name={column.key} onChange={handleSelectChange}>
            {contactFieldsAvailable.map(contactField => <MenuItem value={contactField.id}>{contactField.label}</MenuItem>)}
        </Select>
    }

    const renderXls = () => {
        return <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => <TableCell>{column.name}</TableCell>)}
                        </TableRow>
                        <TableRow>
                            {columns.map(column => <TableCell>{renderSelectbox(column)}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <TableRow>{row.map(column => <TableCell>{column}</TableCell>)}</TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
    }

    const renderContent = () => {
        return <div>
            <div className={classes.importOptions}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={updateExisting}
                            onChange={(event) => setUpdateExisting(event.target.checked)}
                            color="primary"
                        />
                        }
                        label="Atualizar dados de contatos existentes"
                    />
                    <FormControlLabel labelPlacement="start" control={<SelectTags outlined onChange={(tags) => setSelectedTags(tags)} />} label="Adicionar as tags aos contatos importados" />
                </FormGroup>
            </div>
            <div className={classes.importOptions}>
                <FormGroup row>
                    <FormControlLabel 
                        control={<Switch checked={createTicketWithUser} 
                            onChange={(event) => setCreateTicketWithUser(event.target.checked)}
                            color="primary" />
                        }
                        label="Criar atendimentos para os contatos importados para o usuário selecionado"
                    />
                    {createTicketWithUser && <FormControlLabel 
                        control={<Switch checked={createTicketWithUserUpdated} 
                            onChange={(event) => setCreateTicketWithUserUpdated(event.target.checked)}
                            color="primary" />
                        }
                    label="Criar para os atualizados também" />}
                    {createTicketWithUser && <FormControlLabel className={classes.select}
                        labelPlacement="start" 
                        control={<SelectUsers outlined onChange={users => setSelectedUsers(users)} label="Selecionar usuário" />} />}
                </FormGroup>
            </div>
            <div className={classes.importOptions}>
                <FormGroup row>
                    <FormControlLabel control={<Switch checked={attribUser} onChange={(event) => setAttribUser(event.target.checked)} color="primary" />} label="Atribuir atendente para os contatos" />
                    {attribUser && <FormControlLabel className={classes.select} labelPlacement="start" control={<SelectUsers outlined onChange={user => setAttribUserId(user ? user.id : null)} />} />}
                    {attribUser && <FormControlLabel className={classes.select} labelPlacement="start" control={<AsyncSelect width="100%" label="Setor (opcional)" url="/queue" dictKey={null} outlined onChange={(event, queue) => setAttribQueueId(queue ? queue.id : null)} />} />}
                </FormGroup>
            </div>
            {renderXls()}
            <div className={classes.actions}>
                {uploading && <div>Importando... Aguarde</div>}
                <Button variant="contained" color="primary" disabled={uploading} className={classes.buttonImport} onClick={() => processImport()}>Importar dados</Button> 
                <Button variant="contained" color="secondary" disabled={uploading} onClick={() => {setRows(null);setColumns(null)}}>Cancelar</Button>
                {error && <div className={classes.error}>{error}</div>}
            </div>
        </div>
    }

    const renderInput = () => {
        return <DropzoneAreaBase dropzoneText="Clique aqui para selecionar ou arraste um arquivo XLSX, XLS ou CSV para adicionar contatos" onDrop={onChangeFile} filesLimit={1} fileObjects={fileObjects} />
        //return <input type="file" ref={fileRef} onChange={onChangeFile} />;
    }

    return (
        <div>
            {imported && <div>
                <ul>
                    <li>{countCreated} contatos criados</li>
                    <li>{countUpdated} contatos atualizados</li>
                    <li>{countIgnored} contatos ignorados (número inválido ou não marcados para atualizar)</li>
                    <li>{countTickets} atendimentos criados / atualizados</li>
                </ul>
            </div>}
            {openingFile && <div>Processando arquivo...</div>}
            {invalidFile && <div>Arquivo inválido!</div>}
            {!imported && rows && columns ? renderContent() : renderInput()}
        </div>
    );
}

export default ContactImport;