import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import getSocket from "../../helpers/socket";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import { MutedItemsContext } from '../../context/Tickets/MutedItems';
import useCountPaused from '../../hooks/countMessages';
const useStyles = makeStyles(theme => ({
    tabContainer: {
        overflowY: "auto",
        maxHeight: 350,
        ...theme.scrollbarStyles,
    },
    popoverPaper: {
        width: "100%",
        maxWidth: 350,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            maxWidth: 270,
        },
    },
    btnNotification: {
        padding: 0,
    },
    noShadow: {
        boxShadow: "none !important",
    },
}));

const NotificationsPopOver = () => {
    const classes = useStyles();
    
    const { countPaused, setCountPaused } = useCountPaused();
    const { mutedItems, setMutedItems } = useContext(MutedItemsContext);
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const ticketIdUrl = +history.location.pathname.split("/")[2];
    const ticketIdRef = useRef(ticketIdUrl);
    const anchorEl = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const { info, isActive, getSettingValue } = useContext(SettingsContext);
    const { settings } = useContext(UserSettingsContext);
    const notificationSound = settings && settings.notificationSound;
    const [, setDesktopNotifications] = useState([]);

    const userQueueIds = user && user.queues ? user.queues.map(q => q.id) : [];

    const { tickets } = useTickets({ withUnreadMessages: "true", queueIds: JSON.stringify(userQueueIds) });
    const [play] = useSound(notificationSound ? `/notificationSounds/${notificationSound}.mp3` : alertSound);
    const soundAlertRef = useRef();
    const [muteds, setAllMuteds] = useState([]);

    const historyRef = useRef(history);


    useEffect(() => {
        soundAlertRef.current = play;

        if (!("Notification" in window)) {
            console.log("This browser doesn't support notifications");
        } else {
            Notification.requestPermission();
        }
    }, [play]);

    useEffect(() => {
        setNotifications(tickets);
    }, [tickets]);

    useEffect(() => {
        ticketIdRef.current = ticketIdUrl;
    }, [ticketIdUrl]);

    useEffect(() => {
        const socket = getSocket(true);
        const connectEvent = () => socket.emit("joinNotification");
        //const blockNonDefaultConnections = user.profile !== "admin" && isActive('blockNonDefaultConnections');
        const blockNonDefaultConnections = isActive('blockNonDefaultConnections');
        const ignoreUserConnectionForGroups = isActive('ignoreUserConnectionForGroups');
        const userCanInteractWithAllGroups = user.profile === "admin" || isActive('userCanInteractWithAllGroups');
        //const enableGroupNotifications = isActive("enableGroupNotifications");
        //const enableBotNotifications = isActive("enableBotNotifications");
        const enableGroupNotifications = settings && (settings.enableGroupNotifications === 'true' || settings.enableGroupNotifications) ? true: false;
        const enableBotNotifications = settings && (settings.enableBotNotifications === 'true' || settings.enableBotNotifications) ? true: false;
        const enableSessionBot = isActive("enableSessionBot");


        setAllMuteds(mutedItems);


        const userAllowedToGroup = (ticket) => userCanInteractWithAllGroups ? true : ticket.contact.allowUsers.some((item) => item.id == user.id);

        const ticketEvent = data => {
            if (data.action === "updateUnread" || data.action === "delete") {
                setNotifications(prevState => {
                    const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
                    if (ticketIndex !== -1) {
                        prevState.splice(ticketIndex, 1);
                        return [...prevState];
                    }
                    return prevState;
                });

                setDesktopNotifications(prevState => {
                    const notfiticationIndex = prevState.findIndex(
                        n => n.tag === String(data.ticketId)
                    );
                    if (notfiticationIndex !== -1) {
                        prevState[notfiticationIndex].close();
                        prevState.splice(notfiticationIndex, 1);
                        return [...prevState];
                    }
                    return prevState;
                });
            }
        }


        const appMessageEvent = data => {
            if (
                data.action === "create" &&
                !data.message.read &&
                (data.ticket.userId === user?.id || !data.ticket.userId)
            ) {
                if (data.ticket.status == 'paused') {
                    let pauseds = countPaused + 1;
                    setCountPaused(Number(pauseds));
                }

                const shouldNotNotificate =
                    (data.message.ticketId === ticketIdRef.current &&
                        document.visibilityState === "visible") ||
                    muteds.includes(data.message.ticketId) ||
                    (data.ticket.userId && data.ticket.userId !== user?.id) ||
                    (data.ticket.status === 'feedback' || data.ticket.status === 'paused') ||
                    (data.ticket.queue && !userQueueIds.includes(data.ticket.queue.id)) ||
                    (blockNonDefaultConnections && (data.ticket.status != 'group' || !ignoreUserConnectionForGroups) && user?.whatsappId && data.ticket.whatsappId != user?.whatsappId) ||
                    (data.ticket.status == "group" && (!enableGroupNotifications || !userAllowedToGroup(data.ticket)));
                if (shouldNotNotificate) return;


                /*if (data.ticket.status === 'bot' && !enableBotNotifications && user.profile !== "admin") {
                    return;
                }*/


                if (data.ticket.status === 'bot' && !enableBotNotifications || data.ticket.status === 'bot' && !enableSessionBot) {
                    return;
                }

                setNotifications(prevState => {
                    const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
                    if (ticketIndex !== -1) {
                        prevState[ticketIndex] = data.ticket;
                        return [...prevState];
                    }
                    return [data.ticket, ...prevState];
                });

                handleNotifications(data);
            }
        }

        socket.on("connect", connectEvent);
        socket.on("ticket", ticketEvent);
        socket.on("appMessage", appMessageEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            /*socket.emit("leaveNotification");
            socket.off("connect", connectEvent);
            socket.off("ticket", ticketEvent);
            socket.off("appMessage", appMessageEvent);*/
            socket.disconnect();
        };
    }, [user, info, muteds, mutedItems, countPaused, setCountPaused]);

    const handleNotifications = data => {
        const { message, contact, ticket } = data;

        const options = {
            body: `${message.body} - ${format(new Date(), "HH:mm")}`,
            icon: contact.profilePicUrl,
            tag: ticket.id,
            renotify: true,
        };

        const notification = new Notification(
            `${i18n.t("tickets.notification.message")} ${contact.name}`,
            options
        );

        notification.onclick = e => {
            e.preventDefault();
            window.focus();
            historyRef.current.push(`/tickets/${ticket.id}`);
        };

        setDesktopNotifications(prevState => {
            const notfiticationIndex = prevState.findIndex(
                n => n.tag === notification.tag
            );
            if (notfiticationIndex !== -1) {
                prevState[notfiticationIndex] = notification;
                return [...prevState];
            }
            return [notification, ...prevState];
        });
        settings && settings.enableSound && !mutedItems.includes(ticket.id) && soundAlertRef.current()
        console.log(settings)
    };

    const handleClick = () => {
        setIsOpen(false); // always disabled
    }

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const NotificationTicket = ({ children }) => {
        return <div onClick={handleClickAway}>{children}</div>;
    };

    return (
        <>

            <IconButton
                onClick={handleClick}
                className={classes.btnNotification}
                buttonRef={anchorEl}
                aria-label="Open Notifications"
                color="inherit"
            >
                <Badge badgeContent={notifications.length} color="secondary">
                    <ChatIcon />
                </Badge>
            </IconButton>
            <Popover
                disableScrollLock
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={handleClickAway}
            >
                <List dense className={classes.tabContainer}>
                    {notifications.length === 0 ? (
                        <ListItem>
                            <ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
                        </ListItem>
                    ) : (
                        notifications.map(ticket => (
                            <NotificationTicket key={ticket.id}>
                                <TicketListItem ticket={ticket} />
                            </NotificationTicket>
                        ))
                    )}
                </List>
            </Popover>
        </>
    );
};

export default NotificationsPopOver;
