import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, IconButton, makeStyles, useTheme } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import RestorePageOutlinedIcon from "@material-ui/icons/RestorePageOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined"
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import UserModal from "../components/UserModal";
import BackdropLoading from "../components/BackdropLoading";
import NotificationsPopOver from "../components/NotificationsPopOver";
import Typography from "@material-ui/core/Typography";
import Hidden from '@material-ui/core/Hidden';

import AccountIcon from "@material-ui/icons/AccountCircle";
import ExitIcon from "@material-ui/icons/ExitToApp";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import Settings from "../helpers/Settings";
import { SettingsContext } from "../context/Settings/SettingsContext";
import ColorModeContext from "./themeContext";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from "react-router-dom";
import Version from "../helpers/version";
import NotifySettingsModal from "../components/NotifySettingsModal";

function ListItemLink(props) {
	const { icon, primary, to, className, withSettings, settingsNotify } = props;



	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<RouterLink to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);

	return (
		<li>
			<ListItem button component={renderLink} className={className}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
				{withSettings && <SettingsIcon style={{fontSize: 18}} onClick={settingsNotify(true)} />}
			</ListItem>
		</li>
	);
}

const useStyles = makeStyles(theme => ({
	menuItem: {
		fontSize: '16px !important',
		'&:hover': {
			color: 'unset'
		},
		'&:focus': {
			color: 'unset'
		}
	},
	menuItemLink: {
		'&:hover': {
			cursor: 'pointer',
			color: 'unset',
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		},
		'&:focus': {
			color: 'unset'
		}
	}, 
	iconSettings: {
		fontSize: 14
	}
}));

const MainListItems = ({ collapsed }) => {
	const { whatsApps } = useContext(WhatsAppsContext);
	const { user } = useContext(AuthContext);
	const { isActive, getSettingValue } = useContext(SettingsContext);
	const [connectionWarning, setConnectionWarning] = useState(false);
	const [userModalOpen, setUserModalOpen] = useState(false);
	const { handleLogout, loading } = useContext(AuthContext);
	const [enableToggleDarkMode, setEnableToggleDarkMode] = useState(false);
	const [openSettingsNotify, setSettingsNotify] = useState(false);
	const history = useHistory();

	const classes = useStyles();

	const theme = useTheme();

	const { colorMode } = useContext(ColorModeContext);

	const handleOpenUserModal = () => {
		setUserModalOpen(true);
	};

	const handleClickLogout = () => {
		handleLogout();
	};

	const handleChangelog = () => {
		//history.push('/updates');
	};

	const settingIsActive = (key) => {
		return isActive(key);
	}

	const toggleColorMode = () => {
		colorMode.toggleColorMode();
	}

	//Modal Configuraçoes de Notificações
	const handleOpenSettingsNotifications = () => {
		setSettingsNotify(true);
	};

	const handleCloseSettingsNotify = () => {
		setSettingsNotify(false);
	};


	useEffect(() => {
	}, [openSettingsNotify]);
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (whatsApps.length > 0) {
				const offlineWhats = whatsApps.filter(whats => {
					return (
						whats.status === "qrcode" ||
						whats.status === "PAIRING" ||
						whats.status === "DISCONNECTED" ||
						whats.status === "TIMEOUT" ||
						whats.status === "OPENING"
					);
				});
				if (offlineWhats.length > 0) {
					setConnectionWarning(true);
				} else {
					setConnectionWarning(false);
				}
			}
		}, 2000);
		return () => clearTimeout(delayDebounceFn);
	}, [whatsApps]);

	if (loading) {
		return <BackdropLoading />;
	}

	return (
		<>
			<div>
				<UserModal
					open={userModalOpen}
					onClose={() => setUserModalOpen(false)}
					userId={user?.id}
				/>
				<NotifySettingsModal
					open={openSettingsNotify}
					onClose={() => setSettingsNotify(false)}
					userId={user?.id}
				/>
				{enableToggleDarkMode && <ListItem>
					<IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
						{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
					</IconButton>
				</ListItem>}
				{user.profile === "admin" || settingIsActive("showDashboard") ?
					<ListItemLink
						to="/"
						primary="Dashboard"
						icon={<DashboardOutlinedIcon />}
						className={classes.menuItem}
					/>
					: <></>}
				{user.profile === "admin" || settingIsActive("showConnections") ?
					<ListItemLink
						to="/connections"
						primary={i18n.t("mainDrawer.listItems.connections")}
						icon={
							<Badge badgeContent={connectionWarning ? "!" : 0} color="error">
								<SyncAltIcon />
							</Badge>
						}
						className={classes.menuItem}
					/>
					: <></>}
				<ListItemLink
					to="/tickets"
					primary={i18n.t("mainDrawer.listItems.tickets")}
					icon={user.id && <NotificationsPopOver  />}
					className={classes.menuItem}
					withSettings={true}
					settingsNotify={() => handleOpenSettingsNotifications}
				/>

				{user.profile === "admin" || settingIsActive("showContacts") ?
					<ListItemLink
						to="/contacts"
						primary={i18n.t("mainDrawer.listItems.contacts")}
						icon={<ContactPhoneOutlinedIcon />}
						className={classes.menuItem}
					/>
					: <></>}
				<ListItemLink
					to="/shortcut-messages"
					primary={i18n.t("mainDrawer.listItems.shortcut_messages")}
					icon={<RestorePageOutlinedIcon />}
					className={classes.menuItem}
				/>
				<ListItemLink
					to="/scheduled-messages"
					primary={i18n.t("mainDrawer.listItems.scheduled_messages")}
					icon={<AccessAlarmIcon />}
					className={classes.menuItem}
				/>
				{user.profile === "admin" || settingIsActive("showTags") ? <ListItemLink
					to="/tags"
					primary={i18n.t("mainDrawer.listItems.tags")}
					icon={<LoyaltyOutlinedIcon />}
					className={classes.menuItem}
				/> : <></>}
				<Can
					role={user.profile}
					perform="drawer-admin-items:view"
					yes={() => (
						<>
							<Divider />
							<ListSubheader inset>
								{i18n.t("mainDrawer.listItems.administration")}
							</ListSubheader>
							<ListItemLink
								to="/users"
								primary={i18n.t("mainDrawer.listItems.users")}
								icon={<PeopleAltOutlinedIcon />}
								className={classes.menuItem}
							/>
							<ListItemLink
								to="/queues"
								primary={i18n.t("mainDrawer.listItems.queues")}
								icon={<AccountTreeOutlinedIcon />}
								className={classes.menuItem}
							/>
						</>
					)}
				/>

				<Divider />

				{user.profile === "admin" ?
					<ListItemLink
						to="/Settings"
						primary={i18n.t("mainDrawer.listItems.settings")}
						icon={<RestorePageOutlinedIcon />}
						className={classes.menuItem}
					/>
					: <></>}

				{user.profile === "admin" ?
					<ListItemLink
						to="/Bots"
						primary={i18n.t("mainDrawer.listItems.bots")}
						icon={<BallotOutlinedIcon />}
						className={classes.menuItem}
					/>
					: <></>}

				<ListItem className={classes.menuItemLink} onClick={handleOpenUserModal} >
					<ListItemIcon><AccountIcon /></ListItemIcon>
					<ListItemText primary={i18n.t("mainDrawer.appBar.user.profile")} />
				</ListItem>

				{/*<ListItemLink
						to="/changelog"
						primary={'Changelog'}
						icon={<BallotOutlinedIcon />}
						className={classes.menuItem}
					/>*/}
			

				<ListItem className={classes.menuItemLink} onClick={handleClickLogout} >
					<ListItemIcon><ExitIcon /></ListItemIcon>
					<ListItemText primary={i18n.t("mainDrawer.appBar.user.logout")} />
				</ListItem>

				{!collapsed && <React.Fragment>
					<Divider />
					<Hidden only={['sm', 'xs']}>
						<img style={{ width: "100%", padding: "10px" }} src="/suporte.png" alt="image" />
					</Hidden>
					<Typography onClick={handleChangelog} style={{ fontSize: "12px", cursor:"pointer", padding: "10px", textAlign: "right", fontWeight: "bold" }}>
						Versão: {Version}
					</Typography>
				</React.Fragment>
				}
			</div>

			

		</>
	);
};

export default MainListItems;
