import WhatsApp from "@material-ui/icons/WhatsApp";
import FileCopy from "@material-ui/icons/FileCopy";
import React, { useRef, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    whatsappButton: {
        marginRight: theme.spacing(1)
    }
}));

const ContactImportModal = ({ open, handleClose, handleWhatsappImport }) => {
    const classes = useStyles();
    const history = useHistory();

    const historyRef = useRef(history);

    const handleFileImport = () => {
        historyRef.current.push("/contacts/import");
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle>
                    <p>Escolha o tipo de importação</p>
                </DialogTitle>
                <DialogContent>
                    <Button variant="contained" color="primary" onClick={handleWhatsappImport} className={classes.whatsappButton}>
                        <WhatsApp /> Importar contatos do Whatsapp
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleFileImport}>
                        <FileCopy /> Importar contatos de arquivo
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ContactImportModal;