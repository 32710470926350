import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { VolumeUp, VolumeOff } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Checkbox, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { MutedItemsContext } from '../../context/Tickets/MutedItems';

import contrastColor from "../../helpers/contrastColor";
import { toggleFixedTicket } from "../../helpers/userOptions";
import ContactTag from "../ContactTag";
import ConnectionIcon from "../ConnectionIcon";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",		
		'@media (min-width: 600px)': {
			"&:hover $CheckboxTicket": {
				display: "block"
			},
			'&:hover $buttonVol': {
			  opacity: 100,		
			  display: 'none',	  
			},
			'&$checked $CheckboxTicket': {
			  display: 'block',
			},
		  }
	},
	buttonVol:{
		display: 'none',
		opacity: 0,
		fontSize: 10,
		transition: "all ease 0.5s",
		position: 'absolute',
		right: 0,
		backgroundColor: 'transparent !important',
		bottom: 10,
		width: 20,
		height: 20		
	},
	iconVol: {
		fontSize: 20,
		width: 20
	},
	CheckboxTicket: {
		display: "none",
		transition: "all ease 0.5s"
	},
	checked: {},
	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	/* textBlur:{
		color: 'transparent !important',
		textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
	},*/

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		position: "absolute",
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
		marginTop: "-15px",
		left: "15px",
		borderRadius: 0,
	},

	fixItem: {
		position: "absolute",
		alignSelf: "center",
		marginRight: "auto",
		marginLeft: 8,
		marginTop: 0,
		right: "15px",
		borderRadius: 0,
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	ticketRightBottomBlock: {
		bottom: 5,
		display: "flex"
	},
	userTag: {
		background: "#FCFCFC",
		color: "#999",
		border: "1px solid #CCC",
		marginRight: 5,
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	queueTag: {
		background: "#FCFCFC",
		color: "#000",
		marginRight: 5,
		padding: 1,
		fontWeight: 'bold',
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	connectionTag: {
		background: "green",
		color: "#FFF",
		marginRight: 5,
		padding: 1,
		fontWeight: 'bold',
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 3,
		fontSize: "0.8em",
		whiteSpace: "nowrap"
	},
	ticketRateEmoji: {
		marginRight: 10
	},
	whatsappName: {
		color: "#AAA"
	},
	secondaryContentWrapper: {
		minHeight: "45px",
		display: 'block'
	},
	secondaryContentFirst: {

	},
	secondaryContentSecond: {
		display: 'flex',
		marginTop: 5
	},
	cardBottom: {
		overflow: 'hidden'
	},
	connectionIcon: {
		marginRight: theme.spacing(1)
	}


}));

const TicketListItem = ({ ticket, showWhatsappConnection, fixedTickets, disableAnchor, isChecked, onCheckboxChange }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const { mutedItems, muteTicket } = useContext(MutedItemsContext);

	
	  



	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		onCheckboxChange(ticket.id, isChecked);
	};

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const fixTicket = async (ticket) => {
		try {
			await toggleFixedTicket(ticket.id);
		} catch (err) {
			toastError(err);
		}
	}

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const renderTicketRate = ticket => {
		const map = {
			'5': '🥰',
			'4': '😀',
			'3': '😐',
			'2': '😤',
			'1': '😡'
		}
		return (
			<span>{ticket.rate ? map[ticket.rate] : ''}</span>
		)
	}

	const canShowTicketRate = () => {
		return user.profile === 'admin';
	}

	return (
		<React.Fragment key={ticket.id}>

			<ListItem
				dense
				button
				onClick={e => {
					//if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
					[classes.checked]: isChecked
				})}
			>


				<Checkbox
					className={classes.CheckboxTicket}
					checked={isChecked}
					onChange={handleCheckboxChange}
				/>

				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem setor"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				<ListItemAvatar>
					<Avatar style={{ marginTop: '-14px', marginLeft: '4px' }} src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					className={classes.cardBottom}
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{/*<span className={classes.textBlur}>
									{ticket.contact ? ticket.contact.name : '(Sem Contato)'}
								</span>*/}
								{ticket.contact ? ticket.contact.name : '(Sem Contato)'}
							</Typography>
							{ticket.status === "closed" && (
								<Badge
									className={classes.closedBadge}
									style={{ right: '10px' }}
									badgeContent={"Resolvido"}
									color="primary"
								/>
							)}
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy HH:mm")}</>
									)}
								</Typography>
							)}
						</span>
					}
					secondary={
						<span className={classes.secondaryContentWrapper}>
							<span className={classes.secondaryContentFirst}>
								<Badge
									className={classes.newMessagesCount}
									badgeContent={ticket.unreadMessages}
									classes={{
										badge: classes.badgeStyle,
									}}
								/>
								{ticket.status === 'group' &&
									<div onClick={() => { fixTicket(ticket) }} style={{ color: fixedTickets && fixedTickets.indexOf(ticket.id) > -1 ? '#000' : '#CCC' }} className={classes.fixItem}>
										<FontAwesomeIcon icon={faThumbTack} />
									</div>
								}
								<Typography
									className={classes.contactLastMessage}
									noWrap
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{ticket.lastMessage ? (
										<MarkdownWrapper disableAnchor={disableAnchor} oneline={true}>{ticket.lastMessage.indexOf('[') === 0 ? i18n.t(`messages.${ticket.lastMessage}`) : ticket.lastMessage}</MarkdownWrapper>
									) : (
										<br />
									)}
								</Typography>
							</span>
							<span className={classes.secondaryContentSecond}>
								{canShowTicketRate() && ticket.rate && (<div className={classes.ticketRateEmoji}>{renderTicketRate(ticket)}</div>)}
								{ticket.type && <ConnectionIcon width="20" height="20" className={classes.connectionIcon} connectionType={ticket.type} />}
								{showWhatsappConnection && ticket.whatsapp && (<div className={classes.connectionTag}>{ticket.whatsapp.name}</div>)}
								{ticket.queue && (<div style={{ backgroundColor: ticket.queue.color, color: contrastColor(ticket.queue.color) }} className={classes.queueTag}>{ticket.queue.name}</div>)}
								{ticket.user && (
									<div className={classes.userTag}>{ticket.user.name}</div>
								)}
								{ticket.contact && ticket.contact.tags && ticket.contact.tags.map((tag) => {
									return (
										<ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
									);
								})}
							</span>
						</span>
					}
				/>
				 <ButtonWithSpinner onClick={(e) => muteTicket(ticket.id)} className={classes.buttonVol} >
				 {mutedItems && mutedItems.includes(ticket.id) ? 
				 <VolumeOff  className={classes.iconVol} />:
				 <VolumeUp  className={classes.iconVol} />}
                </ButtonWithSpinner>
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						//color="primary"
						style={{ backgroundColor: 'green', color: 'white', padding: '0px', bottom: '0px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>

				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
