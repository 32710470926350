import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import ForwardMessageModal from "../ForwardMessageModal";
import AddNoteModal from "../AddNoteModal";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, handleNoteCreated, ticketId, anchorEl }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [messageForwardModalOpen, setmessageForwardModalOpen] = useState(false);
	const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
	const uniqueMessage = Array.isArray(message) ? message[0] : message;

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${uniqueMessage.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const toDataURL = async (url) => {
		return fetch(url).then((response) => {
			return response.blob();
		}).then(blob => {
			return URL.createObjectURL(blob);
		});
	}

	const download = async (url, name) => {
		const a = document.createElement("a");
        a.href = await toDataURL(url);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
	}

	const handleDownloadMedias = async () => {
		//window.open(message.mediaUrl);
		var urlParts = message.mediaUrl.split('/');
		download(message.mediaUrl, urlParts[urlParts.length-1]);
		message.childs.forEach((m) => {
			urlParts = m.mediaUrl.split('/');
			download(m.mediaUrl, urlParts[urlParts.length-1]);
		});
	}

	const handleDownloadMediaZip = async () => {
		const fileContent = await api.get('/messages/' + message.id + '/download', {responseType: 'arraybuffer'});
		const url = window.URL.createObjectURL(new Blob([fileContent.data], {type: 'arraybuffer'}));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'imagens-' + message.id + '.zip'); //or any other extension
		document.body.appendChild(link);
		link.click();
	}

	const hanldeReplyMessage = () => {
		setReplyingMessage(uniqueMessage);
		handleClose();
	};


	const handleAddNote= () => {
		setAddNoteModalOpen(true)
		handleClose();
	};

	const handleCloseAddNoteModal= () => {
		setAddNoteModalOpen(false)
		handleClose();
	};

	const hanldeForwardMessage = () => {
		setmessageForwardModalOpen(true)
		handleClose();
	};

	const handleCloseForwardMessageModal = () => {
     	setmessageForwardModalOpen(false)
    };

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleNoteAdd = () => {
		handleNoteCreated();
	}

	return (
		<>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>

			<ForwardMessageModal
				 open={messageForwardModalOpen}
				 onClose={handleCloseForwardMessageModal}
				 aria-labelledby="form-dialog-title"
				 message={message}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ForwardMessageModal>


			<AddNoteModal
				open={addNoteModalOpen}
				 onClose={handleCloseAddNoteModal}
				 aria-labelledby="form-dialog-title"
				 message={message}
				 ticketId={ticketId}
				 noteCreated={handleNoteAdd}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</AddNoteModal>


			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{uniqueMessage.fromMe && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("messageOptionsMenu.delete")}
					</MenuItem>
				)}
				<MenuItem onClick={hanldeForwardMessage}>
					{i18n.t("messageOptionsMenu.forward")}
				</MenuItem>
				<MenuItem onClick={handleAddNote}>
					{i18n.t("messageOptionsMenu.create_note")}
				</MenuItem>
				<MenuItem onClick={hanldeReplyMessage}>
					{i18n.t("messageOptionsMenu.reply")}
				</MenuItem>
				{message.childs && message.childs.length > 0 && (
					<MenuItem onClick={handleDownloadMedias}>
						{i18n.t("messageOptionsMenu.download_all")}
					</MenuItem>
				)}
				{message.childs && message.childs.length > 0 && (
					<MenuItem onClick={handleDownloadMediaZip}>
						{i18n.t("messageOptionsMenu.download_zip")}
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

export default MessageOptionsMenu;
