import React from "react";
import './audio.css';

const MessageAudio = ({ message }) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const render = () => {
        if (!isSafari) {
            return (
                <audio controls src={message.mediaUrl}>
                    <source src="{message.mediaUrl}" type="audio/ogg" />
                </audio>
            )
        } else {
            return (
                <audio controls>
                    <source src={`${apiURL}/messages/${message.id}/download/mp3`} type="audio/mp3"></source>
                </audio>
            );
        }
    }
    return render();
}

export default MessageAudio;