import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import {
    MoreVert, Replay, Repeat, Close, DeleteForever, DoneAll, Pause, PlayArrow,
    VolumeUp, VolumeOff, Fullscreen, Timer, AspectRatio
} from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';
import TransferTicketModal from "../TransferTicketModal";
import ConfirmationModal from "../ConfirmationModal";
import ScheduledMessageModal from "../ScheduledMessageModal";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ResolveTicketModal from "../MessagesList/ResolveTicketModal";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { MutedItemsContext } from '../../context/Tickets/MutedItems';

import PublicIcon from '@material-ui/icons/Public';
import ChatSettingsModal from "../ChatSettingsModal";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        //flex: "none",
        display: 'flex',
        flexWrap: 'wrap',
        justifyItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: "center",
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(0),
        },
    },
    buttonsTicket: {
        height: 30,
        borderRadius: '5px!important',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    }
}));

const TicketActionButtons = ({ ticket, handleClose, handleFullscreen, fullscreen, integrationIframes, onLoadIframe }) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [settingChatModalOpen, setSettingsChatModalOpen] = useState(false);
    const [resolveTicketModalOpen, setResolveTicketModalOpen] = useState(false);
    const isMounted = useRef(true);

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const { getSettingValue } = useContext(SettingsContext);
    const { mutedItems, muteTicket } = useContext(MutedItemsContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    const handleOpenTransferModal = e => {
        setTransferTicketModalOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    };

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    };

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
        }
    };

    const handleOpenTicketOptionsMenu = e => {
        //setAnchorEl(e.currentTarget);
    };

    const handleCloseTicketOptionsMenu = e => {
        setAnchorEl(null);
    };

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleResolveTicket = (e) => {
        handleUpdateTicketStatus(e, "closed", user?.id);
    }

    const handleResolveTicketFeedback = (e) => {
        handleUpdateTicketStatus(e, "feedback", user?.id);
    }

    const handleResolveTicketNoMessage = (e) => {
        handleUpdateTicketStatus(e, "closed", user?.id, true);
    }

    const handleSettingsChat = () => {
        setSettingsChatModalOpen(true);
        if (typeof (handleClose) == "function") handleClose();
    }

    const handleCloseSettingsChat = () => {
        setSettingsChatModalOpen(false);
        if (typeof (handleClose) == "function") handleClose();
    }

    const handleIframeClick = (iframe) => {
        setMenuOpen(false);
        if (onLoadIframe) {
            onLoadIframe(iframe);
        }
    }

    const handleUpdateTicketStatus = async (e, status, userId, closeNow) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                closeNow
            });

            setLoading(false);
            if (status === "open") {
                history.push(`/tickets/${ticket.id}`);
            } else {
                history.push("/tickets");
            }
        } catch (err) {
            setLoading(false);
            toastError(err)
        }
    };


    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    return (
        <div className={classes.actionButtons}>
            <ButtonWithSpinner onClick={(e) => muteTicket(ticket.id)} className={classes.buttonsTicket}>
                {mutedItems && mutedItems.includes(ticket.id) ? <VolumeOff /> : <VolumeUp />}
            </ButtonWithSpinner>
            {(ticket.status === "closed" || ticket.status === "feedback") && (
                <ButtonWithSpinner
                    style={{ backgroundColor: 'black', color: 'white', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                    loading={loading}
                    startIcon={<Replay />}
                    className={classes.buttonsTicket}
                    size="small"
                    variant="contained"
                    onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
                >
                    {i18n.t("messagesList.header.buttons.reopen")}
                </ButtonWithSpinner>
            )}


            {(ticket.status === "open" || ticket.status === "group") && (
                <>
                    {ticket.status === "open" && <ButtonWithSpinner
                        style={{ backgroundColor: 'black', color: 'white', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                        loading={loading}
                        startIcon={<Replay />}
                        className={classes.buttonsTicket}
                        size="small"
                        variant="contained"
                        onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                    >
                        {i18n.t("messagesList.header.buttons.return")}
                    </ButtonWithSpinner>}
                    <ButtonWithSpinner
                        style={{ backgroundColor: 'white', color: 'black', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                        loading={loading}
                        className={classes.buttonsTicket}
                        size="small"
                        variant="contained"
                        startIcon={<Pause />}
                        onClick={e => handleUpdateTicketStatus(e, "paused", ticket.isGroup === true ? ticket.contact?.id : user?.id)}
                    >
                        {i18n.t("messagesList.header.buttons.pause")}
                    </ButtonWithSpinner>
                    <ButtonWithSpinner
                        style={{ backgroundColor: '#2ECC71', color: '#00000', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                        loading={loading}
                        startIcon={<DoneAll />}
                        className={classes.buttonsTicket}
                        size="small"
                        variant="contained"
                        onClick={e => setResolveTicketModalOpen(true)}
                    >
                        {i18n.t("messagesList.header.buttons.resolve")}
                    </ButtonWithSpinner>

                    {ticket.status === "open" && <ButtonWithSpinner
                        style={{ backgroundColor: '#F1C40F', color: 'black', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                        loading={loading}
                        startIcon={<Repeat />}
                        className={classes.buttonsTicket}
                        size="small"
                        variant="contained"
                        onClick={handleOpenTransferModal}
                    >
                        Transferir
                    </ButtonWithSpinner>}

                    {getSettingValue('canDeleteTickets') === 'enabled' &&
                        <Can
                            role={user.profile}
                            perform="ticket-options:deleteTicket"
                            yes={() => (
                                <ButtonWithSpinner
                                    style={{ backgroundColor: '#E74C3C', color: '#ffffff', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                                    loading={loading}
                                    startIcon={<DeleteForever />}
                                    className={classes.buttonsTicket}
                                    size="small"
                                    variant="contained"
                                    onClick={handleOpenConfirmationModal}
                                >
                                    Excluir
                                </ButtonWithSpinner>
                            )}
                        />}

                    <ConfirmationModal
                        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} #${ticket.id
                            }?`}
                        open={confirmationOpen}
                        onClose={setConfirmationOpen}
                        onConfirm={handleDeleteTicket}
                    >
                        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
                    </ConfirmationModal>
                    {ticket.status === "open" && <TransferTicketModal
                        modalOpen={transferTicketModalOpen}
                        onClose={handleCloseTransferTicketModal}
                        ticket={ticket}
                    />}

                    <ChatSettingsModal
                        open={settingChatModalOpen}
                        onClose={() => setSettingsChatModalOpen(false)}
                        onAccept={handleResolveTicket}
                        onAcceptFeedback={handleResolveTicketFeedback}
                        onAcceptNoEndMessage={handleResolveTicketNoMessage}
                        ticket={ticket}
                    />
                    <ResolveTicketModal
                        open={resolveTicketModalOpen}
                        onClose={() => setResolveTicketModalOpen(false)}
                        onAccept={handleResolveTicket}
                        onAcceptFeedback={handleResolveTicketFeedback}
                        onAcceptNoEndMessage={handleResolveTicketNoMessage}
                        ticket={ticket}
                    />
                </>

            )}

            {ticket.status === "paused" && (
                <ButtonWithSpinner
                    style={{ backgroundColor: '#2ECC71', color: '#00000', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                    loading={loading}
                    className={classes.buttonsTicket}
                    size="small"
                    variant="contained"
                    startIcon={<PlayArrow />}
                    onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
                >
                    {i18n.t("messagesList.header.buttons.unpause")}
                </ButtonWithSpinner>
            )}
            {ticket.status === "pending" || ticket.status === "bot" && (
                <ButtonWithSpinner
                    style={{ backgroundColor: 'black', color: 'white', fontSize: '9px', margin: '2px', borderRadius: '0px' }}
                    loading={loading}
                    className={classes.buttonsTicket}
                    size="small"
                    variant="contained"
                    onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
                >
                    {i18n.t("messagesList.header.buttons.accept")}
                </ButtonWithSpinner>
            )}

            {fullscreen &&
                <ButtonWithSpinner 
                style={{ fontSize: '9px', margin: '2px' }}
                onClick={handleFullscreen} className={classes.buttonsTicket}>
                    <Fullscreen style={{ fontSize: 16, paddingRight: 5 }} /> Sair Tela cheia
                </ButtonWithSpinner>}
            <div>

                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    style={{ paddingHorizontal: 3, paddingTop: 10 }}
                >
                    <MoreVert style={{ fontSize: 16, padding: 0 }} />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                >

                    <MenuItem onClick={handleFullscreen}>
                        <Fullscreen style={{ fontSize: 16, paddingRight: 5 }} /> Fullscreen
                    </MenuItem>
                    <MenuItem onClick={handleSettingsChat}>
                        <SettingsIcon style={{ fontSize: 16, paddingRight: 5 }} /> Configurações do Chat
                    </MenuItem>
                    {integrationIframes && integrationIframes.length && integrationIframes.map((iframe) => (
                        <MenuItem key={iframe.id} onClick={() => handleIframeClick(iframe)}>
                            <AspectRatio style={{ fontSize: 16, paddingRight: 5 }} /> {iframe.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>

        </div>
    );
};

export default TicketActionButtons;
