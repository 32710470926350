import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, Chip, ListItemText, makeStyles } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import chroma from "chroma-js";

const TicketsQueueSelect = ({
    userQueues,
    selectedQueueIds = [],
    onChange,
    width,
    noMargin,
    showSelected
}) => {
    const [localValues, setLocalValues] = useState([]);
    const { user } = useContext(AuthContext);

    const handleChange = e => {
        setLocalValues(e.target.value);
    };

    useEffect(() => {
        let selectedValues = localValues;
        const allIndex = selectedValues.indexOf('all');
        const notAllIndex = selectedValues.indexOf('notall');

        if (notAllIndex > -1) {
            selectedValues.splice(allIndex, 1);
            selectedValues = [-1];
            selectedValues = [...selectedValues, ...userQueues?.map(queue => queue.id)];
        }

        if (allIndex > -1) {
            selectedValues = [];
            window.localStorage.setItem('selectedQueueIds', JSON.stringify([]));
        }
        if (selectedValues.length > 0) {
            window.localStorage.setItem('selectedQueueIds', JSON.stringify(selectedValues));
        }
        selectedValues = [...new Set(selectedValues)];
        onChange(selectedValues);
    }, [localValues]);

    useEffect(() => {
        if (localValues.length != selectedQueueIds.length) {
            setLocalValues(selectedQueueIds);
        }
    }, [selectedQueueIds]);

    return (
        <div style={{ width: width || 120 }}>
            <FormControl variant="outlined" fullWidth margin={noMargin ? undefined : "dense"}>
                <Select
                    multiple
                    displayEmpty
                    value={selectedQueueIds}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={() => {
                        if (!showSelected || !selectedQueueIds || selectedQueueIds.length === 0) {
                            return i18n.t("ticketsQueueSelect.placeholder");
                        } else {
                            return (userQueues.map((option, index) => {
                                if (selectedQueueIds.indexOf(option.id) > -1) {
                                    return (<Chip
                                        label={option.name}
                                        size='medium'
                                        className="MuiAutocomplete-tag"
                                        style={{backgroundColor: option.color, color: chroma.contrast(option.color, 'white') > 2 ? 'white' : 'black'}}
                                        onDelete={() => {handleChange(selectedQueueIds.filter((id) => id !== option.id))}}
                                    />)
                                }
                            }));
                        }
                    }}
                >
                    {selectedQueueIds?.length === (userQueues?.length + 1) ? 
                    <MenuItem key={-1} value={'all'}>
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={userQueues?.length > 0 && (selectedQueueIds?.length - 1) === userQueues?.length}
                            indeterminate={selectedQueueIds?.length > 0 && (selectedQueueIds?.length - 1) < userQueues?.length}
                        />
                        <ListItemText primary={'Desmarcar Todos'} />
                    </MenuItem>
                    :
                    <MenuItem key={-1} value={'notall'}>
                        <Checkbox
                            size="small"
                            color="primary"
                        />
                        <ListItemText primary={'Marcar Todos'} />
                    </MenuItem>
                    }
                   <MenuItem key={0} value={-1}>
                        <Checkbox
                            style={{
                                color: "grey",
                            }}
                            size="small"
                            color="primary"
                            checked={selectedQueueIds.indexOf(-1) > -1}
                        /> 
                        <ListItemText primary="Sem Setor" />
                   
                    </MenuItem>
                    {userQueues?.length > 0 && userQueues.map(queue => (
                        <MenuItem dense key={queue.id} value={queue.id}>
                            <Checkbox
                                style={{
                                    color: queue.color,
                                }}
                                size="small"
                                color="primary"
                                checked={selectedQueueIds.indexOf(queue.id) > -1}
                            />
                            <ListItemText primary={queue.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default TicketsQueueSelect;
