import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as moment from 'moment';

import Title from "./Title";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ResponseTime = ({period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, title, yAxisTitle, setLoading}) => {
	const theme = useTheme();

  const [chartOptions, setChartOptions] = useState({});
  //const [chartData, setChartData] = useState([]);
  const [chartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [chartSeries, setChartSeries] = useState([]);
  const chart = useRef();

  useEffect(() => {
    setLoading(true);
		const delayDebounceFn = setTimeout(() => {
      if (internalLoading) return;
      setInternalLoading(true);
			const fetchContacts = async () => {
				try {
					const { data } = await api.get(endpoint, {
						params: {
							dateStart: moment(period[0]).format('YYYY-MM-DD') ,
							dateEnd: moment(period[1]).format('YYYY-MM-DD'), 
							contactIds: JSON.stringify(contactIds), 
							userIds: JSON.stringify(userIds), 
							queueIds: JSON.stringify(queueIds), 
              statuses: JSON.stringify(statuses),
							tagIds: JSON.stringify(tagIds)
						},
					});
					setChartSeries(data.data.series);
          setChartCategories(data.data.categories);
					setInternalLoading(false);
          setLoading(false);
				} catch (err) {
          setInternalLoading(false);
          setLoading(false);
					toastError(err);
				}
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
  }, [period, userIds, contactIds, queueIds, tagIds, statuses]);

  useEffect(() => {
    if (chart && chart.current && chart.current.chart) {
      if (internalLoading) {
        chart.current.chart.showLoading('Carregando...');
      } else {
        chart.current.chart.hideLoading();
      }
    }
  }, [internalLoading]);
  
  useEffect(() => {
    setChartOptions(
      {
        chart: {
          type: 'column',
          backgroundColor: theme.palette.background.paper,
          style: {color: theme.palette.text.primary}
        },
        title: {
          text: '',
          style: {color: theme.palette.text.primary}
        },
        colors: theme.mode === 'light' ? ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"] : ["#7cb5ec", "#FFFFFF", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
        xAxis: {
          categories: chartCategories,
          title: {style: {color: theme.palette.text.primary}},
          labels: {
            style: {color: theme.palette.text.primary}
          }
        },
        yAxis: {
          title: {
            text: yAxisTitle,
            style: {color: theme.palette.text.primary}
          },
          labels: {
            style: {color: theme.palette.text.primary}
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y} segundos</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        legend: {
          style: {color: theme.palette.text.primary},
          itemStyle: {color: theme.palette.text.primary},
          itemHoverStyle: {color: theme.palette.text.primary},
          lineHeight: 20
        },
        caption: {
          style: {color: theme.palette.text.primary}
        },
        subtitle: {
          style: {color: theme.palette.text.primary}
        },
        labels: {
          style: {color: theme.palette.text.primary}
        },
        series: chartSeries,
        plotOptions: {
          series: {
            point: {
              events: {
                //mouseOver: this.setHoverData.bind(this)
              }
            }
          }
        }
      },
    );
  }, [chartData, chartCategories, theme]);
     

	return (
		<React.Fragment>
			<Title>{title}</Title>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chart}
        />
		</React.Fragment>
	);
};

export default ResponseTime;
