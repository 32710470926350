import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Layers, Person, VerifiedUser } from "@material-ui/icons";
import DashboardFeedbackVisual from "../../components/Dashboard/feedbackVisual";

const useStyles = makeStyles(theme => ({
	box: {
		backgroundColor: theme.palette.background.paper,
		padding: 10,
        position: "relative",
        color: theme.palette.dark.main
	},
    title: {
        fontSize: "1.2em",
        fontWeight: "normal"
    },
    icon: {
        position: "absolute",
        top: 25,
        right: 15
    },
    value: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    block: {
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },

    avatar: {
        width: "70px",
        borderRadius: "50%",
        height: "70px",
        overflow: "hidden",
        '& img': {
            width: "70px"
        },
        marginRight: 10
    },
    avatarIcon: {
        width: "70px",
        borderRadius: "50%",
        background: "#F3F3F3",
        height: "70px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        color: "#333",
        minWidth: "70px"
    },
    bigText: {
        fontSize: "3em",
        fontWeight: "bold"
    },
    label: {
        display: "block"
    },
    queues: {
        display: "flex"
    },
    queueTag: {
        borderRadius: 20,
        background: "green",
        padding: 10,
        color: "white",
        paddingTop: 5,
        paddingBottom: 5,
        marginRight: 5
    },
    queueNo: {
        fontStyle: "italic"
    },
    emojiRate: {
        fontSize: "3em"
    },
    userQueues: {
        overflowX: "hidden"
    }
}));

const DashboardUsersBox = ({ user, ...props }) => {
    const classes = useStyles();

    const formatTicketsRate = (rate) => {
        if (rate) {
            if (Number.isInteger(parseFloat(rate))) {
                return parseInt(rate);
            }
            return parseFloat(rate).toFixed(2).toString().replace('.', ',');
        }
        return "Sem Avaliação";
    }

    return (
        <Box className={classes.box} {...props}>
            <Grid container>
                <Grid item xs={12} sm={6} md={3}>
                    <div className={classes.block}>
                        <span className={classes.avatar}>
                            <img src={user.avatarUrl} />
                        </span>
                        {user.name}
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DashboardFeedbackVisual className={classes.emojiRate} rate={user.ticketsRate} /> ({formatTicketsRate(user.ticketsRate)})
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <div className={classes.block}>
                        <div className={classes.avatarIcon}>
                            <Person />
                        </div>
                        <div>
                            <span className={classes.bigText}>{user.ticketsNumber}</span>
                            <span className={classes.label}>Atendimentos</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <div className={classes.block}>
                        <div className={classes.avatarIcon}>
                            <Layers />
                        </div>
                        <div className={classes.userQueues}>
                            Setores
                            <div className={classes.queues}>
                                {user.queues && user.queues.map(queue => (<span key={queue.id} className={classes.queueTag}>{queue.name}</span>))}
                                {(!user.queues || user.queues.length == 0) && <span className={classes.queueNo}>Sem setores</span>}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <span className={classes.icon}></span>
            <span className={classes.value}></span>
        </Box>
    )
}

export default DashboardUsersBox;