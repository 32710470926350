import React from "react";
import { useEffect, useState} from "react";
import api from "../../services/api";
import colourStyles from "../../constants/tagsColor";
import toastError from "../../errors/toastError";
import { Checkbox, Chip, FormControl, InputLabel, ListItemText, makeStyles, MenuItem, Select } from "@material-ui/core";
import AsyncSelect from "../AsyncSelect";
import chroma from "chroma-js";

const useStyles = makeStyles(theme => ({
    select: {
        
    }
}));

const SelectTags = ({onChange, selectedTags, disabled, outlined, width, includeContactsCount }) => {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [internalSelectedTags, setInternalSelectedTags] = useState([]);

    useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchTags = async () => {
				try {
					const { data } = await api.get("/tags", {params: {all: true, includeContactsCount }});
					setTags(data.tags);
                    if (selectedTags && selectedTags.length > 0) {
                        setInternalSelectedTags(selectedTags.map((tag) => {return {value: tag.id, label: tag.name, color: tag.color}}));
                    } else {
                        setInternalSelectedTags([]);
                    }
				} catch (err) {
					toastError(err);
				}
			};
			fetchTags();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [selectedTags]);

    useEffect(() => {
        onChange(internalSelectedTags);
    }, [internalSelectedTags]);

    const handleChange = (event) => {
        setInternalSelectedTags(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };
    const renderSelect = () => {
        return <Select
            //options={tags.map((tag) => {return {value: tag.id, label: tag.name, color: tag.color}})}
            multiple
            variant="outlined"
            onChange={handleChange}
            className={classes.select}
            //onRemove={handleChange}
            value={internalSelectedTags}
            disabled={disabled}
            placeholder="Selecione as tags..."
            //menuPosition={'fixed'}
            //menuPortalTarget={document.body}
            //styles={colourStyles}
            >
                {tags.map((tag) => (
                    <MenuItem dense key={tag.id} value={tag.id}>
                        <Checkbox
                            style={{
                                color: tag.color,
                            }}
                            size="small"
                            color="primary"
                            checked={internalSelectedTags.indexOf(tag.id) > -1}
                        />
                        <ListItemText primary={tag.name} />
                    </MenuItem>
                ))}
            </Select>;
    }

    return (
        outlined ? 
        <FormControl fullWidth={!width} style={{width: width || '100%'}} variant="outlined">
            <AsyncSelect 
                url="/tags" placeholder="Selecione as tags..." 
                dictKey="tags" multiple onChange={(event, values) => {setInternalSelectedTags(values)}}
                width="100%"
                label={"Tags"}
                disabled={disabled}
                disableCloseOnSelect
                renderTags={(tags, getCustomizedTagProps) => (!tags || tags.length === 0 ? 'Selecione as tags...' : tags.map((option, index) => {
                    return (<Chip
                      label={includeContactsCount ? `${option.name} (${option.contacts.length})` : option.name}
                      size='medium'
                      style={{backgroundColor: option.color, color: chroma.contrast(option.color, 'white') > 2 ? 'white' : 'black'}}
                      {...getCustomizedTagProps({ index })}
                    />)}
                  ))}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            style={{
                                color: option.color,
                            }}
                            size="small"
                            color="primary"
                            checked={selected}
                        />
                        <ListItemText primary={option.name} />
                    </React.Fragment>
                )}
            />
        </FormControl>
        : 
        renderSelect()
    );
};

export default SelectTags;