import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import AsyncSelect from "../AsyncSelect";
import { CheckboxContext } from "rsuite/lib/CheckboxGroup";

const useStyles = makeStyles((theme) => ({
  select: {},
}));

const SelectStatus = ({
  onChange,
  disabled,
  outlined,
  isSearched,
  defaultValue,
  width,
}) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [statuses, setStatuses] = useState([
    { id: "pending", name: "Aguardando" },
    { id: "open", name: "Em Atendimento" },
    { id: "paused", name: "Pausados" },
    { id: "closed", name: "Resolvidos" },
  ]);
  const [internalSelectedStatus, setInternalSelectedStatus] = useState([]);
  const [wasSearched, setWasSearched] = useState(false);


  useEffect(() => {
    onChange(internalSelectedStatus);
    setWasSearched(isSearched)   
  }, [internalSelectedStatus, isSearched]);

  const handleChange = (event) => {
    setInternalSelectedStatus(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const renderSelect = () => {
    return (
      <Select
        multiple
        variant="outlined"
        onChange={handleChange}
        className={classes.select}
        value={internalSelectedStatus}
        disabled={disabled}
        placeholder="Selecione as tags..."
      >
        {statuses.map((status) => (
          <MenuItem dense key={status.id} value={status.id}>
            <CheckboxContext
              size="small"
              color="primary"
              checked={internalSelectedStatus.indexOf(status.id) > -1}
            />
            <ListItemText primary={status.name} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  return outlined ? (
    <FormControl
      fullWidth={!width}
      style={{ width: width || "100%" }}
      variant="outlined"
    >
 

        <AsyncSelect
          placeholder="Selecione status"
          options={statuses.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
          dictKey="status"
          multiple
          onChange={(event, values) => {
            setInternalSelectedStatus(values);
          }}
          width="100%"
          label={"Status"}
          statuses={statuses}
          disabled={disabled}
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <React.Fragment>         
            <>
              <Checkbox
                size="small"
                color="primary"
                checked={selected}
              />
              <ListItemText primary={option.name} />
              </>
            </React.Fragment>
          )}
        />
     
    </FormControl>
  ) : (
    renderSelect()
  );
};

export default SelectStatus;
