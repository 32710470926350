import React from "react";
import { Block } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },
    ciphertextMessage: {
        fontStyle: "italic",
        color: "#999"
    }
})
/**
 * This component renders a messages that was not uncrypted by Whatsapp engine.
 * @param {object} message - The message object
 */
const MessageCiphertext = ({ message }) => {
    const classes = useStyles();
    return <span className={classes.ciphertextMessage}>
        <Block
            color="disabled"
            fontSize="small"
            className={classes.deletedIcon}
        />
         Essa mensagem não pôde ser carregada pelo Whatsapp Web. Você pode aguardar a atualização ou entrar em contato direto com o contato para continuar o atendimento.
    </span>
}

export default MessageCiphertext;