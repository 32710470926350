import React from "react";

import { Avatar, CardHeader, Grid } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick, infoButton }) => {
	const renderCardReader = () => {
		return (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image" />}
				title={`${contact?.name || '(sem contato)'} #${ticket.id}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
				}
				
			/>
		);
	}

	return <React.Fragment>
		{infoButton ? <Grid container justify="flex-start" alignItems="center">
			<Grid item>{infoButton}</Grid>
			<Grid item>{renderCardReader()}</Grid>
		</Grid> : renderCardReader()}
	</React.Fragment>
};

export default TicketInfo;
