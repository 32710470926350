import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ModalImage from "react-modal-image";
import CloseIcon from "@material-ui/icons/Close";
import { FileIcon, defaultStyles } from 'react-file-icon';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import AsyncSelect from "../AsyncSelect";
import { DatePicker } from 'rsuite';
import { Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Chip } from "@material-ui/core";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
    root: {
        flexWrap: "wrap",
    },
    formik: {
        overflow: "auto",
        ...theme.scrollbarStylesSoftBig,
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%"
    },
    datePickerField: {
        width: "100%",
        zIndex: 1000000,
        marginBottom: 10
    },
    datePickerFieldMenu: {
        zIndex: 9999999999
    },
    h4: {
        fontSize: 18,
        fontWeight: "bold",
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textFieldNameContainer: {
        width: "100%"
    },
    textFieldContentContainer: {
        width: "100%"
    },
    addMargin: {
        marginTop: 10,
        marginBottom: 20
    },
    uploadInput: {
        display: "none"
    },
    messageMedia: {
        width: '50px',
        height: '50px'
    },
    closeButton: {
        cursor: "pointer"
    },
    fileContainer: {
        textAlign: "center"
    },
    fileIconContainer: {
        width: "50px",
        margin: "0 auto"
    },
    formControl: {

    },
    select: {

    },
    selectLabel: {
        marginLeft: theme.spacing(1)
    },
    repeatHead: {
        fontSize: 18,
        marginTop: 40,
        fontWeight: 'bold'
    },
    warning: {
        color: "red",
        fontSize: 12
    },
    '@global': {
        '.swal2-container': {
            zIndex: "20000 !important"
        }
    }
}));

const ScheduledMessageSchema = Yup.object().shape({
    date: Yup.date().min(new Date()),
    content: Yup.string().min(8, "Informe uma mensagem de no mínimo 8 caracteres").max(60000, "Informe uma mensagem de no máximo 60.000 caracteres"),
});

const ScheduledMessageModal = ({ open, onClose, scheduledMessageId, initialValues, onSave, fromTicket, contact, message }) => {

    const classes = useStyles();

    const initialState = {
        date: new Date(),
        content: message ? message : '',
        contactId: contact && contact.id ? contact.id : null,
        repeatType: "",
        repeatBusinessDayOption: "",
        ticketStrategy: "create"
    };

    const [scheduledMessage, setScheduledMessage] = useState(initialState);
    const [initialContacts, setInitialContacts] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [media, setMedia] = useState(null);
    const [repeatSectionOpen, setRepeatSectionOpen] = useState(false);
    const [listContactSelect, setListContactSelect] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(false);

    const fileRef = useRef();

    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sáb',
        ok: 'OK',
        today: 'Hoje',
        yesterday: 'Ontem',
        last7Days: 'Últimos 7 dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
        last30Days: 'Últimos 30 dias',
        thisMonth: 'Esse mês',
        lastMonth: 'Último mês',
        thisYear: 'Esse Ano',
        lastYear: 'Último Ano',
        formattedMonthPattern: 'MMM, YYYY',
        formattedDayPattern: 'DD MMM, YYYY'
    };

    useEffect(() => {
        if (!open) return;
        const fetchScheduledMessage = async () => {
            if (initialValues) {
                setScheduledMessage(prevState => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!scheduledMessageId) {
                setInitialContacts([]);
                setInitialTags([]);
                setMedia(null);
                return;
            };

            try {
                const { data } = await api.get(`/scheduled-messages/${scheduledMessageId}`);
                setInitialContacts(data.contacts);
                setInitialTags(data.tags);
                setRepeatSectionOpen(data.repeat);
                setScheduledMessage(prevState => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchScheduledMessage();
    }, [scheduledMessageId, open, initialValues]);


    const cleanRepeat = (setFieldValue) => {
        setFieldValue("repeatType", "");
        setFieldValue("repeatValue", "");
        setFieldValue("repeatTimes", "");
        setFieldValue("repeatBusinessDayOption", "");
        setRepeatSectionOpen(false);
    }

    const handleClose = () => {
        setListContactSelect(null);
        onClose();
        setScheduledMessage(initialState);

    };

    useEffect(() => {
        if (media) {
            if (["mp3", "ogg", "wav"].indexOf(media.name.split('.').pop()) > -1) {
                setSelectedAudio(true);
            } else {
                setSelectedAudio(false);
            }
        } else {
            setSelectedAudio(false);
        }
    }, [media]);

    const checkFileSelection = (selectedMedia, values, setFieldValue) => {
        if (selectedMedia) {
            if (["mp3", "ogg", "wav"].indexOf(selectedMedia.name.split('.').pop()) > -1) {
                if (values.ticketStrategy !== "create") {
                    Swal.fire({
                        title: "Aviso!",
                        text: "Ao selecionar áudio, não é possível selecionar outra estratégia de envio. O Atendimento será criado ao enviar a mensagem. Clique em OK para continuar",
                        icon: 'warning',
                        confirmButtonText: "OK"
                    });
                }
                setFieldValue("ticketStrategy", "create");
            }
        }
    }

    const handleDeleteContact = () => {
        setListContactSelect(true);
    };

    const handleSaveScheduledMessage = async values => {
        setLoading(true);
        const formData = new FormData();

        formData.append("date", values.date ? values.date : '');

        if (values.contactIds && !fromTicket || values.contactIds && fromTicket && listContactSelect) {
            values.contactIds.map(contactId => formData.append("contactIds[]", contactId));
        }

        if (!values.contactIds && fromTicket && !listContactSelect && contact.id) {
            formData.append("contactIds[]", contact.id)
        }

        if (values.tagIds) {
            values.tagIds.map(tagId => formData.append("tagIds[]", tagId));
        }

        if (values.content) {
            formData.append("content", values.content);
        }
        if (!values.content && message && fromTicket) {
            formData.append("content", message);
        }
        formData.append("whatsappId", values.whatsappId ? values.whatsappId : '');
        formData.append("ticketStrategy", values.ticketStrategy);

        if (values.repeatType) {
            formData.append("repeatType", values.repeatType);
            formData.append("repeatValue", values.repeatValue);
            formData.append("repeatTimes", values.repeatTimes);
            formData.append("repeatBusinessDayOption", values.repeatBusinessDayOption);
        }

        if (media) {
            formData.append("medias", media);
        } else {
            if (values.mediaUrl) {
                formData.append("mediaUrl", values.mediaUrl);
            }
        }

        try {
            if (scheduledMessageId) {
                await api.put(`/scheduled-messages/${scheduledMessageId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                handleClose();
            } else {
                const { data } = await api.post("/scheduled-messages", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("scheduledMessageModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    const showFilePicker = () => {
        fileRef.current.click();
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {scheduledMessageId
                        ? `${i18n.t("scheduledMessageModal.title.edit")}`
                        : `${i18n.t("scheduledMessageModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={scheduledMessage}
                    className={classes.formik}
                    enableReinitialize={true}
                    validationSchema={ScheduledMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveScheduledMessage(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    {i18n.t("scheduledMessageModal.form.mainInfo")}
                                </Typography>
                                <div className={classes.textFieldNameContainer}>
                                    <DatePicker
                                        format="DD/MM/YYYY HH:mm"
                                        label={i18n.t("scheduledMessageModal.form.date")}
                                        onChange={(value) => { setFieldValue("date", value) }}
                                        value={typeof (values.date) === "string" ? new Date(values.date) : values.date}
                                        className={classes.datePickerField}
                                        container={document.body}
                                        menuClassName={classes.datePickerFieldMenu}
                                        //defaultValue={typeof(values.date) === "string" ? new Date(values.date) : values.date}
                                        calendarDefaultDate={typeof (values.date) === "string" ? new Date(values.date) : values.date}
                                        locale={locale}
                                        ranges={[
                                            {
                                                label: 'Agora',
                                                value: new Date()
                                            }
                                        ]}
                                    />
                                </div>
                                <Typography variant="h4" className={classes.h4}>
                                    Escolha de que Conexão será enviado a mensagem
                                </Typography>
                                <div className={classes.addMargin}>
                                    <AsyncSelect width="100%"
                                        url="/whatsapp" initialValue={values.whatsapp}
                                        dictKey="whatsapps"
                                        onChange={(event, value) => { setFieldValue("whatsappId", value ? value.id : null); setFieldValue("whatsapp", value) }}
                                        selectFirstIfOnlyOne={true}
                                        label="Conexões" />
                                </div>
                                <Typography variant="h4" className={classes.h4}>
                                    Escolha para quem será enviado.
                                </Typography>
                                <Typography variant="body1">
                                    Você pode escolher enviar para uma tag, nesse caso incluirá todos os contatos que estão associados à essa tag.<br />
                                    E também pode escolher um ou mais contatos manualmente para envio.<br />
                                    Após salvar o agendamento você poderá ver uma lista de todos os contatos para os quais serão enviadas a mensagem.
                                </Typography>
                                <Grid container spacing={2} className={classes.addMargin}>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.textFieldNameContainer}>

                                            {fromTicket && !listContactSelect ? (
                                                <Chip label={contact && contact.id && contact.name} onDelete={handleDeleteContact} color="primary" />
                                            ) :
                                                (
                                                    <AsyncSelect
                                                        width="100%"
                                                        url="/contacts"
                                                        initialValue={initialContacts}
                                                        multiple={true}
                                                        defaultValue={31}
                                                        value={31}
                                                        dictKey="contacts"
                                                        onChange={(event, value) => {
                                                            if (value) setFieldValue("contactIds", value.map((contact) => contact.id));
                                                            else setFieldValue("contactIds", []);
                                                        }}
                                                        label="Contatos"
                                                    />
                                                )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className={classes.textFieldNameContainer}>
                                            <AsyncSelect width="100%" includeContactsCount url="/tags"
                                                initialValue={initialTags} multiple={true}
                                                dictKey="tags"
                                                onChange={(event, value) => { if (value) setFieldValue("tagIds", value.map((tag) => tag.id)); else setFieldValue("tagIds", []) }}
                                                label="Tags" />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Typography variant="h4" className={classes.h4}>
                                    Criar atendimento?
                                </Typography>
                                <Typography variant="body1">
                                    Escolha qual estratégia utilizar para a criação ou não de um novo atendimento.
                                    {selectedAudio && <Typography variant="body1" className={classes.warning}>Ps: Não há suporte para criar e fechar atendimento ou ignorar as mensagens com áudio</Typography>}
                                </Typography>
                                <div className={classes.addMargin}>
                                    <Select name="ticketStrategy" labelId="ticketStrategy" value={values.ticketStrategy}
                                        onChange={(event) => setFieldValue("ticketStrategy", event.target.value)}
                                        className={classes.select} variant="outlined" fullWidth>
                                        <MenuItem value="create">Criar Atendimento normalmente</MenuItem>
                                        {!selectedAudio && <MenuItem value="nocreate">Não cria atendimento (nesse caso o sistema não salva a mensagem e nenhuma informação relacionada)</MenuItem>}
                                        {!selectedAudio && <MenuItem value="close">Criar Atendimento fechado (cria um atendimento e fecha instantaneamente, apenas para manter a mensagem no histórico)</MenuItem>}
                                    </Select>
                                </div>
                                <Typography variant="h4" className={classes.h4}>
                                    Digite a mensagem a ser enviada
                                </Typography>
                                <Typography variant="body1">
                                    Você pode usar a variável <strong>@nome</strong> no conteúdo da mensagem.<br />
                                    Mas atente-se que ao utilizar a variável <strong>@nome</strong>, caso o contato não tenha nome definido, será utilizado o número do contato no lugar.
                                </Typography>
                                <div className={classes.textFieldContentContainer}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("scheduledMessageModal.form.content")}
                                        name="content"
                                        value={values.content}
                                        error={touched.content && Boolean(errors.content)}
                                        helperText={touched.content && errors.content}
                                        placeholder="Conteúdo da mensagem"
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        multiline
                                        rows={5}
                                        fullwidth="true"
                                    />
                                </div>
                                {values.mediaUrl ? <div className={classes.fileContainer}>
                                    <p>Arquivo atual:</p>
                                    {["jpg", "jpeg", "gif", "png", "webp"].indexOf(values.mediaUrl.split('.').pop()) > -1 ?
                                        <ModalImage
                                            className={classes.messageMedia}
                                            small={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                            medium={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                            large={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                            alt="image"
                                        />
                                        : <div className={classes.fileIconContainer}>
                                            <FileIcon extension={values.mediaUrl.split('.').pop()} {...defaultStyles[values.mediaUrl.split('.').pop()]} />
                                        </div>}
                                    <CloseIcon className={classes.closeButton} onClick={() => { setFieldValue("mediaUrl", ''); setMedia(null) }} />
                                </div> :
                                    <Button className={classes.textFieldContentContainer} onClick={e => showFilePicker()}>
                                        <input
                                            multiple
                                            type="file"
                                            ref={fileRef}
                                            disabled={loading}
                                            className={classes.uploadInput}
                                            onChange={(e) => { 
                                                setMedia(Array.from(e.target.files)[0]); 
                                                setFieldValue("mediaUrl", Array.from(e.target.files)[0].name); 
                                                checkFileSelection(Array.from(e.target.files)[0], values, setFieldValue); 
                                            }}
                                        />
                                        <AttachFileIcon /> Escolha um arquivo para enviar com a mensagem (opcional)
                                    </Button>}
                                <Typography variant="h4" className={classes.repeatHead}>
                                    Recorrência (opcional)
                                    <IconButton
                                        onClick={() => setRepeatSectionOpen(!repeatSectionOpen)}
                                        aria-label="expand"
                                        size="small"
                                    >
                                        {repeatSectionOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                    {values.repeatType || values.repeatValue || values.repeatTimes || values.repeatBusinessDayOption ?
                                        <IconButton onClick={() => cleanRepeat(setFieldValue)} aria-label="expand" size="small">
                                            <DeleteForeverIcon />
                                        </IconButton> : <></>}
                                </Typography>
                                <Collapse in={repeatSectionOpen}>
                                    <Typography variant="body1">
                                        Você pode escolher enviar a mensagem de forma recorrente e escolher o intervalo. Caso seja uma mensagem a ser enviada uma única vez, não altere nada nessa seção.
                                    </Typography>
                                    <Grid container spacing={2} className={classes.addMargin}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel id="repeatType" className={classes.selectLabel}>Intervalo</InputLabel>
                                                <Select name="repeatType" labelId="repeatType" value={values.repeatType}
                                                    onChange={(event) => setFieldValue("repeatType", event.target.value)}
                                                    className={classes.select} variant="outlined" fullWidth>
                                                    <MenuItem value="day">Dias</MenuItem>
                                                    <MenuItem value="week">Semanas</MenuItem>
                                                    <MenuItem value="month">Meses</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField id="repeatValue" fullWidth
                                                label="Valor do intervalo" value={values.repeatValue} onChange={(event) => setFieldValue("repeatValue", event.target.value)}
                                                type="number" variant="outlined" InputLabelProps={{ shrink: true }} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField id="repeatTimes" fullWidth
                                                label="Enviar quantas vezes" value={values.repeatTimes}
                                                onChange={(event) => setFieldValue("repeatTimes", event.target.value)}
                                                helperText="Para enviar sempre, informe 0"
                                                type="number" variant="outlined" InputLabelProps={{ shrink: true }} />
                                        </Grid>
                                      {/**  <Grid item xs={12} md={3}>
                                            <Select id="repeatBusinessDayOption" fullWidth
                                                variant="outlined"
                                                onChange={(event) => setFieldValue("repeatBusinessDayOption", event.target.value)}
                                                placeholder="Dias úteis"
                                                helperText="Define a ação que o sistema deve tomar em caso da recorrência cair em dia não útil"
                                                label="Dias úteis" value={values.repeatBusinessDayOption}>
                                                <MenuItem value="send">Enviar normalmente em dias não úteis</MenuItem>
                                                <MenuItem value="onedaybefore">Enviar um dia útil antes</MenuItem>
                                                <MenuItem value="onedayafter">Enviar um dia útil depois</MenuItem>
                                            </Select>
                                        </Grid> **/}
                                    </Grid>
                                </Collapse>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("scheduledMessageModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {scheduledMessageId
                                        ? `${i18n.t("scheduledMessageModal.buttons.okEdit")}`
                                        : `${i18n.t("scheduledMessageModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ScheduledMessageModal;
