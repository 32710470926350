import React, { useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { FormControl, InputLabel, MenuItem, Select, Menu, IconButton, Paper } from "@material-ui/core";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import api from "../../services/api";
import { useHistory } from "react-router-dom";


const ListNotes = (ticketId) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const history = useHistory();
    const theTicket = ticketId.ticketId;

    const goNote = ({ id, messageId }) => {
        if (id) {
            //alert(id)
        history.push(`/tickets/${id}?message=${messageId}`)
        }
    };

    const [notes, setNotes] = useState([

    ]);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };


    useEffect(() => {
        const fetchNotes = async () => {
         
            try {
                const { data } = await api.get(`/notes/${theTicket}`);
                if (data) {
                    setNotes(data);
                }
            } catch (err) {
                console.log(err)
            }
        };

        fetchNotes();
    }, []);


    return (
        <div style={{ maxHeight: 350, overflow: 'auto'}}>
            {!notes || !notes.notes || notes.notes.length < 1 ? (
                <div>
                    <span>Sem notas salvas no momento</span>
                </div>
            ) : (
                <div>
               {notes.notes.map((option) => (
                    <div
                        value={option.id}
                        key={option.id}
                        style={{ display: 'flex', gap: 3, cursor: 'pointer', margin: '10px 0px', justifyContent: 'space-between' }}
                        onClick={() => goNote({ id: option.ticketId, messageId: option.messageId })}
                    >
                        <span style={{ fontSize: 13, paddingRight: 10 }}>{option.title}</span>
                        <div style={{ width: 32, height: 32, borderRadius: 10, background: `${option.color ? option.color: '#cccccc'}` }}></div>
                    </div>
                ))}
            </div>
            )}
        </div>
    );
};

export { ListNotes };
