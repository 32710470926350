import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { cleanLocalOptions } from "../../helpers/userOptions";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const useAuth = () => {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    const setUserObject = (user) => {
        // Format the meta to get unreadMessages
        if (user && user.ticketMeta) {
            user.ticketMeta.map(meta => {
                if (typeof(user[meta.ticketId]) == 'undefined') {
                    user[meta.ticketId] = {};
                }
                user[meta.ticketId][meta.type] = meta.value;
            });
        }
        setUser(user);
    }

    api.interceptors.request.use(
        config => {
            const token = localStorage.getItem("token");
            if (token) {
                config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
                setIsAuth(true);
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalRequest = error.config;
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;

                const { data } = await api.post("/auth/refresh_token");
                if (data) {
                    localStorage.setItem("token", JSON.stringify(data.token));
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                }
                return api(originalRequest);
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem("token");
                api.defaults.headers.Authorization = undefined;
                setIsAuth(false);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const token = localStorage.getItem("token");
        (async () => {
            if (token) {
                try {
                    const { data } = await api.post("/auth/refresh_token");
                    api.defaults.headers.Authorization = `Bearer ${data.token}`;
                    setIsAuth(true);
                    setUserObject(data.user);
                } catch (err) {
                    toastError(err);
                }
            }
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const userEvent = data => {
            if (data.action === "update" && data.user.id === user.id) {
                setUserObject(data.user);
            }
        }

        const userLogout = data => {
            handleLogout();
        }

        socket.on("user", userEvent);
        socket.on("logout", userLogout);

        return () => {
            socket.off('user', userEvent);
            socket.off('logout', userLogout);
        };
    }, [user]);

    const handleLogin = async userData => {
        setLoading(true);

        try {
            const { data } = await api.post("/auth/login", userData);
            localStorage.setItem("token", JSON.stringify(data.token));
            localStorage.setItem("public-token", data.user.token);
            localStorage.setItem("user-id", data.user.id);
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setUserObject(data.user);
            setIsAuth(true);
            toast.success(i18n.t("auth.toasts.success"));
            history.push("/tickets");
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        setLoading(true);

        try {
            await api.delete("/auth/logout");
            setIsAuth(false);
            setUser({});
            localStorage.removeItem("token");
            localStorage.removeItem("user-id");
            localStorage.removeItem("public-token");
            cleanLocalOptions();
            api.defaults.headers.Authorization = undefined;
            setLoading(false);
            history.push("/login");
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    return { isAuth, user, loading, handleLogin, handleLogout };
};

export default useAuth;
