import React from "react";
import { useEffect, useState} from "react";
import AsyncSelect from "../AsyncSelect";
import { FormControl } from "@material-ui/core";

const SelectContacts = ({onChange, selectedContacts, disabled}) => {
    const [internalSelectedContacts, setInternalSelectedContacts] = useState([]);

    const handleChange = (event, changedContacts) => {
        setInternalSelectedContacts(changedContacts);
        if (onChange) {
            onChange(changedContacts);
        }
    };

    return (
        <FormControl fullWidth variant="outlined">
            <AsyncSelect
                multiple
                width="100%"
                onChange={handleChange}
                disabled={disabled}
                initialValue={selectedContacts}
                url="/contacts"
                dictKey="contacts"
                value={internalSelectedContacts}
                className="basic-multi-select"
                placeholder="Selecione os contatos..."
                label="Contatos"
                />
        </FormControl>
    );
};

export default SelectContacts;