import React, { useEffect, useState } from "react"

const DashboardFeedbackVisual = ({rate, className}) => {
    const [positive, setPositive] = useState(false);
    const [rateNumber, setRateNumber] = useState(0);

    const emojis = {
        '5': {emoji: '🥰', label: 'Muito Satisfeito'},
        '4': {emoji: '😀', label: 'Satisfeito'},
        '3': {emoji: '😐', label: 'Indiferente'},
        '2': {emoji: '😤', label: 'Insatisfeito'},
        '1': {emoji: '😡', label: 'Muito Insatisfeito'}
    }

    useEffect(() => {
        const rateInteger = Math.round(rate);
        setRateNumber(rateInteger);
        if (rate >= rateInteger) {
            setPositive(true);
        } else {
            setPositive(false);
        }
    }, [rate]);

    const getRateEmoji = () => {
        if (rateNumber) {
            const element = emojis[rateNumber];
            return element.emoji;
        }
        return '🚫​';
    }

    return <span className={className}>{getRateEmoji()}</span>
}

export default DashboardFeedbackVisual;