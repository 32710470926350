import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		//padding: theme.spacing(4),
		height: '100%',
		overflowY: "hidden",
	},

	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
		transition: 'all ease 0.5s'
	},
	contactsWrapperSmall: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
		[theme.breakpoints.down("sm")]: {
			display:"none"
		}
	},
	messagessWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: theme.palette.fancyBackground,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
		borderRadius:0,
	},
	ticketsManager: {

	},
	ticketsManagerClosed: {
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	}
}));

const Chat = () => {
	const classes = useStyles();
	const { ticketId } = useParams();
	const [fullscreen, setFullscreen] = useState(false);


	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					
					<Grid item xs={!fullscreen ? 12:0} md={!fullscreen ? 4:0} style={fullscreen ? {width: 0}:{width: 'auto'}} className={ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper}>
						<TicketsManager />
					</Grid>
					
					<Grid item xs={12} md={!fullscreen ? 8:12} className={classes.messagessWrapper}>
						{ticketId ? (
							<>
								<Ticket setFullscreen={setFullscreen} fullscreen={fullscreen} />
							</>
						) : (
							<Hidden only={['sm', 'xs']}>
								<Paper className={classes.welcomeMsg}>
									<span>{i18n.t("chat.noTicketMessage")}</span>
								</Paper>
							</Hidden>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Chat;
