const messages = {
	en: {
		translations: {
			ticket: "Ticket",
			signup: {
				title: "Sign up",
				toasts: {
					success: "User created successfully! Please login!",
					fail: "Error creating user. Check the reported data.",
				},
				form: {
					name: "Name",
					email: "Email",
					password: "Password",
				},
				buttons: {
					submit: "Register",
					login: "Already have an account? Log in!",
				},
			},
			login: {
				title: "Login",
				form: {
					email: "Email",
					password: "Password",
				},
				buttons: {
					submit: "Enter",
					register: "Don't have an account? Register!",
				},
			},
			auth: {
				toasts: {
					success: "Login successfully!",
				},
			},
			dashboard: {
				charts: {
					perDay: {
						title: "Tickets today: ",
					},
				},
			},
			connections: {
				title: "Connections",
				toasts: {
					deleted: "WhatsApp connection deleted sucessfully!",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage: "Are you sure? It cannot be reverted.",
					disconnectTitle: "Disconnect",
					disconnectMessage: "Are you sure? You'll need to read QR Code again.",
				},
				buttons: {
					add: "Add Connection",
					disconnect: "Disconnect",
					tryAgain: "Try Again",
					qrcode: "QR CODE",
					newQr: "New QR CODE",
					connecting: "Connecting",
					reset: "Reset Connection",
                    restartServer: "Restart Server",
					authenticate: "Authenticate"
				},
				toolTips: {
					disconnected: {
						title: "Failed to start WhatsApp session",
						content:
							"Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
					},
					qrcode: {
						title: "Waiting for QR Code read",
						content:
							"Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
					},
					connected: {
						title: "Connection established",
					},
					timeout: {
						title: "Connection with cell phone has been lost",
						content:
							"Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
					},
				},
				table: {
					name: "Name",
					status: "Status",
					lastUpdate: "Last Update",
					default: "Default",
					actions: "Actions",
					session: "Session",
					type: "Type"
				},
			},
			whatsappModal: {
				title: {
					add: "Add Connection",
					edit: "Edit Connection",
				},
				form: {
					name: "Name",
					default: "Default",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "WhatsApp saved successfully.",
			},
			qrCode: {
				message: "Read QrCode to start the session",
			},
			contacts: {
				title: "Contacts",
				toasts: {
					deleted: "Contact deleted sucessfully!",
					synced: "Successfully synced contact!",
				},
				searchPlaceholder: "Search ...",
				confirmationModal: {
					deleteTitle: "Delete",
					importTitlte: "Import contacts",
					deleteMessage:
						"Are you sure you want to delete this contact? All related tickets will be lost.",
					importMessage: "Do you want to import all contacts from the phone?",
				},
				buttons: {
					import: "Import Contacts",
					add: "Add Contact",
					addTags: "Add Tags",
					export: "Export",
					deleteContacts: "Delete selected contacts"
				},
				table: {
					name: "Name",
					whatsapp: "ID",
					email: "Email",
					actions: "Actions",
					tags: "Tags",
					user: "User"
				},
			},
			shortcut_messages: {
				title: "Shortcut Messages",
				toasts: {
					deleted: "Shortcut message deleted!",
				},
				searchPlaceholder: "Search...",
				confirmationModal: {
					deleteTitle: "Delete ",
					deleteMessage:
						"Are you sure you want to delete this shortcut message?",
				},
				buttons: {
					add: "Add",
					import: "Import",
                    export: "Export"
				},
				table: {
					name: "Name",
					content: "Content",
					queue: "Queues",
					actions: "Actions",
				},
			},
			scheduled_messages: {
				title: "Scheduled Messages",
				toasts: {
					deleted: "Scheduled message deleted!",
				},
				searchPlaceholder: "Search...",
				confirmationModal: {
					deleteTitle: "Delete ",
					deleteMessage:
						"Are you sure you want to delete this scheduled message?",
				},
				buttons: {
					add: "Add scheduled message",
				},
				table: {
					date: "Schedule to",
					content: "Content",
					sent: "Sent at",
					actions: "Actions",
					to: "To"
				},
			},
			tags: {
				title: "Tags",
				toasts: {
					deleted: "Tag deleted!",
				},
				searchPlaceholder: "Search...",
				confirmationModal: {
					deleteTitle: "Delete ",
					deleteMessage:
						"Are you sure you want delete this tag?",
				},
				buttons: {
					add: "Add Tag",
				},
				table: {
					name: "Name",
					content: "Content",
					actions: "Actions",
					color: "Color",
					contacts: "Contact number"
				},
			},
			addNoteModal:{
				title: {
					add: "Add note",
					edit: "Edit note",
				},
				form: {
					mainInfo: "Contact details",
					syncInfo: "Do you want to sync the contact?",
					extraInfo: "Additional information",
					title: "Title",
					content: "Message",
					number: "Whatsapp number",
					email: "Email",
					extraName: "Field name",
					extraValue: "Value",
				},
				buttons: {
					addExtraInfo: "Add information",
					okAdd: "Add",
					okEdit: "Save",
					okSync: "Synchronize",
					cancel: "Cancel",
				},
				success: "Contact saved successfully.",	
			},
			contactModal: {
				title: {
					add: "Add contact",
					edit: "Edit contact",
				},
				form: {
					mainInfo: "Contact details",
					syncInfo: "Do you want to sync the contact?",
					extraInfo: "Additional information",
					name: "Name",
					number: "Whatsapp number",
					email: "Email",
					extraName: "Field name",
					extraValue: "Value",
				},
				buttons: {
					addExtraInfo: "Add information",
					okAdd: "Add",
					okEdit: "Save",
					okSync: "Synchronize",
					cancel: "Cancel",
				},
				success: "Contact saved successfully.",
			},
			tagModal: {
				title: {
					add: "Add tag",
					edit: "Edit tag",
				},
				form: {
					mainInfo: "Tag data",
					name: "Name",
					color: "Color"
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "Tag saved.",
			},
			shortcutMessageModal: {
				title: {
					add: "Add shortcut message",
					edit: "Edit shortcut message",
				},
				form: {
					mainInfo: "Shortcut message data",
					extraInfo: "Aditional info",
					name: "Name",
					content: "Content",
				},
				buttons: {
					addExtraInfo: "Add information",
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "Shortcut message saved.",
			},
			scheduledMessageModal: {
				title: {
					add: "Add scheduled message",
					edit: "Edit scheduled message",
				},
				form: {
					mainInfo: "Scheduled message data",
					extraInfo: "Aditional info",
					date: "Schedule to:",
					content: "Content",
					contactId: "Contact"
				},
				buttons: {
					addExtraInfo: "Add info",
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "Scheduled message saved.",
			},
			queueModal: {
				title: {
					add: "Add queue",
					edit: "Edit queue",
				},
				form: {
					name: "Name",
					color: "Color",
					greetingMessage: "Greeting Message",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
			},
			userModal: {
				title: {
					add: "Add user",
					edit: "Edit user",
				},
				form: {
					name: "Name",
					email: "Email",
					password: "Password",
					profile: "Profile",
				},
				buttons: {
					okAdd: "Add",
					okEdit: "Save",
					cancel: "Cancel",
				},
				success: "User saved successfully.",
			},
			webhookModal: {
                title: {
                    add: "Add webhook",
                    edit: "Edit webhook",
                },
                form: {
                    name: "Name",
                    url: "URL",
                    urlType: "Request Type",
                    headerString: "Custom Header (string, optional)",
                    type: "Type",
                    sendEveryMinutes: "Send request each X minutes (choose some value between 60 and 1440)"
                },
                buttons: {
                    okAdd: "Add",
                    okEdit: "Save",
                    cancel: "Cancel",
                },
                success: "Webhook saved.",
            },
			integrationIframeModal: {
                title: {
                    add: "Add iframe",
                    edit: "Edit iframe",
                },
                form: {
                    name: "Nome",
                    url: "URL"
                },
                buttons: {
                    okAdd: "Add",
                    okEdit: "Save",
                    cancel: "Cancel",
                },
                success: "Iframe saved.",
            },
			iframes: {
                title: "Iframes (Beta)",
                table: {
                    name: "Name",
                    url: "URL",
                    active: "Active",
                    actions: "Actions"
                },
                searchPlaceholder: "Search",
                buttons: {
                    add: "Add iframe",
                },
                toasts: {
                    deleted: "Iframe deleted",
                },
                confirmationModal: {
                    deleteTitle: "Delete",
                    deleteMessage:
                        "Are you sure you want to delete this iframe?",
                },
            },
			chat: {
				noTicketMessage: "Select a ticket to start chatting.",
			},
			ticketsManager: {
				buttons: {
					newTicket: "New",
				},
			},
			ticketsQueueSelect: {
				placeholder: "Queues",
			},
			tickets: {
				toasts: {
					deleted: "The ticket you were on has been deleted.",
				},
				notification: {
					message: "Message from",
				},
				tabs: {
					open: { title: "Inbox" },
					closed: { title: "Resolved" },
					search: { title: "Search" },
					paused: { title: "Paused" },
				},
				search: {
					placeholder: "Search tickets and messages.",
					ticketId: "Ticket ID: example 15",
					connections: "Connections",
					users: "Users",
					from: "From",
					to: "To",

					unanswered: "Unanswered messages",
					unansweredDisabled: "Disabled",
					unansweredClient: "All unanswered messages by client",
					unansweredMe: "All unanswered messages by me",
					unansweredTimeClient: "Unanswered messages by client in a period of time",
					unansweredTimeMe: "Unanswered messages by me in a period of time",



					selectedConnections: "connection(s) selected(s)",
					selectedUsers: "user(s) selected(s)",
					selectedTags: "tag(s) selected(s)",
					selectedQueues: "queue(s) selected(s)",
					
				},
				buttons: {
					showAll: "All",
					sortAscending: "Sort oldest",
					clear: "Clear",
					search: "Search"
				},
				status: {
					open: "Chating",
					closed: "Closed",
					bot: "Automated",
					feedback: "Feedback",
					pending: "Waiting",
					group: "Groups"
				}
			},
			transferTicketModal: {
				title: "Transfer Ticket",
				fieldLabel: "Type to search for users",
				noOptions: "No user found with this name",
				buttons: {
					ok: "Transfer",
					cancel: "Cancel",
				},
			},
			resolveTicketModal: {
				title: "End Ticket",
				content: "Do you want to end this service and send the satisfaction survey, or just end the service?",
				contentAutoFeedback: "Are you sure you want to end this service and send the satisfaction survey?",
				contentEndOnly: "Are you sure you want to end the call?",
				buttons: {
					accept: "Finish only",
					feedback: "Finish and send Satisfaction Survey",
					cancel: "Cancel",
					end: "Finish",
					endWithoutMessage: "Finish without any message"
				}
			},
			ticketsList: {
				pendingHeader: "Queue",
				assignedHeader: "Working on",
				noTicketsTitle: "Nothing here!",
				noTicketsMessage: "No tickets found with this status or search term.",
				buttons: {
					accept: "Accept",
				},
			},
			newTicketModal: {
				title: "Create Ticket",
				fieldLabel: "Type to search for a contact",
				add: "Add",
				buttons: {
					ok: "Save",
					cancel: "Cancel",
				},
			},
			mainDrawer: {
				listItems: {
					dashboard: "Dashboard",
					connections: "Connections",
					tickets: "Tickets",
					contacts: "Contacts",
					queues: "Queues",
					administration: "Administration",
					users: "Users",
					settings: "Settings",
					shortcut_messages: "Messages",
					scheduled_messages: "Appointments",
					tags: "Tags",
					bots: "Bots"
				},
				appBar: {
					user: {
						profile: "Profile",
						logout: "Logout",
					},
				},
			},
			notifications: {
				noTickets: "No notifications.",
			},
			queues: {
				title: "Queues",
				table: {
					name: "Name",
					color: "Color",
					greeting: "Greeting message",
					actions: "Actions",
				},
				buttons: {
					add: "Add queue",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage:
						"Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
				},
			},
			queueSelect: {
				inputLabel: "Queues",
			},
			users: {
				title: "Users",
				table: {
					name: "Name",
					email: "Email",
					profile: "Profile",
					actions: "Actions",
					status: "Status"
				},
				buttons: {
					add: "Add user",
				},
				toasts: {
					deleted: "User deleted sucessfully.",
				},
				confirmationModal: {
					deleteTitle: "Delete",
					deleteMessage:
						"All user data will be lost. Users' open tickets will be moved to queue.",
				},
			},
			settings: {
				success: "Settings saved successfully.",
				title: "Settings",
				tabs: {
					general: "General",
					appointments: "Appointments",
					internal_chat: "Internal Chat",
					integration: "Integration",
					actions_by_batch: "Actions by Batch",						
				},	
				settings: {
					notificationSound: {
						name: "General notification sound",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
					backgroundChat: {
						name: "Background chat",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
					chatNotificationSound: {
						name: "Message notification sound",
						options: {
							enabled: "Habilitado",
							disabled: "Deshabilitado",
						},
					},
					userCreation: {
						name: "User creation",
						options: {
							enabled: "Enabled",
							disabled: "Disabled",
						},
					},
					groupMedia: {
						name: "Group media (images and videos)",
						options: {
							enabled: "Enabled",
							disabled: "Disabled"
						}
					},
					showContacts: {
						name: "Show Contacts section to all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showTags: {
						name: "Show Tags Section to all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showConnections: {
						name: "Show Connection Section to all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showResolved: {
						name: "Show resolved Tickets for all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showWaiting: {
						name: "Show Waiting for Tickets for All",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
                    showDeletedMessages: {
						name: "Show deleted messages",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					showDashboard: {
						name: "Show dashboard section for all",
						options: {
							enabled: "Enabled",
							disabled: "Disabled (only administrator)"
						}
					},
					language: {
						name: "Language",
						options: {
							english: "English",
							spanish: "Spanish",
							portuguese: "Portuguese"
						}
					}
				},
			},
			messagesList: {
				header: {
					assignedTo: "Assigned to:",
					buttons: {						
						return: "Return",
						resolve: "Resolve",
						reopen: "Reopen",
						accept: "Accept",
						pause: "Pause",
						unpause: "Unpause",
					},
				},
			},
			messages: {
				"[CONTACT]": "[Contato]",
				"[LOCALIZATION]": "[Localização]",
			},
			messagesInput: {
				placeholderOpen: "Type a message",
				placeholderClosed: "Reopen or accept this ticket to send a message.",
				signMessage: "Sign",
			},
			contactDrawer: {
				header: "Contact details",
				buttons: {
					edit: "Edit contact",
				},
				extraInfo: "Other information",
			},
			ticketOptionsMenu: {
				delete: "Delete",
				transfer: "Transfer",
				confirmationModal: {
					title: "Delete ticket #",
					titleFrom: "from contact ",
					message: "Attention! All ticket's related messages will be lost.",
				},
				buttons: {
					delete: "Delete",
					cancel: "Cancel",
				},
			},
			confirmationModal: {
				buttons: {
					confirm: "Ok",
					cancel: "Cancel",
				},
			},
			messageOptionsMenu: {
				delete: "Delete",
				forward: "Forward",
				reply: "Reply",
				create_note: "Add Note",
				confirmationModal: {
					title: "Delete message?",
					message: "This action cannot be reverted.",
				},
				download_all: "Download All",
				download_zip: "Download Zip"
			},
			instagram: {
                mentionedStory: "% mentioned you in their story",
                mentionedStoryBy: "You mentioned %s in your story"
            },
			backendErrors: {
				ERR_NO_OTHER_WHATSAPP:
					"There must be at lest one default WhatsApp connection.",
				ERR_NO_DEF_WAPP_FOUND:
					"No default WhatsApp found. Check connections page.",
				ERR_WAPP_NOT_INITIALIZED:
					"This WhatsApp session is not initialized. Check connections page.",
				ERR_WAPP_CHECK_CONTACT:
					"Could not check WhatsApp contact. Check connections page.",
				ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
				ERR_WAPP_DOWNLOAD_MEDIA:
					"Could not download media from WhatsApp. Check connections page.",
				ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
				ERR_USER_DISABLED: "User disabled.",
				ERR_SENDING_WAPP_MSG:
					"Error sending WhatsApp message. Check connections page.",
				ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
				ERR_OTHER_OPEN_TICKET: "There's already an open ticket for this contact. The ticket is with {{ errorData.userName }} in the queue {{ errorData.queueName }}",
				ERR_OPEN_MORE_TICKETS_THAN_LIMIT: "Você não pode aceitar mais atendimentos no momento",
				ERR_SESSION_EXPIRED: "Session expired. Please login.",
				ERR_USER_CREATION_DISABLED:
					"User creation was disabled by administrator.",
				ERR_NO_PERMISSION: "You don't have permission to access this resource.",
				ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
				ERR_NO_SETTING_FOUND: "No setting found with this ID.",
				ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
				ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
				ERR_NO_USER_FOUND: "No user found with this ID.",
				ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
				ERR_CREATING_MESSAGE: "Error while creating message on database.",
				ERR_CREATING_TICKET: "Error while creating ticket on database.",
				ERR_FETCH_WAPP_MSG:
					"Error fetching the message in WhtasApp, maybe it is too old.",
				ERR_QUEUE_COLOR_ALREADY_EXISTS:
					"This color is already in use, pick another one.",
				ERR_WAPP_GREETING_REQUIRED:
					"Greeting message is required if there is more than one queue.",
				ERR_FILE_TOO_BIG:
					"Whatsapp support send files at max of 16MB.",
			},
		},
	},
};

export { messages };
