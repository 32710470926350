

import React, {useContext} from "react";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";

const SettingSoundSelectionField = ({ name, value, label, helpText, userSettings, userId }) => {
    const { handleChangeSetting } = useContext(UserSettingsContext);


    const playSound = (sound) => {
        const audio = new Audio(`/notificationSounds/${sound}.mp3`);
        audio.play();
    }

    const handlePlaySound = (e, sound) => {
        e.stopPropagation();
        e.preventDefault();
        playSound(sound);
    }   

    const handleChange = event => {
        const newValue = event.target.value;
        handleChangeSetting(name, newValue);
      };

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select placeholder={label} value={value} onChange={handleChange}>
                <MenuItem value="default"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'default') }}><PlayArrow /></IconButton> Padrão</MenuItem>
                {/* <MenuItem value="silence"><IconButton size="small" onClick={(e) => {handlePlaySound(e, 'silence')}}><PlayArrow /></IconButton> Silecioso</MenuItem> */}
                <MenuItem value="eventually"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'eventually') }}><PlayArrow /></IconButton> Eventually"</MenuItem>
                <MenuItem value="magic"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'magic') }}><PlayArrow /></IconButton> Magic</MenuItem>
                <MenuItem value="make-it-possible"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'make-it-possible') }}><PlayArrow /></IconButton> I'll make it possible</MenuItem>
                <MenuItem value="message-with-intro"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'message-with-intro') }}><PlayArrow /></IconButton> Message with intro</MenuItem>
                <MenuItem value="msn"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'msn') }}><PlayArrow /></IconButton> MSN</MenuItem>
                <MenuItem value="piece-of-cake"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'piece-of-cake') }}><PlayArrow /></IconButton> Piece of Cake</MenuItem>
                <MenuItem value="upset-sound-tone"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'upset-sound-tone') }}><PlayArrow /></IconButton> Upset</MenuItem>
                <MenuItem value="when"><IconButton size="small" onClick={(e) => { handlePlaySound(e, 'when') }}><PlayArrow /></IconButton> When?</MenuItem>
            </Select>
        </FormControl>
    )
}

export default SettingSoundSelectionField; 