import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import getSocket from "../../helpers/socket.js";
import clsx from "clsx";
import { i18n } from "../../translate/i18n.js";

import { green } from "@material-ui/core/colors";
import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import {
    AccessTime,
    AttachFile,
    Block,
    Check,
    Close,
    Delete,
    Done,
    DoneAll,
    ExpandMore,
    GetApp
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/bg-wp-transparent-20.png";
import ModalMediaNavigator from "../ModalMediaNavigator";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import { messages } from "../../translate/languages/pt";
import { SettingsContext } from "../../context/Settings/SettingsContext.js";
import Swal from "sweetalert2";
import { downloadResource } from "../../utils.js";
import { useHistory, useLocation } from "react-router-dom";
import { UserSettingsContext } from "../../context/Settings/UserSettingsContext";
import MessageLocation from "../Messages/location.js";
import MessageContact from "../Messages/contact.js";
import MessageDocument from "../Messages/document.js";
import MessageAudio from "../Messages/audio.js";
import MessageCiphertext from "../Messages/ciphertext.js";
import MessageUnsupported from "../Messages/unsupported.js";
import MessageError from "../Messages/error.js";

const useStyles = makeStyles(theme => ({
    messagesListWrapper: {
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },

    messagesList: {
        backgroundImage: theme.mode === 'light' ? `url(${whatsBackground})` : `url(${whatsBackgroundDark})`,
        backgroundPosition: 'center center',
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "20px 20px 20px 20px",
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "90px"
        },
        ...theme.scrollbarStylesSoftBig,
    },

    dragElement: {
        background: 'rgba(255, 255, 255, 0.8)',
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 999999,
        textAlign: "center",
        fontSize: "3em",
        border: "5px dashed #333",
        color: '#333',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    circleLoading: {
        color: green[500],
        position: "absolute",
        opacity: "70%",
        top: 0,
        left: "50%",
        marginTop: 12,
    },

    messageLeft: {
        marginRight: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#ffffff",
        color: "#303030",
        alignSelf: "flex-start",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },
    messageLocation: {
        padding: 5
    },

    messageContact: {

    },

    quotedContainerLeft: {
        overflow: "hidden",
        backgroundColor: "#f0f0f0",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    quotedMsg: {
        padding: 10,
        maxWidth: 300,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    quotedSideColorLeft: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },

    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#dcf8c6",
        color: "#303030",
        alignSelf: "flex-end",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },

    quotedContainerRight: {
        overflowY: "hidden",
        backgroundColor: "#cfe9ba",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    quotedMsgRight: {
        padding: 10,
        maxWidth: 300,
        height: "auto",
        whiteSpace: "pre-wrap",
    },

    quotedSideColorRight: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },

    messageActionsButton: {
        display: "none",
        position: "relative",
        color: "#999",
        zIndex: 1,
        backgroundColor: "inherit",
        opacity: "90%",
        "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },

    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    textContentItemEdited: {
        padding: "3px 96px 6px 6px",
    },

    textContentItemDeleted: {
        fontStyle: "italic",
        color: "rgba(0, 0, 0, 0.36)",
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    locationTextContentItem: {
        padding: 0
    },
    contactTextContentItem: {
        padding: 8,
        minWidth: 300
    },
    audioTextContentItem: {
        marginTop: -30
    },

    messageMedia: {
        objectFit: "contain",
        width: 250,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    timestamp: {
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        right: 5,
        color: "#999",
    },

    locationTimestamp: {
        color: '#FFF',
        background: 'rgba(10, 10, 10, 0.5)',
        padding: '1px',
        paddingLeft: '4px',
        paddingRight: '4px',
        borderRadius: '5px',
        marginBottom: '5px'
    },
    audioTimestamp: {
        
    },

    dailyTimestamp: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "110px",
        backgroundColor: "#e1f3fb",
        margin: "10px",
        borderRadius: "10px",
        boxShadow: "0 1px 1px #b3b3b3",
    },

    dailyTimestampText: {
        color: "#808888",
        padding: 8,
        alignSelf: "center",
        marginLeft: "0px",
    },

    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },

    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },

    ackDoneAllIcon: {
        color: green[500],
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },

    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },
    imageGroup: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: 250,
        marginRight: -5
    },
    imageGroupItem: {
        cursor: "pointer",
        width: 120,
        height: 120,
        overflow: "hidden",
        borderRadius: "5px",
        marginBottom: "5px",
        marginRight: 5,
        "& img": {
            objectFit: "cover",
            width: "100%",
            height: "100%"
        }
    },
    imageGroupItemLast: {
        position: "relative",
        cursor: "pointer",
        background: "#000",
        width: 120,
        height: 120,
        overflow: "hidden",
        marginBottom: "5px",
        marginRight: 5,
        borderRadius: 5,
        "& div": {
            opacity: 0.7,
            borderRadius: "5px",
            height: "100%",
            width: "100%",
            "& img": {
                objectFit: "cover",
                width: "100%",
                height: "100%"
            }
        },
        "& span": {
            position: "absolute",
            color: "#FFF",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "1.9em"
        },

    },
    noteBlock: {
        backgroundColor: '#dddddd',
        display: 'flex-inline',
        maxWidth: 300,
        margin: '10px 0px',
        color: '#000000',
        borderRadius: 10,
        boxShadow: 'inset 0px -5px 0px 0px rgba(0,0,0,0.1)',
        position: 'relative'
    },
    arrowTop: {
        content: " ",
        position: 'absolute',
        color: '#dddddd',
        top: -15,
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
    },
    noteHeader: {
        padding: '10px 10px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    noteHeaderTitle: {
        fontSize: 14
    },
    DeleteIcon: {
        color: 'rgba(0,0,0,0.8)',
        cursor: 'pointer',
        fontSize: 15
    },
    noteContent: {
        padding: '10px 10px',

    },
    linkFile: {
        color: '#4f4f4f',
        borderTop: '1px solid rgba(0,0,0,0.1)',
        padding: '5px 10px 10px 10px',
        marginTop: 0,
        fontSize: 12,
        fontStyle: 'italic',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    ciphertext: {
        color: "#666"
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach(message => {
            const messageIndex = state.findIndex(m => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...newMessages, ...state];
    }

    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const messageIndex = state.findIndex(m => m.id === newMessage.id);

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    }

    if (action.type === "UPDATE_MESSAGE") {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex(m => m.id === messageToUpdate.id);

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === "DELETE_MESSAGE") {
        const messageId = action.payload;
        const messageIndex = state.findIndex(m => m.id === messageId);

        if (messageIndex !== -1) {
            state.splice(messageIndex, 1);
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const MessagesList = ({ ticketId, isGroup, status, onDrop, user }) => {
    const targetDivRef = useRef(null);
    const location = useLocation();

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const classes = useStyles();
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    const history = useHistory();

    var forceScroll = false;


    const [messagesList, dispatch] = useReducer(reducer, []);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [keyControl, setKeyControl] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [disableAnchor, setDisableAnchor] = useState(true);
    const [checkFileEnabled, setCheckFileEnabled] = useState(false);
    const lastMessageRef = useRef();
    const [messageNotes, setMessageNotes] = useState([]);

    const [selectedMessage, setSelectedMessage] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const messageOptionsMenuOpen = Boolean(anchorEl);
    const currentTicketId = useRef(ticketId);

    const [showModalMediaNavigator, setShowModalMediaNavigator] = useState({});

    const [dragActive, setDragActive] = useState(false);

    const { settings, handleChangeSetting } = useContext(UserSettingsContext);

    const { isActive, getSettingValue, hashKey } = useContext(SettingsContext);


    //Go to tickets bro!

    useEffect(() => {
        // Get the messageId from the query parameter in the URL
        const queryParams = new URLSearchParams(location.search);
        const messageId = queryParams.get('message');
    
        // Scroll to the target div when the component mounts
        scrollToTargetDiv(messageId);

        // Add an event listener to handle scrolling when messageId changes in the URL
        return history.listen((location) => {
          const newQueryParams = new URLSearchParams(location.search);
          const newMessageId = newQueryParams.get('message');
          if (newMessageId !== messageId) {
            scrollToTargetDiv(newMessageId);
          }
        });
      }, [history, location]);

    const scrollToTargetDiv = (messageId) => {
        if (messageId) {
            console.log('it got here', messageId)
            const targetDiv = document.getElementById(messageId);
            if (targetDiv) {
                // Use any logic necessary to determine the position of the target div
                // and scroll to it, e.g., using scrollIntoView
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    window.updateMessagesList = () => {
        setKeyControl(keyControl + 1);
    }

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);

        currentTicketId.current = ticketId;
    }, [ticketId, keyControl]);

    useEffect(() => {
        setDisableAnchor(!isActive('showLinks'));
        setCheckFileEnabled(isActive("checkVirus"));
    }, [hashKey]);


    const fetchMessages = async () => {
        try {
            const { data } = await api.get("/messages/" + ticketId, {
                params: { pageNumber },
            });

            if (currentTicketId.current === ticketId) {
                dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                setHasMore(data.hasMore);
                setMessageNotes(data.messageNotes);
                setLoading(false);
            }

            if ((pageNumber === 1 || forceScroll) && data.messages.length > 1) {
                scrollToBottom();
            }
        } catch (err) {
            setLoading(false);
            history.push(`/tickets`);
            toastError(err);
        }
    };


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchMessages();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [pageNumber, ticketId, status, keyControl]);

    useEffect(() => {
        const socket = getSocket(true);

        const connectEvent = () => {
            socket.emit("joinChatBox", ticketId)
        }

        const appMessageEvent = data => {
            if (data.action === "create") {
                dispatch({ type: "ADD_MESSAGE", payload: data.message });
                scrollToBottom();
            }

            if (data.action === "update") {
                dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
            }

            if (data.action == "delete") {
                dispatch({ type: "DELETE_MESSAGE", payload: data.messageId });
            }
        }

        socket.on("connect", connectEvent);
        socket.on("appMessage", appMessageEvent);

        if (socket.connected) {
            connectEvent();
        }

        return () => {
            /*socket.emit("leaveChatBox", ticketId);
            socket.off("connect", connectEvent);
            socket.off("appMessage", appMessageEvent);*/
            socket.disconnect();
        };
    }, [ticketId]);

    let pageNumberInterval = null;

    const loadMore = (force) => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            forceScroll = force;
            setPageNumber(prevPageNumber => prevPageNumber + 1);
            clearInterval(pageNumberInterval);
        }, 100);
    };

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({});
        }
    };

    const handleScroll = e => {
        if (!hasMore) return;
        const { scrollTop } = e.currentTarget;

        if (scrollTop === 0) {
            document.getElementById("messagesList").scrollTop = 1;
        }

        if (loading) {
            return;
        }

        if (scrollTop < 50) {
            loadMore(false);
        }
    };

    const handleOpenMessageOptionsMenu = (e, message) => {
        setAnchorEl(e.currentTarget);
        setSelectedMessage(message);
    };

    const handleCloseMessageOptionsMenu = (e) => {
        setAnchorEl(null);

    };

    const handleNoteCreated = async (e) => {
       // console.log('foi',e);
        fetchMessages();
    };


    const backgroundImageUrl =
        settings &&
            settings.backgroundChat &&
            settings.backgroundChat !== 'default'
            ? settings.backgroundChat.startsWith('bg')
                ? `/backgrounds/${settings.backgroundChat}`
                : settings.backgroundChat.startsWith('pattern')
                    ? `/patterns/${settings.backgroundChat}`
                    : `https://api-mydev.onecode.chat/public/app/${settings.backgroundChat}` : '';

    const backgroundStyle =
        settings &&
            settings.backgroundChat &&
            settings.backgroundChat !== 'default'
            ? { backgroundImage: `url(${backgroundImageUrl}?v=${Date.now()})` }
            : {};

    const renderBodyText = (message) => {
        return message.body;
    }

    const renderVCard = message => {
        return <MessageContact message={message} />;
    }

    const openMediaNavigator = index => {
        var dict = {};
        dict[index] = true;
        setShowModalMediaNavigator(dict);
    }

    const closeModalMediaNavigator = index => {
        var dict = {};
        dict[index] = false;
        setShowModalMediaNavigator(dict);
    }

    const renderImageGroup = (message, messagesIndex) => {
        var moreItems = 1 + message.childs.length - 3;
        return (
            <div className={classes.imageGroup}>
                <div key={messagesIndex} onClick={() => openMediaNavigator(messagesIndex)} className={classes.imageGroupItem}>
                    <img src={message.mediaUrl} />
                </div>
                {message.childs && message.childs.slice(0, 3).map((message, index) => {
                    return (
                        index < 2 ?
                            <div key={index} onClick={() => openMediaNavigator(messagesIndex)} className={classes.imageGroupItem}>
                                <img src={message.mediaUrl} />
                            </div> :
                            <div key={index} className={classes.imageGroupItemLast} onClick={() => openMediaNavigator(messagesIndex)}>
                                <div><img src={message.mediaUrl} /></div>
                                <span>+{moreItems}</span>
                            </div>
                    )
                })}
            </div>
        );
    }

    const handleDownloadMedia = (mediaUrl) => {
        setDownloading(mediaUrl);
        if (isActive('showDownloadConfirmation')) {
            Swal.fire({
                title: 'Deseja fazer o download desse arquivo?',
                text: "Tem certeza que deseja baixar o arquivo para seu PC?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (isActive('forceDownload')) {
                        downloadResource(mediaUrl);
                    } else {
                        window.open(mediaUrl, "_blank");
                    }
                }
                setDownloading(null);
            });
        } else {
            downloadResource(mediaUrl);
            setDownloading(null);
        }
    }

    const handleFileAnalysis = async (message) => {
        if (message.fileAnalysisId && message.fileAnalysisResultSimple) {
            if (message.fileAnalysisResultSimple === "harmless" || message.fileAnalysisResultSimple === "undetected") {
                handleDownloadMedia(message.mediaUrl);
            }
        } else {
            try {
                if (!message.fileAnalysisId || !message.fileAnalysisResultSimple) {
                    await api.get(`/messages/${message.id}/check`);
                } else {
                    toastError("A verificação de vírus já foi iniciada para esse arquivo");
                }
            } catch (err) {
                setLoading(false);
                toastError(err);
            }
        }
    }

    const renderLabelFileAnalysis = (message) => {
        if (message.fileAnalysisResultSimple === "malicious") {
            return <span style={{ color: "red" }}><Close /> Arquivo malicioso</span>;
        }
        if (message.fileAnalysisResultSimple === "suspicious") {
            return <span style={{ color: "orange" }}><Close /> Arquivo suspeito</span>;
        }
        if (message.fileAnalysisResultSimple === "harmless" || message.fileAnalysisResultSimple === "undetected") {
            return <span style={{ color: "green" }}><Check /> Nenhuma ameaça encontrada (clique para fazer o download)</span>;
        }
        return <span>Não foi possível verificar o arquivo por vírus</span>
    }

    const renderFileAnalysis = (message) => {
        return <Button
            color="primary"
            onClick={() => handleFileAnalysis(message)}
            disabled={["malicious", "suspicious"].indexOf(message.fileAnalysisResultSimple) > -1}
        >
            {!message.fileAnalysisId && "Verificar vírus no arquivo"}
            {message.fileAnalysisId && !message.fileAnalysisResultSimple && "Verificando vírus. Aguarde..."}
            {message.fileAnalysisId && message.fileAnalysisResultSimple && renderLabelFileAnalysis(message)}
        </Button>
    }

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    const isImage = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };


    const handleImageClick = () => {
        ///setLightboxIsOpen(true);
    };

    const handleLightboxClose = () => {
        //setLightboxIsOpen(false);
    };


    const handleDownload = (file) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = file;
        downloadLink.download = file.split('/').pop();
        downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const renderMessageNote = (id, position) => {

        const filteredNotes = messageNotes.filter((note) => note.messageId === id);

        return filteredNotes.map((note) => (

            <div key={note.id} id={note.messageId} className={classes.noteBlock} style={{ backgroundColor: note.color, alignSelf: position && 'flex-end' }}>
                {position ? (
                    <>
                        <div
                            className={classes.arrowTop}
                            style={{
                                borderBottom: `15px solid ${note.color}`,
                                right: 30,
                            }}
                        ></div>
                    </>
                ) : (
                    <>
                        <div
                            className={classes.arrowTop}
                            style={{
                                borderBottom: `15px solid ${note.color}`,
                                left: 30,
                            }}
                        ></div>
                    </>
                )}
                <div className={classes.noteHeader}>
                    <h5 className={classes.noteHeaderTitle}>{note.title}</h5>
                    {user && user.profile == 'admin' || user.id === note.userId ?
                        <Delete onClick={() => deleteMessageNote(note.id)} className={classes.DeleteIcon} /> : <></>}
                </div>
                <p className={classes.noteContent}>
                    {note.content}
                    {note.isAudio}
                </p>
                {note.mediaUrl ?
                    note.isAudio ?
                        <audio controls>
                            <source src={`${apiURL}/public/${note.mediaUrl}`} type="audio/mp3"></source>
                        </audio> : isImage(note.mediaUrl) ? (
                            <div className={classes.linkFile} >
                                <ModalImageCors imageUrl={`${apiURL}/public/${note.mediaUrl}`} />

                            </div>

                        ) : (
                            <p className={classes.linkFile} onClick={() => handleDownload(`${apiURL}/public/${note.mediaUrl}`)}>
                                <AttachFile /> {note.mediaUrl}
                            </p>
                        ) : (
                        <></>
                    )}
            </div>
        ));
    };

    const deleteMessageNote = async (id) => {
        try {
            await api.delete(`/notes/${id}`);
            handleNoteCreated();
        } catch (err) {
            toastError(err);
        }
    }

    const renderDocumentMessage = (message) => {
        
    }

    const checkMessageMedia = message => {
        if (message.mediaType === "image") {
            return <ModalImageCors imageUrl={message.mediaUrl} />;
        }
        if (message.mediaType === "sticker") {
            return <img src={message.mediaUrl} style={{ maxWidth: 300, maxHeight: 300 }} />
        }
        if (message.mediaType === "audio") {
            return <MessageAudio message={message} />
        }

        if (message.mediaType === "video") {
            return (
                <video
                    className={classes.messageMedia}
                    src={message.mediaUrl}
                    controls
                />
            );

        }

        if (message.mediaType === "document") {
            return <MessageDocument message={message} />;
        }
        
        return (
            <>
                <div className={classes.downloadMedia}>
                    {downloading == message.mediaUrl ? <CircularProgress color="inherit" /> :
                        checkFileEnabled ? renderFileAnalysis(message) :
                            isActive('showDownloadConfirmation') || isActive('forceDownload') ?
                                <Button
                                    startIcon={<GetApp />}
                                    color="primary"
                                    variant="outlined"
                                    target="_blank"
                                    onClick={() => handleDownloadMedia(message.mediaUrl)}
                                >
                                    Download
                                </Button> :
                                <Button
                                    startIcon={<GetApp />}
                                    color="primary"
                                    variant="outlined"
                                    target="_blank"
                                    href={message.mediaUrl}
                                >
                                    Download
                                </Button>}
                </div>
                <Divider />
            </>
        );
    };

    const handleDrag = event => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            if (onDrop) {
                onDrop(event.dataTransfer.files);
            }
        }
    }

    const renderMessageAck = message => {
        if (message.ack === 0) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 1) {
            return <Done fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 2) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 3 || message.ack === 4) {
            return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
        }
    };

    const renderDailyTimestamps = (message, index) => {
        if (index === 0) {
            return (
                <span
                    className={classes.dailyTimestamp}
                    key={`timestamp-${message.id}`}
                >
                    <div className={classes.dailyTimestampText}>
                        {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
                    </div>
                </span>
            );
        }
        if (index < messagesList.length - 1) {
            let messageDay = parseISO(messagesList[index].createdAt);
            let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

            if (!isSameDay(messageDay, previousMessageDay)) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        key={`timestamp-${message.id}`}
                    >
                        <div className={classes.dailyTimestampText}>
                            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
                        </div>
                    </span>
                );
            }
        }
        if (index === messagesList.length - 1) {
            return (
                <div
                    key={`ref-${message.createdAt}`}
                    ref={lastMessageRef}
                    style={{ float: "left", clear: "both" }}
                />
            );
        }
    };

    const renderMessageDivider = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageUser = messagesList[index].fromMe;
            let previousMessageUser = messagesList[index - 1].fromMe;

            if (messageUser !== previousMessageUser) {
                return (
                    <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
                );
            }
        }
    };

    const renderTicketDivider = (message, index) => {
        if (index < messagesList.length && index > 0) {
            if (messagesList[index].ticketId !== messagesList[index - 1].ticketId) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        style={{ width: 'auto' }}
                        key={`ticket-divider-${message.id}`}
                    >
                        <div className={classes.dailyTimestampText}>
                            {i18n.t("ticket")} #{message.ticketId} {message.ticket && message.ticket.createdAt && <span> - {format(parseISO(message.ticket.createdAt), "dd/MM/yyyy HH:mm")}</span>}
                        </div>
                    </span>
                );
            }
        }
    };

    const renderQuotedMessage = message => {
        return (
            <div
                className={clsx(classes.quotedContainerLeft, {
                    [classes.quotedContainerRight]: message.fromMe,
                })}
            >
                <span
                    className={clsx(classes.quotedSideColorLeft, {
                        [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
                    })}
                ></span>
                <div className={classes.quotedMsg}>
                    {!message.quotedMsg?.fromMe && (
                        <span className={classes.messageContactName}>
                            {message.quotedMsg?.contact?.name}
                        </span>
                    )}
                    {message.quotedMsg && message.quotedMsg.mediaUrl ? checkMessageMedia(message.quotedMsg) : message.quotedMsg?.body}
                </div>
            </div>
        );
    };

    const renderLocationMessage = message => {
        return <MessageLocation message={message} />;
    }

    const getMessageClassNames = message => {
        const classNames = [];
        if (message.fromMe) {
            classNames.push(classes.messageRight);
        } else {
            classNames.push(classes.messageLeft);
        }
        if (message.mediaType === 'location') {
            classNames.push(classes.messageLocation);
        }
        if (message.mediaType === 'contact') {
            classNames.push(classes.messageContact);
        }
        return classNames.join(' ');
    }

    const getEditedMessage = message => {
        if (message.editedToId) {
            const editedMessage = messagesList.find(m => m.id === message.editedToId);
            return editedMessage;
        }
        return null;
    }

    const renderNormalMessage = (message, index) => {
        if (message.editedToId) {
            const editedMessage = getEditedMessage(message);
            if (editedMessage) {
                return renderNormalContent(editedMessage, index, true);
            } else {
                return renderNormalContent(message, index);
            }
        } else {
            return renderNormalContent(message, index);
        }
    }

    const renderNormalContent = (message, index, edited = false) => {
        return (
        <React.Fragment>
            {message.quotedMsg && renderQuotedMessage(message)}
            {message.mediaUrl && checkMessageMedia(message)}
            <div className={clsx(classes.textContentItem, {
                [classes.textContentItemDeleted]: message.isDeleted,
                [classes.locationTextContentItem]: message.mediaType === 'location',
                [classes.contactTextContentItem]: message.mediaType === 'contact' || message.mediaType === 'vcard',
                [classes.audioTextContentItem]: message.mediaType === 'audio',
                [classes.textContentItemEdited]: edited
            })}>
                <>
                    {message.isDeleted && <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                    />}
                    {!message.isDeleted || getSettingValue('showDeletedMessages') === 'enabled' ? (
                        <>
                            {message.mediaType === "vcard" || message.mediaType === "contact" && renderVCard(message)}
                            {message.mediaType === "location" && renderLocationMessage(message)}
                            {message.mediaType !== "vcard" && message.mediaType !== "contact" && message.mediaType !== "location" && message.mediaType !== "poll" && message.mediaType !== "unsuported" && 
                            <MarkdownWrapper disableAnchor={disableAnchor}>{message.mediaType !== 'sticker' && message.mediaType !== 'audio' ? renderBodyText(message) : '&nbsp;'}</MarkdownWrapper>}
                            {message.mediaType === "unsuported" || message.mediaType === "poll" && <MessageUnsupported message={message} />}
                            {message.error && <MessageError message={message} />}
                            {message.mediaType === "ciphertext" && <MessageCiphertext message={message} />}
                        </>
                    ) : (
                        <span>Mensagem apagada</span>
                    )}
                </>
                <span className={clsx(classes.timestamp, {[classes.locationTimestamp]: message.mediaType === 'location', [classes.audioTimestamp]: message.mediaType === 'audio'})}>
                    {edited && "Editado em "}{format(parseISO(message.createdAt), "HH:mm")}
                    {message.fromMe && renderMessageAck(message)}
                </span>
            </div>
        </React.Fragment>);
    }

    const renderMessages = () => {
        if (messagesList.length > 0) {
            const viewMessagesList = messagesList.map((message, index) => {
                if (message.editedFromId) {
                    // Não renderiza mensagens que são do tipo edição. Elas serão exibidas na mesma posição da que foi editada.
                    return (<></>);
                }
                return (
                    <React.Fragment key={message.id}>
                        {renderDailyTimestamps(message, index)}
                        {renderMessageDivider(message, index)}
                        {renderTicketDivider(message, index)}
                        <div className={getMessageClassNames(message)}>
                            <IconButton
                                variant="contained"
                                size="small"
                                id="messageActionsButton"
                                disabled={message.isDeleted}
                                className={classes.messageActionsButton}
                                onClick={e => handleOpenMessageOptionsMenu(e, message)}
                            >
                                <ExpandMore />
                            </IconButton>
                            {isGroup && (
                                <span className={classes.messageContactName}>
                                    {message.contact?.name}
                                </span>
                            )}
                            {message.childs && message.childs.length > 0 && renderImageGroup(message, index)}
                            {!message.childs || message.childs.length === 0 ? renderNormalMessage(message, index) : <></>}
                            {showModalMediaNavigator[index] && message.childs ? <ModalMediaNavigator onClose={() => closeModalMediaNavigator(index)}	
                                medias={[{ url: message.mediaUrl, type: message.mediaType == 'image' ? 'photo' : 'video', title: message.body }].concat(	
                                    message.childs.map((childMessage) => { return { url: childMessage.mediaUrl, type: childMessage.mediaType == 'image' ? 'photo' : 'video', title: childMessage.body } })	
                            )} /> : <div></div>}
                        </div>

                        {renderMessageNote(message.id, message.fromMe ? 'right' : 'left')}
                    </React.Fragment >
                );
            });
            return viewMessagesList;
        } else {
            return <div>Say hello to your new contact!</div>;
        }
    };

    return (
        <div className={classes.messagesListWrapper} onDragEnter={handleDrag}>
            {dragActive && <div className={classes.dragElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>Solte o arquivo aqui</div>}
            <MessageOptionsMenu
                message={selectedMessage}
                ticketId={ticketId}
                anchorEl={anchorEl}
                menuOpen={messageOptionsMenuOpen}
                handleClose={handleCloseMessageOptionsMenu}
                handleNoteCreated={handleNoteCreated}
            />
            <div
                id="messagesList"
                className={classes.messagesList}
                style={backgroundStyle}
                onScroll={handleScroll}
            >
                {messagesList.length > 0 ? renderMessages() : []}
            </div>
            {loading && (
                <div>
                    <CircularProgress className={classes.circleLoading} />
                </div>
            )}
        </div>
    );
};

export default MessagesList;
