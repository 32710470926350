import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import IOSSwitch from "../IOSSwitch";
import { FormControl, FormHelperText, Input, InputLabel } from "@material-ui/core";

const SettingIntEnabledField = ({ name, settings, label, helpText }) => {
    const [enabled, setEnabled] = useState(false);
    const [oneChange, setOneChange] = useState(false);
    const inputRef = useRef();

    let debounce = null;

    const getSettingValue = key => {
		const { value } = settings && settings.length > 0 && settings.find(s => s.key === key);
		return value;
	};

    const updateLocalValue = (value) => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = value;
        }
    }

    useEffect(() => {
        const value = getSettingValue(name);
        if (value && value > 0) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    }, [settings, name]);

    /*useEffect(() => {
        if (oneChange) {
            updateLocalValue(1);
            updateField(name, 1);
            setOneChange(false);
        }
    }, [oneChange]);*/

    const updateField = async(field, value) => {
        try {
            await api.put(`/settings/${field}`, {
                value: value,
            });
            toast.success(i18n.t("settings.success"));
        } catch (err) {
            toastError(err);
        }
    }

    const handleChangeSetting = async e => {
        if (debounce) {
            clearTimeout(debounce);
        }
        const selectedValue = e.target.value;
        const settingKey = e.target.name;
        debounce = setTimeout(async() => {
            await updateField(settingKey, selectedValue);
        }, 500);
    }

    const handleChangeEnabled = async e => {
        setEnabled(e.target.checked);
        if (!e.target.checked) {
            await updateField(name, 0);
        }
        if (e.target && e.target.checked) {
            setOneChange(true);
        }
    }



    return (<React.Fragment>
        <FormControl>
            <IOSSwitch onChange={handleChangeEnabled} checked={enabled} label={label} />
            {enabled && <Input id={name} name={name} type="number" aria-describedby={`${name}-helper`} inputRef={inputRef} defaultValue={getSettingValue(name)} onChange={handleChangeSetting} />}
            {enabled && helpText && <FormHelperText id={`${name}-helper`}>{helpText}</FormHelperText>}
        </FormControl>
    </React.Fragment>)
}

export default SettingIntEnabledField; 