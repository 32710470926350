import { FormControl, FormGroup, Tooltip } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import React from "react";
import { useEffect, useState} from "react";
import AsyncSelect from "../AsyncSelect";

const SelectWhatsapps = ({onChange, selectedWhatsapps, disabled, width, outlined, multiple, tooltip, label}) => {
    const [internalSelectedWhatsapps, setInternalSelectedWhatsapps] = useState(multiple ? [] : null);

    const handleChange = (event, changedWhatsapps) => {
        setInternalSelectedWhatsapps(changedWhatsapps);
        if (onChange) {
            onChange(changedWhatsapps);
        }
    };

    const renderSelect = () => {
        return (
            <AsyncSelect
                width="100%"
                url="/whatsapp"
                disabled={disabled}
                dictKey="whatsapps"
                initialValue={selectedWhatsapps}
                multiple={multiple}
                onChange={handleChange}
                value={internalSelectedWhatsapps}
                placeholder={multiple ? "Selecione as conexões..." : "Selecione a conexão..."}
                label={multiple ? "Conexões" : "Conexão"}
                disableCloseOnSelect={multiple}
                />
        );
    }

    return (
        outlined ? 
        <FormGroup>
            <FormControl fullWidth={!width} style={{width: width || '100%'}} variant="outlined">
                <AsyncSelect 
                    url="/whatsapp" placeholder={multiple ? "Selecione as conexões..." : "Selecione a conexão..."}
                    dictKey="whatsapps" onChange={handleChange}
                    multiple={multiple}
                    initialValue={selectedWhatsapps}
                    width="100%"
                    label={label ? label : multiple ? "Conexões" : "Conexão"}
                    disabled={disabled}
                    disableCloseOnSelect={multiple}
                />
            </FormControl>
            {tooltip && <Tooltip title={tooltip} placement="right"><Info /></Tooltip>}
        </FormGroup>
        : 
        renderSelect()
    );
};

export default SelectWhatsapps;