import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";
import SearchIcon from "@material-ui/icons/Search";
import BlockIcon from "@material-ui/icons/Block";
import TextField from "@material-ui/core/TextField";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined"
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TapAndPlay from "@material-ui/icons/TapAndPlay";
import GroupIcon from "@material-ui/icons/Group";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ContactTagsModal from "../../components/ContactTagsModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import AddTagToContactModal from "../../components/AddTagToContactModal";
import ContactSyncModal from "../../components/ContactSyncModal";


import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { SettingsContext } from "../../context/Settings/SettingsContext";

import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, Tooltip } from "@material-ui/core";
import ContactAllowUserModal from "../../components/ContactAllowUserModal";
import getSocket from "../../helpers/socket";
import SelectTags from "../../components/SelectTags";
import ContactImportModal from "../../components/ContactImportModal";

import ShareIcon from "@material-ui/icons/Share";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from '@material-ui/icons/GetApp';

import ConnectionIcon from "../../components/ConnectionIcon";
import NewTicketModal from "../../components/NewTicketModal";
import Swal from "sweetalert2";
import SelectUsers from "../../components/SelectUsers";
import { RemoveCircleOutline } from "@material-ui/icons";

const socket = getSocket();

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex(c => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex(c => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        //padding: theme.spacing(1),
        borderRadius: 0,
        overflowY: "scroll",
        ...theme.scrollbarStylesSoftBig,
    },
    tag: {
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: '#FFF',
        textAlign: 'center',
        marginRight: 10,
        borderRadius: 5
    },
    mainHeaderBlock: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap'
        },
    },
    actionsButtons: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    actionsDivButton: {
        display: 'flex',
        gap: 10,
    },
    buttonsTicket: {
        height: 33,
        borderRadius: '5px!important',
        display: 'inline-flex',
        alignItems: 'center',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.07) !important',
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2) !important',
        },
    }
}));

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);
    const { isActive } = useContext(SettingsContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedContact, setSelectedContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactSyncSingleModalOpen, setContactSyncSingleModalOpen] = useState(false);
    const [addTagModalOpen, setAddTagModalOpen] = useState(false);
    const [contactTagsModalOpen, setContactTagsModalOpen] = useState(false);
    const [deletingContact, setDeletingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [importOpen, setImportOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [checked, setChecked] = useState([]);
    const [onlyGroups, setOnlyGroups] = useState(false);
    const [contactAddUserOpen, setContactAddUserOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [tagIds, setTagIds] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [totalContacts, setTotalContacts] = useState("");
    const [newTicketContact, setNewTicketContact] = useState(null);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [forceReload, setForceReload] = useState(null);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, tagIds, userIds, onlyGroups]);

    useEffect(() => {
        if (newTicketContact) {
            setNewTicketModalOpen(true);
        } else {
            setNewTicketModalOpen(false);
        }
    }, [newTicketContact]);

    const settingIsActive = key => {
        return isActive(key);
    }

    const handleToggle = (event) => {
        const currentIndex = checked.indexOf(event.target.value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const toggleAll = (event) => {
        if (event.target.checked) {
            checkAll();
        } else {
            uncheckAll();
        }
    }
    const checkAll = () => {
        setChecked(contacts.map((element) => { return "" + element.id }));
    }

    const uncheckAll = () => {
        setChecked([]);
    }

    const handleExport = async () => {
        setExporting(true);
        try {
            const format = 'xlsx';
            const { data } = await api.get(`/contacts/export`, {
                params: {
                    format,
                    tagIds,
                    searchParam,
                    userIds
                },
                responseType: 'arraybuffer',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' }
            }
            );
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `contatos.${format}`;
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }

    const handleHistoryExport = async (contactId) => {
        setExporting(true);
        try {
            const format = 'xlsx';
            const { data } = await api.get(`/contacts/${contactId}/history/export?format=${format}`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `historico_contato.${format}`;
            link.click();
            setExporting(false);
        } catch (err) {
            toastError(err);
            setExporting(false);
        }
    }
    const fetchContacts = async () => {
        try {
            const { data } = await api.get("/contacts/", {
                params: { searchParam, pageNumber, onlyGroups: onlyGroups ? "1" : "0", tagIds, userIds },
            });
            dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
            setHasMore(data.hasMore);
            setTotalContacts(`(${data.count})`);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            if (user.profile !== "admin" && !settingIsActive("showContacts")) return;
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, onlyGroups, tagIds, userIds, user, forceReload]);

    const removeCheckedItem = (contactId) => {
        const currentIndex = checked.indexOf(contactId);
        const newChecked = [...checked];

        console.log("INDEXOF", currentIndex, contactId, checked);

        if (currentIndex !== -1) {
            newChecked.splice(currentIndex, 1);
            setChecked(newChecked);
        }
    }

    useEffect(() => {
        const contactEvent = data => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
            }
        }

        socket.on("contact", contactEvent);

        return () => {
            socket.off('contact', contactEvent);
        };
    }, []);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleOpenAddTagModal = () => {
        setAddTagModalOpen(true);
    }


    const handleOpenContactTagsModal = () => {
        setContactTagsModalOpen(true);
    }

    const handleCloseContactTagsModal = () => {
        setContactTagsModalOpen(false);
    }


    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const handleCloseAddTagModal = () => {
        setAddTagModalOpen(false);
    }

    const handleSaveTicket = async contact => {
        if (!contact || !contact.id) return;
        setLoading(true);
        /*try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                userId: user?.id,
                status: "open",
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }*/
        setNewTicketContact(contact);
        setLoading(false);
    };

    const hadleEditContact = contactId => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };


    //Sicronizar Unico Contato
    const handleSyncSingleContact = async contactId => {
        setSelectedContactId(contactId);
        setContactSyncSingleModalOpen(true);

    };


    const handleCloseSyncSingleContactModal = () => {
        setSelectedContactId(null);
        setContactSyncSingleModalOpen(false);
        fetchContacts();
        //handleSearch();
    };
    //Sicronizar Unico Contato

    /*const handleUpdateContact = async contactId => {
        try {
           const response = await api.post(`/contacts/import/unique/${contactId}`);
            toast.success(i18n.t("contacts.toasts.synced"));
           // console.log(response.data)
            //setSelectedContactId(contactId);
            setSearchParam(response.data.response.name);
            handleSearch();
        } catch (err) {
            toastError(err);
        }
    };*/


    const handleDeleteContact = async contactId => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t("contacts.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleDeleteContacts = () => {
        Swal.fire({
            title: 'Atenção! Excluir contatos selecionados.',
            text: "Tem certeza que deseja excluir todos os contatos selecionados? Todos os atendimentos desses contatos também serão excluídos",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/contacts/`, { data: { contactIds: checked } });
                    toast.success(i18n.t("contacts.toasts.deleted"));
                } catch (err) {
                    toastError(err);
                }
                setDeletingContact(null);
                setSearchParam("");
                setPageNumber(1);
                setForceReload(Math.random());

                setChecked([]);
                
            }
        });
    }

    const handleimportContact = async () => {
        try {
            await api.post("/contacts/import");
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    let pageNumberInterval = null;

    const loadMore = () => {
        if (pageNumberInterval) {
            clearInterval(pageNumberInterval);
        }
        pageNumberInterval = setInterval(() => {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
            clearInterval(pageNumberInterval);
        }, 300);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOnlyGroupChange = e => {
        setOnlyGroups(e.target.checked);
    }

    const handleDeleteTagsContacts = () => {
        Swal.fire({
            title: 'Atenção! Excluir tags dos contatos selecionados.',
            text: "Tem certeza que deseja excluir tags do contato selecionados?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.post(`/contacts/tags`, { contactIds: checked, tagIds: tagIds } );
                    toast.success(i18n.t("Tags Removidas com sucesso!"));
                    window.location.reload();
                } catch (err) {
                    toastError(err);
                }
                setDeletingContact(null);
                setSearchParam("");
                setPageNumber(1);
                setForceReload(Math.random());
                setChecked([]);
            }
        });
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <ContactAllowUserModal
                open={contactAddUserOpen}
                contact={selectedContact}
                onClose={() => setContactAddUserOpen(false)}
            ></ContactAllowUserModal>
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactModal>
            <ContactSyncModal
                open={contactSyncSingleModalOpen}
                onClose={handleCloseSyncSingleContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactSyncModal>
            <AddTagToContactModal
                open={addTagModalOpen}
                onClose={handleCloseAddTagModal}
                aria-labelledby="form-dialog-title"
                contactIds={checked}
            ></AddTagToContactModal>
            <ContactTagsModal
                open={contactTagsModalOpen}
                onClose={handleCloseContactTagsModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            >
            </ContactTagsModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
                        }?`
                        : `${i18n.t("contacts.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={e =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleimportContact()
                }
            >
                {deletingContact
                    ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
                    : `${i18n.t("contacts.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={e => setNewTicketContact(null)}
                forContact={newTicketContact}
            />
            {importOpen && <ContactImportModal open={importOpen} handleClose={() => setImportOpen(false)} handleWhatsappImport={() => setConfirmOpen(true)} />}
            {user.profile === "admin" || settingIsActive("showContacts") ?
                <>
                    <MainHeader className={classes.mainHeaderBlock}>
                        <Title>{i18n.t("contacts.title")}</Title>
                        <MainHeaderButtonsWrapper className="d-flex flex-row flex-wrap" style={{ overflowX: 'auto', flexWrap: 'wrap' }}>
                            <TextField
                                placeholder={i18n.t("contacts.searchPlaceholder")}
                                type="search"
                                value={searchParam}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ color: "gray" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <SelectUsers width="250px" multiple onChange={(value) => setUserIds(value ? value.map(userItem => userItem.id) : [])} outlined></SelectUsers>
                            <SelectTags width="250px" onChange={(value) => { if (value) setTagIds(value.map((tag) => tag.id)); else setTagIds([]) }} outlined></SelectTags>
                            <FormControlLabel label="Mostrar somente grupos" control={
                                <Checkbox
                                    checked={onlyGroups}
                                    onChange={handleOnlyGroupChange}
                                />
                            } />

                        </MainHeaderButtonsWrapper>

                    </MainHeader>

                    <div className={classes.actionsButtons}>
                        <h5>{totalContacts} {i18n.t("contacts.title")}</h5>
                        <div className={classes.actionsDivButton}>
                            {checked && checked.length > 0 &&
                                <React.Fragment>
                                   {tagIds && tagIds.length > 0 && <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDeleteTagsContacts}
                                        className={classes.buttonsTicket}
                                        style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px' }}
                                        >
                                        <RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("Remover Tags")} ({checked.length})
                                    </Button> }
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleOpenAddTagModal}
                                        className={classes.buttonsTicket}
                                        style={{ height: 31 }}
                                    >
                                        <AddIcon style={{ fontSize: 13 }} /> {i18n.t("contacts.buttons.addTags")} ({checked.length})
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleDeleteContacts}
                                        className={classes.buttonsTicket}
                                        style={{ height: 31 }}
                                    >
                                        <RemoveCircleOutline style={{ fontSize: 13 }} />   {i18n.t("contacts.buttons.deleteContacts")} ({checked.length})
                                    </Button>
                                </React.Fragment>
                            }

                           {/*<Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonsTicket}
                                style={{ height: 31 }}
                                onClick={handleOpenContactTagsModal}
                            >
                                <LoyaltyOutlinedIcon style={{ fontSize: 13 }} /> Gerenciador de Tags
                        </Button>*/}


                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonsTicket}
                                style={{ height: 31 }}
                                onClick={e => setImportOpen(true)}
                            >
                                <GetAppIcon style={{ fontSize: 13 }} /> {i18n.t("contacts.buttons.import")}
                            </Button>

                            <Button
                                variant="contained"
                                className={classes.buttonsTicket}
                                style={{ backgroundColor: 'black', color: '#ffffff', fontSize: '12px' }}
                                onClick={handleOpenContactModal}
                            >
                                <AddIcon style={{ fontSize: 13 }} />  {i18n.t("contacts.buttons.add")}
                            </Button>
                            {user.profile === "admin" && (
                                <Button
                                    variant="contained"
                                    color="error"

                                    className={classes.buttonsTicket}
                                    onClick={handleExport}
                                    disabled={exporting}>
                                    <ShareIcon style={{ fontSize: 13 }} /> {!exporting ? i18n.t("contacts.buttons.export") : i18n.t("exporting")}
                                </Button>
                            )}
                        </div>
                    </div>
                </>
                : <></>}
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox onChange={toggleAll} />
                            </TableCell>
                            <TableCell padding="checkbox" />
                            <TableCell></TableCell>
                            <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                            <TableCell align="center">
                                {i18n.t("contacts.table.whatsapp")}
                            </TableCell>
                            <TableCell>
                                {i18n.t("contacts.table.user")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contacts.table.email")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contacts.table.tags")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contacts.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map(contact => (
                                <TableRow key={contact.id}>
                                    <TableCell>
                                        <Checkbox value={contact.id} checked={checked.indexOf("" + contact.id) !== -1} onChange={handleToggle} />
                                    </TableCell>
                                    <TableCell>
                                        {contact.blocked && <BlockIcon />}
                                    </TableCell>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={contact.profilePicUrl} />}
                                    </TableCell>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell align="center">
                                        {contact.isWhatsapp && <ConnectionIcon connectionType={'whatsapp'} />}
                                        {contact.isFacebook && <ConnectionIcon connectionType={'facebook'} />}
                                        {contact.isInstagram && <ConnectionIcon connectionType={'instagram'} />}
                                        {contact.numberFormatted}
                                    </TableCell>
                                    <TableCell>
                                        {contact.user ? contact.user.name : ''} {contact.queue ? `(${contact.queue.name})` : ''}
                                    </TableCell>
                                    <TableCell align="center">{contact.email}</TableCell>
                                    <TableCell align="center">
                                        {contact.tags && contact.tags.map(tag =>
                                            (<span className={classes.tag} style={{ backgroundColor: tag.color }}>{tag.name}</span>)
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleSaveTicket(contact)}
                                        >
                                            <WhatsAppIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => hadleEditContact(contact.id)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:exportHistoryContact"
                                            yes={() => (
                                                <Tooltip title="Exportar Histórico do Contato">
                                                    <IconButton
                                                        size="small"
                                                        onClick={e => {
                                                            handleHistoryExport(contact.id);
                                                        }}
                                                    >
                                                        <SpeakerNotes />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        />
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:deleteContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={e => {
                                                        setConfirmOpen(true);
                                                        setDeletingContact(contact);
                                                    }}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        />
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:updateContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={e => {
                                                        //   setConfirmOpen(true);
                                                        //  setUpdatingContact(contact);
                                                    }}
                                                >
                                                    <TapAndPlay />
                                                </IconButton>
                                            )}
                                        />
                                        {!contact.isGroup &&
                                            <IconButton
                                                alt="Sicronizar do Celular"
                                                size="small"
                                                onClick={() => handleSyncSingleContact(contact.id)}
                                            >
                                                <TapAndPlay />
                                            </IconButton>
                                        }


                                        {contact.isGroup &&
                                            <Can
                                                role={user.profile}
                                                perform="contacts-page:attribGroupUser"
                                                yes={() => (
                                                    <IconButton
                                                        size="small"
                                                        onClick={e => {
                                                            setSelectedContact(contact);
                                                            setContactAddUserOpen(true);
                                                        }}
                                                    >
                                                        <GroupIcon />
                                                    </IconButton>
                                                )}
                                            />}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={7} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Contacts;
