import React, { useEffect, useState } from "react";

import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'
import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';
import { DateRangePicker as DateRangePickerBase } from 'rsuite';
import * as moment from 'moment';

const DateRangePicker = ({ disabled, onChange }) => {
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);

    useEffect(() => {
        if (onChange) {
            onChange(period);
        }
    }, [period]);

    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sáb',
        ok: 'OK',
        today: 'Hoje',
        yesterday: 'Ontem',
        last7Days: 'Últimos 7 dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
        last30Days: 'Últimos 30 dias',
        thisMonth: 'Esse mês',
        lastMonth: 'Último mês',
        thisYear: 'Esse Ano',
        lastYear: 'Último Ano',
        formattedMonthPattern: 'MMM, YYYY',
        formattedDayPattern: 'DD MMM, YYYY'
    };

    const ranges = [
        {
            label: 'last7Days',
            value: [moment().subtract(6, 'days').toDate(), moment().toDate()]
        },
        {
            label: 'Últimos 30 dias',
            value: [moment().subtract(30, 'days').toDate(), moment().toDate()]
        },
        {
            label: 'Esse mês',
            value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
        },
        {
            label: 'Último mês',
            value: [moment().startOf('month').subtract(1, 'months').toDate(), moment().startOf('month').subtract(1, 'months').endOf('month').toDate()]
        },
        {
            label: 'Esse Ano',
            value: [moment().startOf('year').toDate(), moment().endOf('year').toDate()]
        },
        {
            label: 'Último Ano',
            value: [moment().startOf('year').subtract(1, 'years').toDate(), moment().startOf('year').subtract(1, 'years').endOf('year').toDate()]
        }
    ];

    return (
        <IntlProvider locale={ptBR}>
            <DateRangePickerBase
                ranges={ranges}
                defaultValue={period}
                onChange={setPeriod}
                locale={locale}
                disabled={disabled}
                format="DD/MM/YYYY"
            />
        </IntlProvider>
    );
};

export default DateRangePicker;
