import React, { useState, useEffect, useReducer } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import NotificationModalShow from "../../components/Notifications/show";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";

import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import getSocket from "../../helpers/socket";

const socket = getSocket();

const reducer = (state, action) => {
	if (action.type === "LOAD_NOTIFICATIONS") {
		const notifications = action.payload;
		const newNotifications = [];

		notifications.forEach(notification => {
			const notificationIndex = state.findIndex(c => c.id === notification.id);
			if (notificationIndex !== -1) {
				state[notificationIndex] = notification;
			} else {
				newNotifications.push(notification);
			}
		});

		return [...state, ...newNotifications];
	}

	if (action.type === "UPDATE_NOTIFICATIONS") {
		const notification = action.payload;
		const notificationIndex = state.findIndex(c => c.id === notification.id);

		if (notificationIndex !== -1) {
			state[notificationIndex] = notification;
			return [...state];
		} else {
			return [notification, ...state];
		}
	}

    if (action.type === "DELETE_NOTIFICATION") {
		const notificationId = action.payload;

		const notificationIndex = state.findIndex(c => c.id === notificationId);
		if (notificationIndex !== -1) {
			state.splice(notificationIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		//padding: theme.spacing(1),
		borderRadius:0,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	tag: {
		padding:5,
		paddingLeft: 10,
		paddingRight: 10,
		color:'#FFF',
		textAlign:'center',
		marginRight: 10,
		borderRadius: 5
	}
}));

const Notifications = () => {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [searchParam, setSearchParam] = useState("");
	const [notifications, dispatch] = useReducer(reducer, []);
	const [currentNotification, setCurrentNotification] = useState(null);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [checked, setChecked] = useState([]);

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam]);

	const handleToggle = (event) => {
		const currentIndex = checked.indexOf(event.target.value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(event.target.value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	}

	const toggleAll = (event) => {
		if (event.target.checked) {
			checkAll();
		} else {
			uncheckAll();
		}
	}
	const checkAll = () => {
		setChecked(notifications.map((element) => {return ""+element.id}));
	}

	const uncheckAll = () => {
		setChecked([]);
	}

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchNotifications = async () => {
				try {
					const { data } = await api.get("/notifications/", {
						params: { searchParam, pageNumber },
					});
					dispatch({ type: "LOAD_NOTIFICATIONS", payload: data.notifications });
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					toastError(err);
				}
			};
			fetchNotifications();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, pageNumber]);

	useEffect(() => {
		const notificationEvent = data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.notification });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_NOTIFICATION", payload: +data.notificationId });
			}
		}

		socket.on("notification", notificationEvent);

		return () => {
			socket.off("notification", notificationEvent);
		};
	}, []);

	const handleSearch = event => {
		setSearchParam(event.target.value.toLowerCase());
	};

	const handleOpenNotificationModal = (notification) => {
		setCurrentNotification(notification);
		setNotificationModalOpen(true);
	};

	const handleCloseNotificationModal = () => {
		setCurrentNotification(null);
		setNotificationModalOpen(false);
	};

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	const handleScroll = e => {
		if (!hasMore || loading) return;
		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
		<MainContainer className={classes.mainContainer}>
			<NotificationModalShow
				open={notificationModalOpen}
				onClose={handleCloseNotificationModal}
				aria-labelledby="form-dialog-title"
				notification={currentNotification}
			></NotificationModalShow>
			<MainHeader>
				<Title>{i18n.t("notifications.title")}</Title>
				<MainHeaderButtonsWrapper>
					<TextField
						placeholder={i18n.t("notifications.searchPlaceholder")}
						type="search"
						value={searchParam}
						onChange={handleSearch}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon style={{ color: "gray" }} />
								</InputAdornment>
							),
						}}
					/>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper
				className={classes.mainPaper}
				variant="outlined"
				onScroll={handleScroll}
			>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox onChange={toggleAll} />
							</TableCell>
							<TableCell>{i18n.t("notifications.table.date")}</TableCell>
							<TableCell>{i18n.t("notifications.table.title")}</TableCell>
							<TableCell align="center">
								{i18n.t("notifications.table.message")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<>
							{notifications.map(notification => (
								<TableRow key={notification.id}>
									<TableCell>
										<Checkbox value={notification.id} checked={checked.indexOf(""+notification.id) !== -1} onChange={handleToggle} />
									</TableCell>
                                    <TableCell>{moment(notification.createdAt).format("DD/MM/YYYY HH:MM")}</TableCell>
									<TableCell><span style={{fontStyle: notification.read ? 'normal' : 'bold'}}>{notification.notification.title}</span></TableCell>
									<TableCell align="center">
                                        <IconButton
											size="small"
											onClick={() => handleOpenNotificationModal(notification)}
										>
											<SearchIcon />
										</IconButton>
                                    </TableCell>
								</TableRow>
							))}
							{loading && <TableRowSkeleton avatar columns={3} />}
						</>
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Notifications;
