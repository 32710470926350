import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Circle } from '@uiw/react-color';
import { i18n } from "../../translate/i18n";
import ModalImage from "react-modal-image";
import { FileIcon, defaultStyles } from 'react-file-icon';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormControlLabel, Switch } from "@material-ui/core";
import AsyncSelect from "../AsyncSelect";
import MicRecorder from "mic-recorder-to-mp3";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MicIcon from "@material-ui/icons/Mic";
import RecordingTimer from "../MessageInput/RecordingTimer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles(theme => ({
    AddMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    boxFiles: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    iconText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        color: '#000000'
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column"
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: "green",
    },
    uploadInput: {
        display: "none"
    },
    textField: {
        marginRight: theme.spacing(1),
        width: '100%'
    },
    textareaField: {
        marginRight: theme.spacing(1),
        width: '100%',
        minHeight: 100
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    messageSaved: {
        boxShadow: "0 1px 1px #b3b3b3",
        background: "#f5f5f5",
        padding: '5px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cont: {
        fontStyle: "italic",
    },
    timestamp: {
        fontStyle: 'normal',
        fontSize: 11,
        color: '#666666'
    }
}));

const ContactSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Muito Curto!")
        .max(200, "Muito Longo!")
        .required("Obrigatório"),
    content: Yup.string().min(3, "Muito Pequeno!"),
});

const AddNoteModal = ({ open, onClose, message, ticketId, onSave, noteCreated }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const [hex, setHex] = useState('');
    const [media, setMedia] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialState, setinitialState] = useState({});
    const [isAudio, setIsAudio] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [contact, setContact] = useState(initialState);
    const [recording, setRecording] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {

        setinitialState({
            title: "",
            content: "",
            userId: user.id,
            ticketId: ticketId,
            contactId: message && message.contact ? message.contact.id : null,
            isAudio: isAudio,
            medias: media,
            color: hex
        })
    }, [message]);

    const fileRef = useRef();

    const showFilePicker = () => {
        fileRef.current.click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setIsAudio(false);
    };

    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleCloseNote = () => {
        noteCreated();
    };

    const handleSaveNote = async (values) => {
        try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('userId', user.id);
            formData.append('ticketId', ticketId);
            formData.append('contactId', message.contact && message.contact.id);
            formData.append('isAudio', isAudio)
            formData.append('color', hex);
            if (selectedFile) {
                formData.append('medias', selectedFile);
            }

            const { data } = await api.post(`/notes/${message.id}`, formData);

            console.log(data);
            if (onSave) {
                onSave(data);
            }
            
            handleClose();
            handleCloseNote();
            toast.success(i18n.t('addNoteModal.success'));
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    const handleChangeColor = (color) => {
        setHex(color.hex)
    };

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3();
            if (blob.size < 10000) {
                setLoading(false);
                setRecording(false);
                return;
            }
            const filename = `${new Date().getTime()}.mp3`;
            setIsAudio(true);
            setSelectedFile(blob, filename)
            console.log(blob, filename)
            //formData.append("medias", blob, filename);
        } catch (err) {
            toastError(err);
        }

        setRecording(false);
        setLoading(false);
    };


    const timestamp = new Date(message.createdAt);
    const formattedTime = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = timestamp.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {/*contactId
                        ? `${i18n.t("addNoteModal.title.edit")}`
                        :*/ `${i18n.t("addNoteModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveNote(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.AddMargin}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("addNoteModal.form.title")}
                                        name="title"
                                        autoFocus
                                        error={touched.title && Boolean(errors.title)}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                    />
                                </div>
                                <div className={classes.AddMargin} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label className={classes.label}>Nota</label>
                                    <Field
                                        as={TextareaAutosize}
                                        label={i18n.t("addNoteModal.form.content")}
                                        name="content"
                                        autoFocus
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textareaField}
                                    />
                                </div>


                                {selectedFile ?
                                    <div className={classes.boxFiles}>
                                        {isAudio ?
                                            <><i>Audio Anexado</i></>
                                            : selectedFile.name}

                                        <CloseIcon onClick={() => setSelectedFile(null)} className={classes.closeIcon} />
                                    </div>
                                    :
                                    <div className={classes.boxFiles}>
                                        {values.mediaUrl ? <div className={classes.fileContainer}>
                                            <p>Arquivo atual:</p>
                                            {["jpg", "jpeg", "gif", "png", "webp"].indexOf(values.mediaUrl.split('.').pop()) > -1 ?
                                                <ModalImage
                                                    className={classes.messageMedia}
                                                    small={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                                    medium={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                                    large={values.absoluteMediaUrl || URL.createObjectURL(media)}
                                                    alt="image"
                                                />
                                                : <div className={classes.fileIconContainer}>
                                                    <FileIcon extension={values.mediaUrl.split('.').pop()} {...defaultStyles[values.mediaUrl.split('.').pop()]} />
                                                </div>}
                                            <CloseIcon className={classes.closeButton} onClick={() => { setFieldValue("mediaUrl", ''); setMedia(null) }} />
                                        </div> :
                                            <Button className={classes.textFieldContentContainer} onClick={e => showFilePicker()}>
                                                <input
                                                    multiple
                                                    type="file"
                                                    ref={fileRef}
                                                    disabled={loading}
                                                    className={classes.uploadInput}
                                                    onChange={handleFileChange}
                                                />
                                                <AttachFileIcon /> Anexar Arquivo (opcional)
                                            </Button>}
                                        {recording ? (
                                            <div className={classes.recorderWrapper}>
                                                <IconButton
                                                    aria-label="cancelRecording"
                                                    component="span"
                                                    fontSize="large"

                                                    disabled={loading}
                                                    onClick={handleCancelAudio}
                                                >
                                                    <HighlightOffIcon className={classes.cancelAudioIcon} />
                                                </IconButton>
                                                {loading ? (
                                                    <div>
                                                        <CircularProgress className={classes.audioLoading} />
                                                    </div>
                                                ) : (
                                                    <RecordingTimer />
                                                )}

                                                <IconButton
                                                    aria-label="sendRecordedAudio"
                                                    component="span"
                                                    onClick={handleUploadAudio}
                                                    disabled={loading}
                                                >
                                                    <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                                                </IconButton>




                                            </div>
                                        ) : (
                                            <IconButton
                                                aria-label="showRecorder"
                                                component="span"
                                                onClick={handleStartRecording}
                                                className={classes.textFieldContentContainer}
                                            >
                                                <MicIcon className={classes.sendMessageIcons} /> <span className={classes.iconText}> Gravar Áudio</span>
                                            </IconButton>

                                        )}

                                    </div>}

                                <div className={classes.AddMargin}>
                                    <label className={classes.label}>Cor da Nota</label>
                                    <Circle
                                        colors={['#dfe6e9', '#ffeaa7',
                                            '#55efc4', '#74b9ff', '#ff7675'
                                        ]}
                                        color={hex}
                                        onChange={(color) => {
                                            handleChangeColor(color)
                                        }}
                                    />
                                </div>

                                {message && (
                                    <><label className={classes.label}>Sobre</label>
                                        <div className={classes.messageSaved}>
                                            <span className={classes.cont}>{message.body}</span>
                                            <span className={classes.timestamp}>{formattedDateTime}</span>
                                        </div></>)}



                                {/* {user.profile === 'admin' && <div>
                                  
                                    <div style={{ marginTop: 10 }}>
                                        <AsyncSelect url="/users" dictKey={"users"}
                                            initialValue={values.user} width="100%" label="Atendente"
                                            onChange={(event, value) => setFieldValue("userId", value ? value.id : null)} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <AsyncSelect url="/queue" dictKey={null}
                                            initialValue={values.queue} width="100%" label="Setor"
                                            onChange={(event, value) => setFieldValue("queueId", value ? value.id : null)} />
                                    </div>
                                </div>} */}

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {/*contactId
                                        ? `${i18n.t("contactModal.buttons.okEdit")}`
                                        :*/ `${i18n.t("contactModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default AddNoteModal;
