import api from "../../services/api";
import { useState, useEffect, useContext } from "react";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import getSocket from "../../helpers/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
const socket = getSocket();

const useUserSettings = () => {
    const [settings, setSettings] = useState(null);
    const [hashKey, setHashKey] = useState(null);
    const { user } = useContext(AuthContext);

    const setUserSettings = (newSettings) => {
        setSettings(newSettings);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchSettings = async () => {
                try {
                    const { data } = await api.get(`/user/${user.id}/settings`);
               
                    setSettings({
                        "enableSound": data.settings.enableSound,
                        "notificationSound": data.settings.notificationSound,
                        "enableGroupNotifications": data.settings.enableGroupNotifications,
                        "enableBotNotifications": data.settings.enableBotNotifications,
                        "backgroundChat": data.settings.backgroundChat,
                        "colorDefault": data.settings.colorDefault
                    })

                    if(data.settings.colorDefault){
                        window.localStorage.setItem("colorDefault", data.settings.colorDefault);
                    }
                    setHashKey(prevState => {
                        return prevState ? prevState + 1 : 1;
                    });
                } catch (err) {
                    console.log(err)
                    toastError(err);
                }
            };
            fetchSettings();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [user]);




    let debounce;
    const handleChangeSetting = async (name, value) => {
        console.log(name,value)
        debounce = setTimeout(async () => {
            try {
                const response = await api.put(`/user/${user.id}/settings`, {
                   [name]: value
                });
                if (response) {
                    console.log(response.data)
                    if(response.data.colorDefault){
                        window.localStorage.setItem("colorDefault", response.data.colorDefault);
                    }
                    toast.success(i18n.t("settings.success"));
                    setSettings(response.data)

                   
                }
            } catch (err) {
                toastError(err);
            }
        }, 500);

    }

    const getUserSettingValue = key => {
        if (!settings.settings || !settings.settings.length) return null;
        console.log(key)
        const { value } = settings.settings.find(s => s.key === key);

      
        return value;
    };

    const isActiveUserSetting = setting => {
        return getUserSettingValue(setting) === "enabled";
    }

    return {
        isActiveUserSetting,
        getUserSettingValue,
        settings: settings,
        handleChangeSetting,
        setSettings: setUserSettings,
        hashKey,
    };
}

export default useUserSettings;